// © 2022 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.
// This AWS Content is provided subject to the terms of the AWS Customer
// Agreement available at http://aws.amazon.com/agreement or other written
// agreement between Customer and either Amazon Web Services, Inc. or Amazon
// Web Services EMEA SARL or both.

// import '@watergis/mapbox-gl-export/css/styles.css';
import './App.css';

import "@cloudscape-design/global-styles/index.css"
import AppLayout from '@cloudscape-design/components/app-layout';
import Header from '@cloudscape-design/components/header';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
import { ScrollView } from '@aws-amplify/ui-react';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { default as CsIcon } from "@cloudscape-design/components/icon";
import Grid from "@cloudscape-design/components/grid";
import Flashbar from "@cloudscape-design/components/flashbar";
import Tabs from "@cloudscape-design/components/tabs";
import HelpPanel from "@cloudscape-design/components/help-panel";
import TopNavigation from '@cloudscape-design/components/top-navigation';
import LineChart from "@cloudscape-design/components/line-chart";
import Box from "@cloudscape-design/components/box";
import Button from "@cloudscape-design/components/button";
import { applyMode, applyDensity, Density, Mode } from '@cloudscape-design/global-styles';
import Container from "@cloudscape-design/components/container";
import SplitPanel from "@cloudscape-design/components/split-panel"
import PropertyFilter from '@cloudscape-design/components/property-filter';
import './styles/base.scss';
import './styles/top-navigation.scss';
import logo from './ScepterAir.png';
import { Map, useMap } from 'react-map-gl/maplibre';
import { withIdentityPoolId } from '@aws/amazon-location-utilities-auth-helper'
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { Signer } from "@aws-amplify/core";
import "maplibre-gl/dist/maplibre-gl.css";
import maplibregl from "maplibre-gl";
import { useCollection } from '@cloudscape-design/collection-hooks';
import Checkbox from "@cloudscape-design/components/checkbox";
import { SliderField, Image } from '@aws-amplify/ui-react';

import {
  // Box,
  // Button,
  RadioGroup,
  Badge,
  Cards,
  CollectionPreferences,
  ExpandableSection,
  Link,
  Icon,
  ContentLayout,
  DatePicker,
  DateInput,
  FormField,
  TextContent,
  Select,
  Modal,
  // Header,
  Pagination,
  Table,
  TextFilter,
} from '@cloudscape-design/components';


// react
import React, { useState, useEffect, useRef } from 'react';

import {
  MapView,
  LocationSearch
} from '@aws-amplify/ui-react-geo'



import {
  defaultDarkModeOverride,
  HighlightMatch,
  Authenticator,
  withAuthenticator,
  ThemeProvider,
  Flex,
  useTheme
} from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';

// import { NavigationControl, ScaleControl, FullscreenControl } from 'react-map-gl';


import mapboxgl from 'mapbox-gl';

import { Amplify, Storage, Auth, Analytics, API } from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure({
  ...awsconfig,
  // DataStore: {
  //   authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
  // }
});

const analyticsConfig = {
  AWSPinpoint: {
    // Amazon Pinpoint App Client ID
    appId: '9cf3818b2e6f4bebb573246ee50d70de',
    // Amazon service region
    region: 'us-east-2',
    mandatorySignIn: false,
  }
}

// function getData() {
// const apiName = 'stacfastapi-test';
// const path = '/collections/haps_swir/items';
// const myInit = {
//   // queryStringParameters: {
//   //   order: 'byPrice'
//   // }
// }

// return API.get(apiName, path, myInit);
// }

Analytics.configure(analyticsConfig)

API.configure({
  // API: {
  endpoints: [
    {
      name: 'stacfastapi-test',
      endpoint: 'https://6oznyhwqob.execute-api.us-east-2.amazonaws.com/test-march',
      // endpoint: 'https://oc7tn9cbsc.execute-api.us-east-2.amazonaws.com/dev',
      region: 'us-east-2',
      // custom_header: async () => {
      //   // return { Authorization: 'token' };
      //   // Alternatively, with Cognito User Pools use this:
      //   return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
      //   // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
      // }
    }
  ]
  // }
});

// API.configure();



Analytics.autoTrack('session', {
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // OPTIONAL, the attributes of the event, you can either pass an object or a function
  // which allows you to define dynamic attributes
  // attributes: {
  //   attr: 'attr'
  // },
  // when using function
  // attributes: () => {
  //    const attr = somewhere();
  //    return {
  //        myAttr: attr
  //    }
  // },
  // OPTIONAL, the service provider, by default is the Amazon Pinpoint
  provider: 'AWSPinpoint'
});

Analytics.autoTrack('event', {
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // OPTIONAL, events you want to track, by default is 'click'
  events: ['click'],
  // OPTIONAL, the prefix of the selectors, by default is 'data-amplify-analytics-'
  // in order to avoid collision with the user agent, according to https://www.w3schools.com/tags/att_global_data.asp
  // always put 'data' as the first prefix
  // selectorPrefix: 'data-amplify-analytics-',
  // OPTIONAL, the service provider, by default is the Amazon Pinpoint
  // provider: 'AWSPinpoint',
  // OPTIONAL, the default attributes of the event, you can either pass an object or a function
  // which allows you to define dynamic attributes
  // attributes: {
  //   attr: 'attr'
  // }
  // when using function
  // attributes: () => {
  //    const attr = somewhere();
  //    return {
  //        myAttr: attr
  //    }
  // }
});

function App({ signOut, user }) {

  applyMode(Mode.Dark);
  // console.log(API)


  async function catalogVisualDisplay(object, bounds) {
    console.log(bounds)

    const myDownloadInit = {
      queryStringParameters: {
        object: object
      },
    }

    const url = await API.get('stacfastapi-test', '/download', myDownloadInit)
    // const msiLayer2 = 'msi_layers/2023-11-21.tif';
    // const signedMsiLayer2 = await Storage.get(msiLayer2, { useAccelerateEndpoint: true, expiration: 28800 })
      .then(out => {
        console.log(out);
       
        if(map.current.getStyle().layers.some(e => e.id == object)) {
          map.current.removeLayer(object)
          map.current.removeSource(object)
        } else if (out.url.includes('tropomi_layers')) {
          console.log('TROPOMI')
          map.current.addSource(object, {
            'type': 'raster',
            'tiles': [
              "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?return_mask=true&rescale=1900%2C2000&colormap_name=plasma&url=" + encodeURIComponent(out.url)
              // "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?return_mask=true&rescale=0.2%2C0.5&colormap_name=plasma_r&url=" + encodeURIComponent(out.url)
            ],
            'tileSize': 64,
            'minZoom': 12,
            'maxZoom': 13,
            
              "bounds": bounds
            
          });
          map.current.addLayer(
            {
              'id': object, // Layer ID
              'type': 'raster',
              'source': object, // ID of the tile source created above
              // 'layout': {
              //   'visibility': 'none'
              // },
              'paint': {
                'raster-fade-duration': 0,
                'raster-opacity': 1
                // 'raster-opacity': 0.2
              }
            },
          );
        } else if (out.url.includes('msi_layers')) {
          console.log('TROPOMI')
          map.current.addSource(object, {
            'type': 'raster',
            'tiles': [
              "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?return_mask=true&rescale=0%2C255&colormap_name=plasma&url=" + encodeURIComponent(out.url)
              // "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?return_mask=true&rescale=0.2%2C0.5&colormap_name=plasma_r&url=" + encodeURIComponent(out.url)
            ],
            'tileSize': 64,
            'minZoom': 12,
            'maxZoom': 13,
            
              "bounds": bounds
            
          });
          map.current.addLayer(
            {
              'id': object, // Layer ID
              'type': 'raster',
              'source': object, // ID of the tile source created above
              // 'layout': {
              //   'visibility': 'none'
              // },
              'paint': {
                'raster-fade-duration': 0,
                'raster-opacity': 1
                // 'raster-opacity': 0.2
              }
            },
          );
        }
        else {
        map.current.addSource(object, {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?return_mask=true&rescale=0%2C255&url=" + encodeURIComponent(out.url)
            // "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?return_mask=true&rescale=0.2%2C0.5&colormap_name=plasma_r&url=" + encodeURIComponent(out.url)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          
            "bounds": bounds
          
        });
        map.current.addLayer(
          {
            'id': object, // Layer ID
            'type': 'raster',
            'source': object, // ID of the tile source created above
            // 'layout': {
            //   'visibility': 'none'
            // },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );
      }


      });
    // return url
  }

  async function catalogNdmiDisplay(object, bounds) {
    console.log(object)
    const myDownloadInit = {
      queryStringParameters: {
        object: object
      },
    }

    const url = await API.get('stacfastapi-test', '/download', myDownloadInit)
    // const msiLayer2 = 'msi_layers/2023-11-21.tif';
    // const signedMsiLayer2 = await Storage.get(msiLayer2, { useAccelerateEndpoint: true, expiration: 28800 })
      .then(out => {
        // console.log(out);

        // console.log(map.current.getStyle().layers)
        if(map.current.getStyle().layers.some(e => e.id == object)) {
          map.current.removeLayer(object)
          map.current.removeSource(object)
        } else {
        map.current.addSource(object, {
          'type': 'raster',
          'tiles': [
            // "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?return_mask=true&rescale=0%2C255&colormap_name=plasma_r&url=" + encodeURIComponent(out.url)
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?return_mask=true&rescale=0.2%2C0.5&colormap_name=plasma_r&url=" + encodeURIComponent(out.url)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          
            "bounds": bounds
          
        });
        map.current.addLayer(
          {
            'id': object, // Layer ID
            'type': 'raster',
            'source': object, // ID of the tile source created above
            // 'layout': {
            //   'visibility': 'none'
            // },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );
      }

      });
    // return url
  }

  async function catalogDownload(object) {
    console.log(object)
    const myDownloadInit = {
      queryStringParameters: {
        object: object
      },
    }

    const url = await API.get('stacfastapi-test', '/download', myDownloadInit)
    // const msiLayer2 = 'msi_layers/2023-11-21.tif';
    // const signedMsiLayer2 = await Storage.get(msiLayer2, { useAccelerateEndpoint: true, expiration: 28800 })
  
    window.open(url['url'], "_top")
    // return url
  }

  var initialEndDate = new Date()
  var initialStartDate = new Date()
  let todayDate = new Date().toJSON().slice(0, 10);
  // let todayDate = '2023-10-31'
  // initialStartDate.setDate(initialStartDate.getDate() - 1)
  initialStartDate.setDate(initialStartDate.getDate() - 4)
  initialEndDate.setDate(initialEndDate.getDate() - 4)
  const [dateFilter, setDateFilter] = React.useState(todayDate);
  // console.log(initialStartDate.getUTCMonth())

  function escapeFunc(event) {
    if (event.detail.id == 'signout') {
      // console.log('siging out ', event);
      signOut()
    }

  }

  function timezoneFunc(event) {
    // if (event.detail.id == 'time-zone') {
    // console.log('siging out ', event);
    // console.log(event)
    setTimeZoneVisible(true);
    // return (

    // )
    // }

  }

  async function handlePlumeSliderChange(event) {
    // console.log(event);
    map.current.setPaintProperty(
      'sentinel',
      'raster-opacity',
      parseInt(event, 10) / 100
    );
    map.current.setPaintProperty(
      'balloon1',
      'raster-opacity',
      parseInt(event, 10) / 100
    );

    map.current.setPaintProperty('balloon2', 'raster-opacity', parseInt(event, 10) / 100);
    map.current.setPaintProperty('balloon3', 'raster-opacity', parseInt(event, 10) / 100);
    map.current.setPaintProperty('balloon4', 'raster-opacity', parseInt(event, 10) / 100);
    map.current.setPaintProperty('balloon5', 'raster-opacity', parseInt(event, 10) / 100);
    map.current.setPaintProperty('balloon6', 'raster-opacity', parseInt(event, 10) / 100);
    map.current.setPaintProperty('balloon7', 'raster-opacity', parseInt(event, 10) / 100);
    map.current.setPaintProperty('balloon8', 'raster-opacity', parseInt(event, 10) / 100);
    map.current.setPaintProperty('balloon9', 'raster-opacity', parseInt(event, 10) / 100);
    map.current.setPaintProperty('balloon10', 'raster-opacity', parseInt(event, 10) / 100);
    map.current.setPaintProperty('balloon11', 'raster-opacity', parseInt(event, 10) / 100);
    map.current.setPaintProperty('balloon12', 'raster-opacity', parseInt(event, 10) / 100);
    map.current.setPaintProperty('balloon13', 'raster-opacity', parseInt(event, 10) / 100);
    map.current.setPaintProperty('balloon14', 'raster-opacity', parseInt(event, 10) / 100);
    map.current.setPaintProperty('balloon15', 'raster-opacity', parseInt(event, 10) / 100);
    map.current.setPaintProperty('balloon16', 'raster-opacity', parseInt(event, 10) / 100);
    map.current.setPaintProperty('balloon17', 'raster-opacity', parseInt(event, 10) / 100);
    map.current.setPaintProperty('balloon18', 'raster-opacity', parseInt(event, 10) / 100);
    map.current.setPaintProperty('balloon19', 'raster-opacity', parseInt(event, 10) / 100);
    map.current.setPaintProperty('balloon20', 'raster-opacity', parseInt(event, 10) / 100);
  }

  async function handleSliderChange(event) {
    // console.log(event);
    const tropomiLayers = map.current.getStyle().layers
    // console.log(tropomiLayers)
    tropomiLayers.forEach(element => {
      const layerId = element['id']
      if (layerId.includes('tropomi')) {
        map.current.setPaintProperty(
          element['id'],
          'raster-opacity',
          parseInt(event, 10) / 100
        );
      }
    });

    // map.current.setPaintProperty(
    //   'tropomi1',
    //   'raster-opacity',
    //   parseInt(event, 10) / 100
    // );
    // map.current.setPaintProperty(
    //   'tropomi2',
    //   'raster-opacity',
    //   parseInt(event, 10) / 100
    // );
    // map.current.setPaintProperty(
    //   'tropomi3',
    //   'raster-opacity',
    //   parseInt(event, 10) / 100
    // );
    // map.current.setPaintProperty(
    //   'tropomi4',
    //   'raster-opacity',
    //   parseInt(event, 10) / 100
    // );
    // map.current.setPaintProperty(
    //   'tropomi5',
    //   'raster-opacity',
    //   parseInt(event, 10) / 100
    // );
    // map.current.setPaintProperty(
    //   'tropomi6',
    //   'raster-opacity',
    //   parseInt(event, 10) / 100
    // );
    // map.current.setPaintProperty(
    //   'tropomi7',
    //   'raster-opacity',
    //   parseInt(event, 10) / 100
    // );
    // map.current.setPaintProperty(
    //   'tropomi8',
    //   'raster-opacity',
    //   parseInt(event, 10) / 100
    // );
    // map.current.setPaintProperty(
    //   'tropomi9',
    //   'raster-opacity',
    //   parseInt(event, 10) / 100
    // );
    // map.current.setPaintProperty(
    //   'tropomi10',
    //   'raster-opacity',
    //   parseInt(event, 10) / 100
    // );
    // map.current.setPaintProperty(
    //   'tropomi11',
    //   'raster-opacity',
    //   parseInt(event, 10) / 100
    // );
    // map.current.setPaintProperty(
    //   'tropomi12',
    //   'raster-opacity',
    //   parseInt(event, 10) / 100
    // );
  }

  // const sentinelUrl = 'ffa82a6d-9716-4958-8ad7-ef9995c16aa5_0_b12_tg.tif'
  const sentinelUrl = 'balloon/demo/4a495c2f-c715-44e2-978f-46b08a2f4376_0_diff_u8.tif'
  const [signedSentinelUrl, setSignedSentinelUrl] = React.useState("");
  const soofieOffsetLat = 2;
  const soofieOffsetLon = -4;
  // const soofieOffsetLat = 0;
  // const soofieOffsetLon = 0;
  const [preferencesMT, setPreferencesMT] = useState({ pageSize: 10, visibleContent: ['link', 'value', 'time'] });
  const [preferences, setPreferences] = useState({ pageSize: 20, visibleContent: ['link', 'visible', 'rate', 'download'] });
  const [catalogPreferences, setCatalogPreferences] = useState({ pageSize: 10, visibleContent: ['name', 'date', 'display', 'lat', 'lon', 'cog', 'h5'] });


  const [siteOptions, setSiteOptions] = React.useState([{ label: "All", value: "all" }])
  const [selectedRegion, setSelectedRegion] = React.useState({ label: "All", value: "all", region: "all", site: "all", field: "all", position: { lng: -103.80685014, lat: 32.10275163, zoom: 4 } })
  const [selectedField, setSelectedField] = React.useState({ label: "All", value: "all", position: { lng: -103.80685014, lat: 32.10275163, zoom: 4 } })
  const [selectedSite, setSelectedSite] = React.useState({ label: "All", value: "all", position: { lng: -103.80685014, lat: 32.10275163, zoom: 4 } })
  const [tropomiCards, setTropomiCards] = React.useState([])
  const [tropomiChecked, setTropomiChecked] = React.useState(true)
  const [plumeChecked, setPlumeChecked] = React.useState(true)

  const [groundInfoVis, setGroundInfoVis] = React.useState(false)
  const [balloonInfoVis, setBalloonInfoVis] = React.useState(false)
  const [scepterInfoVis, setScepterInfoVis] = React.useState(false)
  const [satelliteInfoVis, setSatelliteInfoVis] = React.useState(false)
  const [cursorCoords, setCursorCoords] = useState({ 'lng': 0, 'lat': 0 });
  const [allTableData, setAllTableData] = useState([]);
  const [filterPlumeTable, setFilterPlumeTable] = useState([]);

  const [catalogData, setCatalogData] = React.useState([])
  const [allCatalogTableData, setAllCatalogTableData] = useState([]);
  const [filterCatalogTable, setFilterCatalogTable] = useState([]);

  const [tropomiCatalogData, setTropomiCatalogData] = React.useState([])
  const [allTropomiCatalogTableData, setAllTropomiCatalogTableData] = useState([]);
  const [filterTropomiCatalogTable, setTropomiFilterCatalogTable] = useState([]);

  const [msiCatalogData, setMsiCatalogData] = React.useState([])
  const [allMsiCatalogTableData, setAllMsiCatalogTableData] = useState([]);
  const [filterMsiCatalogTable, setMsiFilterCatalogTable] = useState([]);

  const [timeZoneValue, setTimeZoneValue] = React.useState({ value: "UTC", label: "UTC" });
  const [
    selectedMsiItems,
    setSelectedMsiItems
  ] = React.useState([{ alt: "2023-10-17" }, { alt: "2023-11-21" }, { alt: "2023-11-23" }, { alt: "2023-12-11" }]);
  const [
    selectedMsiCatalogItems,
    setSelectedMsiCatalogItems
  ] = React.useState([]);
  const [
    selectedTropomiCatalogItems,
    setSelectedTropomiCatalogItems
  ] = React.useState([]);
  const [
    selectedCatalogItems,
    setSelectedCatalogItems
  ] = React.useState([]);
  const [
    selectedItems,
    setSelectedItems
  ] = React.useState([]);
  const [
    selectedMeasurement,
    setSelectedMeasurement
  ] = React.useState([]);
  const [checked1, setChecked1] = React.useState(true)
  const [checked2, setChecked2] = React.useState(true)
  const [checked3, setChecked3] = React.useState(true)
  const [checked4, setChecked4] = React.useState(true)
  const [checked5, setChecked5] = React.useState(true)
  const [checked6, setChecked6] = React.useState(true)
  const [checked7, setChecked7] = React.useState(true)
  const [checked8, setChecked8] = React.useState(true)
  const [checked9, setChecked9] = React.useState(true)
  const [checked10, setChecked10] = React.useState(true)
  const [checked11, setChecked11] = React.useState(true)
  const [checked12, setChecked12] = React.useState(true)
  const [checked13, setChecked13] = React.useState(true)
  const [checked14, setChecked14] = React.useState(true)
  const [checked15, setChecked15] = React.useState(true)
  const [checked16, setChecked16] = React.useState(true)
  const [checked17, setChecked17] = React.useState(true)
  const [checked18, setChecked18] = React.useState(true)
  const [checked19, setChecked19] = React.useState(true)
  const [checked20, setChecked20] = React.useState(true)
  const [popups, setPopups] = React.useState({})

  // const balloonUrl = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023292161430000_e2023292161629000_c2023347060911852.corr_R004.tif'

  // const balloonUrl2 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023292163731000_e2023292163920000_c2023347080326174.corr_R004.tif'

  // const balloonUrl3 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023292203024000_e2023292203224000_c2023348064456379.corr_R004.tif'

  // const balloonUrl4 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293154928000_e2023293155128000_c2023346225706667.corr_R004.tif'

  // const balloonUrl5 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293155128000_e2023293155328000_c2023346230934079.corr_R004.tif'

  // const balloonUrl6 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293155707000_e2023293155857000_c2023346234014485.corr_R004.tif'

  // const balloonUrl7 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293160058000_e2023293160258000_c2023346235946816.corr_R004.tif'

  // const balloonUrl8 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293162459000_e2023293162659000_c2023347020259277.corr_R004.tif'

  // const balloonUrl9 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293165629000_e2023293165829000_c2023347044644047.corr_R004.tif'

  // const balloonUrl10 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293170513000_e2023293170713000_c2023347053251420.corr_R004.tif'

  // const balloonUrl11 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293172914000_e2023293173114000_c2023347074234047.corr_R004.tif'

  // const balloonUrl12 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293184333000_e2023293184533000_c2023347134949889.corr_R004.tif'

  // const balloonUrl13 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293184933000_e2023293185056000_c2023347142101528.corr_R004.tif'

  // const balloonUrl14 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293191301000_e2023293191501000_c2023347162651403.corr_R004.tif'

  // const balloonUrl15 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293191501000_e2023293191701000_c2023347163743939.corr_R004.tif'

  // const balloonUrl16 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293191701000_e2023293191901000_c2023347164845355.corr_R004.tif'

  // const balloonUrl17 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293192101000_e2023293192225000_c2023347171041238.corr_R004.tif'

  // const balloonUrl18 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293194231000_e2023293194431000_c2023347190821037.corr_R004.tif'

  // const balloonUrl19 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293194831000_e2023293195031000_c2023347194102457.corr_R004.tif'

  // const balloonUrl20 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293201203000_e2023293201403000_c2023347221003180.corr_R004.tif'


  const balloonUrl = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023292161430000_e2023292161629000_c2023347060911852.corr_R004.tif'

  const balloonUrl2 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023292163731000_e2023292163920000_c2023347080326174.corr_R004.tif'

  const balloonUrl3 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023292203024000_e2023292203224000_c2023348064456379.corr_R004.tif'

  const balloonUrl4 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293154928000_e2023293155128000_c2023346225706667.corr_R004.tif'

  const balloonUrl5 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293155128000_e2023293155328000_c2023346230934079.corr_R004.tif'

  const balloonUrl6 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293155707000_e2023293155857000_c2023346234014485.corr_R004.tif'

  const balloonUrl7 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293160058000_e2023293160258000_c2023346235946816.corr_R004.tif'

  const balloonUrl8 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293162459000_e2023293162659000_c2023347020259277.corr_R004.tif'

  const balloonUrl9 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293165629000_e2023293165829000_c2023347044644047.corr_R004.tif'

  const balloonUrl10 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293170513000_e2023293170713000_c2023347053251420.corr_R004.tif'

  const balloonUrl11 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293172914000_e2023293173114000_c2023347074234047.corr_R004.tif'

  const balloonUrl12 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293184333000_e2023293184533000_c2023347134949889.corr_R004.tif'

  const balloonUrl13 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293184933000_e2023293185056000_c2023347142101528.corr_R004.tif'

  const balloonUrl14 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293191301000_e2023293191501000_c2023347162651403.corr_R004.tif'

  const balloonUrl15 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293191501000_e2023293191701000_c2023347163743939.corr_R004.tif'

  const balloonUrl16 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293191701000_e2023293191901000_c2023347164845355.corr_R004.tif'

  const balloonUrl17 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293192101000_e2023293192225000_c2023347171041238.corr_R004.tif'

  const balloonUrl18 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293194231000_e2023293194431000_c2023347190821037.corr_R004.tif'

  const balloonUrl19 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293194831000_e2023293195031000_c2023347194102457.corr_R004.tif'

  const balloonUrl20 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293201203000_e2023293201403000_c2023347221003180.corr_R004.tif'

  function onPlumeClick(...args) {
    // console.log(args)
    const e = args[1]
    // console.log(extra)

    const coordinates = e.features[0].geometry.coordinates.slice();
    const id = e.features[0].properties.id;
    // console.log(timeZoneValue)
    // console.log(e.features[0].properties.date)
    // const stats = JSON.parse(e.features[0].properties.stats);
    // // console.log(stats)
    // const site = e.features[0].properties.site;
    // <b>Site:</b> ${site}<br />
    // <b>Time:</b> ${(new Date(e.features[0].properties.date)).toLocaleString("en-US", {
    //   timeZone: timeZoneValue.value.value,timeZoneName: 'short', year:'numeric',month:'numeric',day:'numeric', hour: '2-digit',
    //   minute: '2-digit'
    // })}. <br />
    var text = `     
            <b>Time:</b> ${(new Date(e.features[0].properties.date)).toLocaleString('en-US', {
      timeZone: timeZoneValue.value, timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
      minute: '2-digit'
    })} <br />
            <b>Rate:</b> ${e.features[0].properties.rate.toFixed(2)} kg/hr <br />
            <b>Lat:</b> ${e.features[0].properties.lat.toFixed(5)} deg<br />
            <b>Lon:</b> ${e.features[0].properties.lon.toFixed(5)} deg<br />
            `

    // const mag = e.features[0].properties.mag;
    // const tsunami =
    // e.features[0].properties.tsunami === 1 ? 'yes' : 'no';

    // Ensure that if the map is zoomed out such that
    // multiple copies of the feature are visible, the
    // popup appears over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    // const newPopup = new maplibregl.Popup({ offset: 10, closeOnClick: true })
    //   .setLngLat(coordinates)
    //   // .trackPointer()
    //   .setHTML(
    //     text
    //   )
    //   .addTo(map.current);

    popups[id].addTo(map.current)

    // popups[id] = newPopup
    // console.log(popups)
    // console.log(id)
    // setPopups(popups)

    // map.current.easeTo({
    //   center: coordinates,
    //   zoom: 14
    // });
  }
  const timeZoneOptions = {
    "UTC": "UTC",
    "America/Los_Angeles": "Pacific",
    "America/Denver": "Mountain",
    "America/Chicago": "Central",
    "America/New_York": "Eastern",
  }
  const [catalogTableData, setCatalogTableData] = useState([
    {
      "id": "DE_DAEDALUS-001_MethaneL2_s2021061080000000_e2021061090000000_c2023038131041542",
      "bbox": [
        -103.96064113819375,
        32.04785881494779,
        -103.91828767139768,
        32.08392973500642
      ],
      "type": "Feature",
      "links": [
        {
          "rel": "collection",
          "type": "application/json",
          "href": "https://6oznyhwqob.execute-api.us-east-2.amazonaws.com/collections/L2_data"
        },
        {
          "rel": "parent",
          "type": "application/json",
          "href": "https://6oznyhwqob.execute-api.us-east-2.amazonaws.com/collections/L2_data"
        },
        {
          "rel": "root",
          "type": "application/json",
          "href": "https://6oznyhwqob.execute-api.us-east-2.amazonaws.com/"
        },
        {
          "rel": "self",
          "type": "application/geo+json",
          "href": "https://6oznyhwqob.execute-api.us-east-2.amazonaws.com/collections/L2_data/items/DE_DAEDALUS-001_MethaneL2_s2021061080000000_e2021061090000000_c2023038131041542"
        }
      ],
      "assets": {
        "cog": {
          "href": "s3://527058141432-us-east-2-balloon-data-bucket/aer_storage/DE_DAEDALUS-001_MethaneL2_s2021061080000000_e2021061090000000_c2023038131041542.tif",
          "type": "image/tiff; application=geotiff; profile=cloud-optimized",
          "roles": [
            "data"
          ],
          "title": "L2 Methane GeoTIFF"
        },
        "h5_data": {
          "href": "s3://527058141432-us-east-2-external-shared-bucket/aer_deliveries/DE_DAEDALUS-001_MethaneL2_s2021061080000000_e2021061090000000_c2023038131041542.h5",
          "type": "application/x-hdf5",
          "roles": [
            "data"
          ],
          "title": "L2 File"
        }
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -103.91828767139768,
              32.04785881494779
            ],
            [
              -103.91828767139768,
              32.08392973500642
            ],
            [
              -103.96064113819375,
              32.08392973500642
            ],
            [
              -103.96064113819375,
              32.04785881494779
            ],
            [
              -103.91828767139768,
              32.04785881494779
            ]
          ]
        ]
      },
      "collection": "L2_data",
      "properties": {
        "gsd": 0.5,
        "title": "L2 Product",
        "created": "2023-03-31T01:35:13.762089Z",
        "mission": "Permian_1",
        "updated": "2023-03-31T01:35:13.762089Z",
        "datetime": "2023-03-31T01:35:15.733968Z",
        "platform": "Stratollite",
        "proj:epsg": 32613,
        "proj:shape": [
          1,
          160,
          156
        ],
        "description": "A calibrated and processed L2 product",
        "instruments": [
          "Headwall Micro-Hyperspec SWIR 384"
        ],
        "constellation": "World_View",
        "proj:transform": [
          0.5,
          0.0,
          598085.8283929707,
          0.0,
          -0.5,
          3550211.3119769073,
          0.0,
          0.0,
          1.0
        ],
        "scepter:layer_count": 5,
        "scepter:processing_level": "L2"
      },
      "stac_version": "1.0.0",
      "stac_extensions": [
        "https://stac-extensions.github.io/projection/v1.1.0/schema.json",
        "https://stac-extensions.github.io/eo/v1.0.0/schema.json",
        "https://stac-extensions.github.io/view/v1.0.0/schema.json"
      ]
    }
  ],
  );

  const [tableData, setTableData] = useState([
    {
      // name: (new Date(Date.UTC(2023, 10, 19, 16, 14, 30))).toLocaleString("en-US", {
      //   timeZone: timeZoneValue.value
      // }),
      // date: (new Date(Date.UTC(2023, 10, 19, 16, 14, 30))).toLocaleString("en-US", {
      //   timeZone: timeZoneValue.value
      // }),
      name: 'Oct 19, 16:14:30Z - 16:16:29Z',
      // date: 'Oct 19, 16:14:30Z - 16:16:29Z',
      layerName: 'balloon1',
      date: '2023-10-19T16:14:30Z',
      background: 9.518,
      mass: 16453.287,
      rate: 288.196,
      u10: 352.120,
      u10speed: 1.890,
      lat: 32.19139,
      lon: -104.10488,
      link: <Link onFollow={() => {
        map.current.flyTo({
          center: [
            -104.10488 + soofieOffsetLon,
            32.19139 + soofieOffsetLat
          ],
          zoom: 15
          // essential: true // this animation is considered essential with respect to prefers-reduced-motion
        })
      }}>{
          (new Date(Date.UTC(2023, 9, 19, 16, 14, 30))).toLocaleString("en-US", {
            timeZone: timeZoneValue.value,
            timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })}
      </Link>
      ,
      download: <Button iconName="download" variant="inline-icon" onClick={() => downloadPlume(balloonUrl)} />,
      visible: <Checkbox
        onChange={({ detail }) =>
          setBallonVis1(detail.checked)
        }
        checked={checked1}
      ></Checkbox>
      // availabilityZone: 'us-east-1c',
      // state: 'Healthy',
      // lastModified: new Date(2019, 3, 28, 4, 16),
    },
    {
      name: 'Oct 19, 16:37:31Z - 16:39:20Z',
      date: 'Oct 19 2023, 16:37:31Z',
      background: 9.469,
      mass: 420609.426,
      rate: 1548.330,
      u10: 18.498,
      u10speed: 1.125,
      visible: <Checkbox
        onChange={({ detail }) =>
          setBallonVis2(detail.checked)
        }
        checked={checked2}
      ></Checkbox>,
      lat: 31.99759,
      layerName: 'balloon2',
      download: <Button iconName="download" variant="inline-icon" onClick={() => downloadPlume(balloonUrl2)} />,
      lon: -104.14920,
      link: <Link onFollow={() => map.current.flyTo({
        center: [
          -104.14920 + soofieOffsetLon,
          31.99759 + soofieOffsetLat
        ],
        zoom: 15
        // essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })}>{
          (new Date(Date.UTC(2023, 9, 19, 16, 37, 31))).toLocaleString("en-US", {
            timeZone: timeZoneValue.value,
            timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })}</Link>,
      // availabilityZone: 'us-east-1a',
      // state: 'Healthy',
      // lastModified: new Date(2022, 5, 5, 15, 32),
    },
    {
      name: 'Oct 19, 20:30:24Z - 20:32:24Z',
      date: 'Oct 19 2023, 20:30:24Z',
      background: 9.545,
      mass: 2933.730,
      rate: 216.022,
      u10: 166.734,
      u10speed: 4.288,
      visible: <Checkbox
        onChange={({ detail }) =>
          setBallonVis3(detail.checked)
        }
        checked={checked3}
      ></Checkbox>,
      layerName: 'balloon3',
      download: <Button iconName="download" variant="inline-icon" onClick={() => downloadPlume(balloonUrl3)} />,
      lon: -104.13220,
      lat: 32.26482,
      link: <Link onFollow={() => map.current.flyTo({
        center: [
          -104.13220 + soofieOffsetLon,
          32.26482 + soofieOffsetLat
        ],
        zoom: 15
        // essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })}>{
          (new Date(Date.UTC(2023, 9, 19, 20, 30, 24))).toLocaleString("en-US", {
            timeZone: timeZoneValue.value,
            timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })}</Link>,
      // availabilityZone: 'us-east-1d',
      // state: 'Healthy',
      // lastModified: new Date(2020, 2, 8, 9, 24),
    },
    {
      name: 'Oct 20, 15:49:28Z - 15:51:28Z',
      date: 'Oct 20 2023, 15:49:28Z',
      background: 9.438,
      mass: 3666.964,
      rate: 19.153,
      u10: 226.981,
      layerName: 'balloon4',
      download: <Button iconName="download" variant="inline-icon" onClick={() => downloadPlume(balloonUrl4)} />,
      u10speed: 0.502,
      visible: <Checkbox
        onChange={({ detail }) =>
          setBallonVis4(detail.checked)
        }
        checked={checked4}
      ></Checkbox>,
      lon: -103.79525,
      lat: 32.31426,
      link: <Link onFollow={() => map.current.flyTo({
        center: [
          -103.79525 + soofieOffsetLon,
          32.31426 + soofieOffsetLat
        ],
        zoom: 15
        // essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })}>{
          (new Date(Date.UTC(2023, 9, 20, 15, 49, 28))).toLocaleString("en-US", {
            timeZone: timeZoneValue.value,
            timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })}</Link>,
      // availabilityZone: 'us-east-1d',
      // state: 'Healthy',
      // lastModified: new Date(2020, 2, 8, 9, 24),
    },
    {
      name: 'Oct 20, 15:51:28Z - 15:53:28Z',
      date: 'Oct 20 2023, 15:51:28Z',
      background: 9.387,
      mass: 2407.385,
      rate: 29.920,
      u10: 190.289,
      u10speed: 0.339,
      layerName: 'balloon5',
      download: <Button iconName="download" variant="inline-icon" onClick={() => downloadPlume(balloonUrl5)} />,
      visible: <Checkbox
        onChange={({ detail }) =>
          setBallonVis5(detail.checked)
        }
        checked={checked5}
      ></Checkbox>,
      lon: -103.79262,
      lat: 32.27590,
      link: <Link onFollow={() => map.current.flyTo({
        center: [
          -103.79262 + soofieOffsetLon,
          32.27590 + soofieOffsetLat
        ],
        zoom: 15
        // essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })}>{
          (new Date(Date.UTC(2023, 9, 20, 15, 51, 28))).toLocaleString("en-US", {
            timeZone: timeZoneValue.value,
            timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })}</Link>,
      // availabilityZone: 'us-east-1d',
      // state: 'Healthy',
      // lastModified: new Date(2020, 2, 8, 9, 24),
    },
    {
      name: 'Oct 20, 15:57:07Z - 15:58:57Z',
      date: 'Oct 20 2023, 15:57:07Z',
      background: 9.444,
      mass: 1487.160,
      rate: 31.312,
      u10: 262.428,
      layerName: 'balloon6',
      download: <Button iconName="download" variant="inline-icon" onClick={() => downloadPlume(balloonUrl6)} />,
      u10speed: 0.650,
      visible: <Checkbox
        onChange={({ detail }) =>
          setBallonVis6(detail.checked)
        }
        checked={checked6}
      ></Checkbox>,
      lon: -103.87758,
      lat: 32.14216,
      link: <Link onFollow={() => map.current.flyTo({
        center: [
          -103.87758 + soofieOffsetLon,
          32.14216 + soofieOffsetLat
        ],
        zoom: 15
        // essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })}>{
          (new Date(Date.UTC(2023, 9, 20, 15, 57, 7))).toLocaleString("en-US", {
            timeZone: timeZoneValue.value,
            timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })}</Link>,
      // availabilityZone: 'us-east-1d',
      // state: 'Healthy',
      // lastModified: new Date(2020, 2, 8, 9, 24),
    },
    {
      name: 'plume_georef_DE_DAEDALUS-002_MethaneL2_s2023293194831000_e2023293195031000_c2023347194102457.corr_R004',
      date: 'Oct 20 2023, 16:00:58Z',
      background: 9.504,
      mass: 11727.962,
      rate: 130.395,
      u10: 296.329,
      u10speed: 0.864,
      layerName: 'balloon7',
      download: <Button iconName="download" variant="inline-icon" onClick={() => downloadPlume(balloonUrl7)} />,
      visible: <Checkbox
        onChange={({ detail }) =>
          setBallonVis7(detail.checked)
        }
        checked={checked7}
      ></Checkbox>,
      lon: -103.79139,
      lat: 32.27529,
      link: <Link onFollow={() => map.current.flyTo({
        center: [
          -103.79139 + soofieOffsetLon,
          32.27529 + soofieOffsetLat
        ],
        zoom: 15
        // essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })}>{
          (new Date(Date.UTC(2023, 9, 20, 16, 0, 58))).toLocaleString("en-US", {
            timeZone: timeZoneValue.value,
            timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })}</Link>,
      // availabilityZone: 'us-east-1d',
      // state: 'Healthy',
      // lastModified: new Date(2020, 2, 8, 9, 24),
    },
    {
      name: 'Oct 20, 16:24:59Z - 16:26:59Z',
      date: 'Oct 20 2023, 16:24:59Z',
      background: 9.473,
      mass: 225.689,
      rate: 27.495,
      u10: 268.775,
      u10speed: 0.764,
      layerName: 'balloon8',
      download: <Button iconName="download" variant="inline-icon" onClick={() => downloadPlume(balloonUrl8)} />,

      visible: <Checkbox
        onChange={({ detail }) =>
          setBallonVis8(detail.checked)
        }
        checked={checked8}
      ></Checkbox>,
      lon: -103.82898,
      lat: 32.22837,
      link: <Link onFollow={() => map.current.flyTo({
        center: [
          -103.82898 + soofieOffsetLon,
          32.22837 + soofieOffsetLat
        ],
        zoom: 15
        // essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })}>{
          (new Date(Date.UTC(2023, 9, 20, 16, 24, 59))).toLocaleString("en-US", {
            timeZone: timeZoneValue.value,
            timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })}</Link>,
      // availabilityZone: 'us-east-1d',
      // state: 'Healthy',
      // lastModified: new Date(2020, 2, 8, 9, 24),
    },
    {
      name: 'Oct 20, 16:56:29Z - 16:58:29Z',
      date: 'Oct 20 2023, 16:56:29Z',
      background: 9.490,
      mass: 3055.096,
      rate: 111.308,
      u10: 261.822,
      layerName: 'balloon9',
      download: <Button iconName="download" variant="inline-icon" onClick={() => downloadPlume(balloonUrl9)} />,
      u10speed: 1.095,
      visible: <Checkbox
        onChange={({ detail }) =>
          setBallonVis9(detail.checked)
        }
        checked={checked9}
      ></Checkbox>,
      lon: -103.79031,
      lat: 32.27091,
      link: <Link onFollow={() => map.current.flyTo({
        center: [
          -103.79031 + soofieOffsetLon,
          32.27091 + soofieOffsetLat
        ],
        zoom: 15
        // essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })}>{
          (new Date(Date.UTC(2023, 9, 20, 16, 56, 29))).toLocaleString("en-US", {
            timeZone: timeZoneValue.value,
            timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })}</Link>,
      // availabilityZone: 'us-east-1d',
      // state: 'Healthy',
      // lastModified: new Date(2020, 2, 8, 9, 24),
    },
    {
      name: 'Oct 20, 17:05:13Z - 17:07:13Z',
      date: 'Oct 20 2023, 17:05:13Z',
      background: 9.485,
      mass: 162.229,
      layerName: 'balloon10',
      download: <Button iconName="download" variant="inline-icon" onClick={() => downloadPlume(balloonUrl10)} />,
      rate: 30.862,
      u10: 289.225,
      u10speed: 0.913,
      visible: <Checkbox
        onChange={({ detail }) =>
          setBallonVis10(detail.checked)
        }
        checked={checked10}
      ></Checkbox>,
      lon: -103.79309,
      lat: 32.28055,
      link: <Link onFollow={() => map.current.flyTo({
        center: [
          -103.79309 + soofieOffsetLon,
          32.28055 + soofieOffsetLat
        ],
        zoom: 15
        // essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })}>{
          (new Date(Date.UTC(2023, 9, 20, 17, 5, 13))).toLocaleString("en-US", {
            timeZone: timeZoneValue.value,
            timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })}</Link>,
      // availabilityZone: 'us-east-1d',
      // state: 'Healthy',
      // lastModified: new Date(2020, 2, 8, 9, 24),
    },
    {
      name: 'Oct 20, 17:29:14Z - 17:31:14Z',
      date: 'Oct 20 2023, 17:29:14Z',
      background: 9.472,
      mass: 3441.932,
      rate: 97.570,
      u10: 249.219,
      layerName: 'balloon11',
      download: <Button iconName="download" variant="inline-icon" onClick={() => downloadPlume(balloonUrl11)} />,
      u10speed: 1.236,
      visible: <Checkbox
        onChange={({ detail }) =>
          setBallonVis11(detail.checked)
        }
        checked={checked11}
      ></Checkbox>,
      lon: -103.79255,
      lat: 32.1098,
      link: <Link onFollow={() => map.current.flyTo({
        center: [
          -103.79255 + soofieOffsetLon,
          32.1098 + soofieOffsetLat
        ],
        zoom: 15
        // essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })}>{
          (new Date(Date.UTC(2023, 9, 20, 17, 29, 14))).toLocaleString("en-US", {
            timeZone: timeZoneValue.value,
            timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })}</Link>,
      // availabilityZone: 'us-east-1d',
      // state: 'Healthy',
      // lastModified: new Date(2020, 2, 8, 9, 24),
    },
    {
      name: 'Oct 20, 18:43:33Z - 18:45:33Z',
      date: 'Oct 20 2023, 18:43:33Z',
      background: 9.403,
      mass: 125.943,
      rate: 55.754,
      u10: 186.388,
      u10speed: 1.691,
      layerName: 'balloon12',
      download: <Button iconName="download" variant="inline-icon" onClick={() => downloadPlume(balloonUrl12)} />,

      visible: <Checkbox
        onChange={({ detail }) =>
          setBallonVis12(detail.checked)
        }
        checked={checked12}
      ></Checkbox>,
      lon: -103.79086,
      lat: 32.31488,
      link: <Link onFollow={() => map.current.flyTo({
        center: [
          -103.79086 + soofieOffsetLon,
          32.31488 + soofieOffsetLat
        ],
        zoom: 15
        // essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })}>{
          (new Date(Date.UTC(2023, 9, 20, 18, 43, 33))).toLocaleString("en-US", {
            timeZone: timeZoneValue.value,
            timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })}</Link>,
      // availabilityZone: 'us-east-1d',
      // state: 'Healthy',
      // lastModified: new Date(2020, 2, 8, 9, 24),
    },
    {
      name: 'Oct 20, 18:49:33Z - 18:50:56Z',
      date: 'Oct 20 2023, 18:49:33Z',
      background: 9.489,
      mass: 1251.514,
      rate: 116.561,
      u10: 194.411,
      layerName: 'balloon13',
      download: <Button iconName="download" variant="inline-icon" onClick={() => downloadPlume(balloonUrl13)} />,
      u10speed: 1.979,
      visible: <Checkbox
        onChange={({ detail }) =>
          setBallonVis13(detail.checked)
        }
        checked={checked13}
      ></Checkbox>,
      lon: -104.00296,
      lat: 32.19602,
      link: <Link onFollow={() => map.current.flyTo({
        center: [
          -104.00296 + soofieOffsetLon,
          32.19602 + soofieOffsetLat
        ],
        zoom: 15
        // essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })}>{
          (new Date(Date.UTC(2023, 9, 20, 18, 49, 33))).toLocaleString("en-US", {
            timeZone: timeZoneValue.value,
            timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })}</Link>,
      // availabilityZone: 'us-east-1d',
      // state: 'Healthy',
      // lastModified: new Date(2020, 2, 8, 9, 24),
    },
    {
      name: 'Oct 20, 19:13:01Z - 19:15:01Z',
      date: 'Oct 20 2023, 19:13:01Z',
      background: 9.551,
      mass: 17628.397,
      rate: 411.782,
      u10: 230.203,
      u10speed: 1.391,
      layerName: 'balloon14',
      download: <Button iconName="download" variant="inline-icon" onClick={() => downloadPlume(balloonUrl14)} />,
      visible: <Checkbox
        onChange={({ detail }) =>
          setBallonVis14(detail.checked)
        }
        checked={checked14}
      ></Checkbox>,
      lon: -103.92634,
      lat: 32.30699,
      link: <Link onFollow={() => map.current.flyTo({
        center: [
          -103.92634 + soofieOffsetLon,
          32.30699 + soofieOffsetLat
        ],
        zoom: 15
        // essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })}>{
          (new Date(Date.UTC(2023, 9, 20, 19, 13, 1))).toLocaleString("en-US", {
            timeZone: timeZoneValue.value,
            timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })}</Link>,
      // availabilityZone: 'us-east-1d',
      // state: 'Healthy',
      // lastModified: new Date(2020, 2, 8, 9, 24),
    },
    {
      name: 'Oct 20, 19:15:01Z - 19:17:01Z',
      date: 'Oct 20 2023, 19:15:01Z',
      background: 9.451,
      mass: 34695.989,
      rate: 749.537,
      u10: 193.814,
      layerName: 'balloon15',
      download: <Button iconName="download" variant="inline-icon" onClick={() => downloadPlume(balloonUrl15)} />,
      u10speed: 1.986,
      visible: <Checkbox
        onChange={({ detail }) =>
          setBallonVis15(detail.checked)
        }
        checked={checked15}
      ></Checkbox>,
      lon: -103.79310,
      lat: 32.31565,
      link: <Link onFollow={() => map.current.flyTo({
        center: [
          -103.79310 + soofieOffsetLon,
          32.31565 + soofieOffsetLat
        ],
        zoom: 15
        // essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })}>{
          (new Date(Date.UTC(2023, 9, 20, 19, 15, 1))).toLocaleString("en-US", {
            timeZone: timeZoneValue.value,
            timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })}</Link>,
      // availabilityZone: 'us-east-1d',
      // state: 'Healthy',
      // lastModified: new Date(2020, 2, 8, 9, 24),
    },
    {
      name: 'Oct 20, 19:17:01Z - 19:19:01Z',
      date: 'Oct 20 2023, 19:17:01Z',
      background: 9.432,
      mass: 27100.938,
      rate: 302.003,
      u10: 203.100,
      layerName: 'balloon16',
      download: <Button iconName="download" variant="inline-icon" onClick={() => downloadPlume(balloonUrl16)} />,

      u10speed: 1.912,
      visible: <Checkbox
        onChange={({ detail }) =>
          setBallonVis16(detail.checked)
        }
        checked={checked16}
      ></Checkbox>,
      lon: -103.92850,
      lat: 32.30745,
      link: <Link onFollow={() => map.current.flyTo({
        center: [
          -103.92850 + soofieOffsetLon,
          32.30745 + soofieOffsetLat
        ],
        zoom: 15
        // essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })}>{
          (new Date(Date.UTC(2023, 9, 20, 19, 17, 1))).toLocaleString("en-US", {
            timeZone: timeZoneValue.value,
            timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })}</Link>,
      // availabilityZone: 'us-east-1d',
      // state: 'Healthy',
      // lastModified: new Date(2020, 2, 8, 9, 24),
    },
    {
      name: 'Oct 20, 19:21:01Z - 19:22:25Z',
      date: 'Oct 20 2023, 19:21:01Z',
      background: 9.501,
      mass: 14253.204,
      rate: 456.757,
      u10: 208.176,
      u10speed: 2.150,
      layerName: 'balloon17',
      download: <Button iconName="download" variant="inline-icon" onClick={() => downloadPlume(balloonUrl17)} />,
      visible: <Checkbox
        onChange={({ detail }) =>
          setBallonVis17(detail.checked)
        }
        checked={checked17}
      ></Checkbox>,
      lon: -104.03101,
      lat: 32.24513,
      link: <Link onFollow={() => map.current.flyTo({
        center: [
          -104.03101 + soofieOffsetLon,
          32.24513 + soofieOffsetLat
        ],
        zoom: 15
        // essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })}>{
          (new Date(Date.UTC(2023, 9, 20, 19, 21, 1))).toLocaleString("en-US", {
            timeZone: timeZoneValue.value,
            timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })}</Link>,
      // availabilityZone: 'us-east-1d',
      // state: 'Healthy',
      // lastModified: new Date(2020, 2, 8, 9, 24),
    },
    {
      name: 'Oct 20, 19:42:31Z - 19:44:31Z',
      date: 'Oct 20 2023, 19:42:31Z',
      background: 9.535,
      mass: 5010.326,
      rate: 330.643,
      layerName: 'balloon18',
      download: <Button iconName="download" variant="inline-icon" onClick={() => downloadPlume(balloonUrl18)} />,
      u10: 179.700,
      u10speed: 2.381,
      visible: <Checkbox
        onChange={({ detail }) =>
          setBallonVis18(detail.checked)
        }
        checked={checked18}
      ></Checkbox>,
      lon: -104.03457,
      lat: 32.24452,
      link: <Link onFollow={() => map.current.flyTo({
        center: [
          -104.03457 + soofieOffsetLon,
          32.24452 + soofieOffsetLat
        ],
        zoom: 15
        // essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })}>{
          (new Date(Date.UTC(2023, 9, 20, 19, 42, 31))).toLocaleString("en-US", {
            timeZone: timeZoneValue.value,
            timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })}</Link>,
      // availabilityZone: 'us-east-1d',
      // state: 'Healthy',
      // lastModified: new Date(2020, 2, 8, 9, 24),
    },
    {
      name: 'Oct 20, 19:48:31Z - 19:50:31Z',
      date: 'Oct 20 2023, 19:48:31Z',
      background: 9.505,
      mass: 941.499,
      rate: 76.078,
      u10: 229.789,
      layerName: 'balloon19',
      download: <Button iconName="download" variant="inline-icon" onClick={() => downloadPlume(balloonUrl19)} />,
      u10speed: 1.803,
      visible: <Checkbox
        onChange={({ detail }) =>
          setBallonVis19(detail.checked)
        }
        checked={checked19}
      ></Checkbox>,
      lon: -103.92976,
      lat: 32.30952,
      link: <Link onFollow={() => map.current.flyTo({
        center: [
          -103.92976 + soofieOffsetLon,
          32.30952 + soofieOffsetLat
        ],
        zoom: 15
        // essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })}>{
          (new Date(Date.UTC(2023, 9, 20, 19, 48, 31))).toLocaleString("en-US", {
            timeZone: timeZoneValue.value,
            timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })}</Link>,
      // availabilityZone: 'us-east-1d',
      // state: 'Healthy',
      // lastModified: new Date(2020, 2, 8, 9, 24),
    },
    {
      name: 'Oct 20, 20:12:03Z - 20:14:03Z',
      date: 'Oct 20 2023, 20:12:03Z',
      background: 9.512,
      mass: 67701.235,
      rate: 920.516,
      u10: 176.06,
      u10speed: 1.46,
      layerName: 'balloon20',
      download: <Button iconName="download" variant="inline-icon" onClick={() => downloadPlume(balloonUrl20)} />,
      visible: <Checkbox
        onChange={({ detail }) =>
          setBallonVis20(detail.checked)
        }
        checked={checked20}
      ></Checkbox>,
      lon: -104.03262,
      lat: 32.24322,
      link: <Link onFollow={() => map.current.flyTo({
        center: [
          -104.03262 + soofieOffsetLon,
          32.24322 + soofieOffsetLat
        ],
        zoom: 15
        // essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })}>{
          (new Date(Date.UTC(2023, 9, 20, 20, 12, 3))).toLocaleString("en-US", {
            timeZone: timeZoneValue.value,
            timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })}</Link>,
      // availabilityZone: 'us-east-1d',
      // state: 'Healthy',
      // lastModified: new Date(2020, 2, 8, 9, 24),
    },


    // {
    //   name: 'Oct 20, 16:24:59Z - 16:24:59Z',
    //   date: 'Oct 20, 16:24:59Z - 16:24:59Z',
    //   background: 9.473,
    //   mass: 255.689,
    //   rate: 27.495,
    //   u10: 268.775,
    //   u10speed: 0.764,
    //   visible: <Checkbox
    //     onChange={({ detail }) =>
    //       setBallonVis21(detail.checked)
    //     }
    //     checked={checked21}
    //   ></Checkbox>,
    //   link: <Link onFollow={() => map.current.fitBounds([
    //     [-102.33970429080722,
    //       31.870716846771828],
    //     [-102.2402421517698,
    //       32.42405392990251]
    //   ])}>SAMPLE | Oct 20, 16:24:59Z - 16:24:59Z </Link>,
    //   // availabilityZone: 'us-east-1d',
    //   // state: 'Healthy',
    //   // lastModified: new Date(2020, 2, 8, 9, 24),
    // },
  ])

  const [filterState, setFilterState] = React.useState(
    {
      measurementFilterState: [],
      measurementSeries: [],
      measurementTableData: [],
      measurementLoading: 'loading'
      // viewProps: {
      //   lat: 32.10275163,
      //   lon: -103.80685014,
      //   zoom: 7
      // },
      // // viewProps: {
      // //   lat: 38.49182356557019,
      // //   lon: 54.194759,
      // //   zoom: 13
      // // },
      // region: {
      //   label: "Permian",
      //   value: "permian"
      // },
      // field: {
      //   label: "Field 1",
      //   value: "field 1"
      // },
      // site: {
      //   label: "Darlene",
      //   value: "d36689f0-23dd-4064-918c-3f2c9c9ae779"
      // },
      // sensorsChecked: true,
      // alertsChecked: true,
      // tropomiChecked: true,
      // windChecked: true,
      // componentsChecked: false,
      // noaaWindChecked: true,
      // alertMarkers: '',
      // sensorMarkers: '',
      // startDate: '2023-09-05',
      // endDate: '2023-09-05',
      // // startDate: initialStartDate.getUTCFullYear() + '-' + ('0' + (initialStartDate.getUTCMonth() + 1)).slice(-2) + '-' + ('0' + initialStartDate.getUTCDate()).slice(-2),
      // // endDate: initialEndDate.getUTCFullYear() + '-' + ('0' + (initialEndDate.getUTCMonth() + 1)).slice(-2) + '-' + ('0' + initialEndDate.getUTCDate()).slice(-2),
      // sites: [],
      // devices: [],
      // components: [],
      // fluxList: [],
      // lastUpload: 'Loading',
      // fluxTable: [
      //   // {
      //   //   'sensor': 'test',
      //   //   'date': 'test',
      //   //   'value': 'test'
      //   // }
      // ],
      // soofieInfoVis: false,
      // tropomiInfoVis: false,
      // noaaInfoVis: false,
      // plumeUrls: '',
      // visibleTimeSeries: [],
      // visibleTimeSeriesFilter: [],
      // activeMainTab: 'first',
      // visibleFluxSeries: [],
      // visibleFluxSeriesFilter: [],
      // recSearchTlLat: '',
      // recSearchTlLon: '',
      // recSearchBrLat: '',
      // recSearchBrLon: ''
    }
  );

  const [measurementFilterState, setMeasurementsFilterState] = React.useState([]);
  const measurementSeries = []

  const profileActions = [
    // { type: 'button', id: 'profile', text: 'Profile' },
    // { type: 'button', id: 'preferences', text: 'Preferences' },
    // { type: 'button', id: 'security', text: 'Security' },
    // {
    //   type: 'menu-dropdown',
    //   id: 'support-group',
    //   text: 'Support',
    //   items: [
    //     {
    //       id: 'documentation',
    //       text: 'Documentation',
    //       href: '#',
    //       external: true,
    //       externalIconAriaLabel: ' (opens in new tab)',
    //     },
    //     { id: 'feedback', text: 'Feedback', href: '#', external: true, externalIconAriaLabel: ' (opens in new tab)' },
    //     { id: 'support', text: 'Customer support' },

    //   ],
    // },
    // onClick={() => moveToAlert(flux['values']['deviceID'])}>
    { type: 'button', id: 'signout', text: 'Sign out' },

  ];



  // console.log(user)
  // const map = useMap();

  const identityPoolId = "us-east-2:040418ec-4000-48b9-91b7-488671631f87"
  var authHelper = ''
  var newMap = ''



  /**
   * Creates an auth helper instance using credentials from Cognito.
   *
   * @param identityPoolId Cognito Identity Pool Id
   */
  async function withIdentityPoolId2(identityPoolId) {
    // console.log('id pool')
    const region = identityPoolId.split(":")[0];
    const credentialsProvider = fromCognitoIdentityPool({
      identityPoolId,
      clientConfig: {
        region,
      },
    });
    let credentials;
    async function refreshCredentials() {
      credentials = await credentialsProvider();
      let timeToRefresh = 3600000; // default to 1 hour if credentials does not have expiration field
      if (credentials.expiration) {
        timeToRefresh = credentials.expiration.getTime() - new Date().getTime();
      }
      // timeToRefresh minus 1 minute to give some time for the actual refresh to happen.
      setTimeout(refreshCredentials, timeToRefresh - 60000);
    }
    await refreshCredentials();
    return {
      getMapAuthenticationOptions: () => ({
        transformRequest: (url) => {
          // console.log(url)
          // console.log(credentials)
          // Only sign aws URLs
          if (url.includes("amazonaws.com/maps")) {
            const newUrl = {
              url: Signer.signUrl(url, {
                access_key: credentials.accessKeyId,
                secret_key: credentials.secretAccessKey,
                session_token: credentials.sessionToken,
              }, {
                service: "geo",
              }),
            };
            // console.log(newUrl)
            return newUrl
          }
          // console.log(url)
          return { url };
        },
      }),
      getLocationClientConfig: () => ({
        credentials: credentialsProvider,
      }),
      getCredentials: () => credentials,
    };
  }

  // useEffect(() => {
  //   if (map.current) {
  //     console.log('initialize 1')
  //     return; // initialize map only once
  //   } else {
  //     console.log(map.current);

  //     var authHelper = ''
  //     async function initializeMap() {

  //       const identityPoolId = "us-east-2:040418ec-4000-48b9-91b7-488671631f87"
  //       authHelper = await withIdentityPoolId2(identityPoolId);
  //       const key = 'v1.public.eyJqdGkiOiI1NzdkY2U1Ni0wZGUyLTRiZmQtOWNmYS0zZGVkZGEyZmZkY2YifUqV5WiO8r_cY9220JcZ-7ZlFFv3dK9Of7vCxPgOM8QS-8IOMUunDrDNAXPAFJHv1n1sWWUPdnzn6mrHe54Fc5hGcMkrzI4TGBTpGWBNh_8IzfHJv9ZEU4qjwJlgfe6IB2SPPJ5swJylMUVL0WfQ5oMEjbG2WvGQ66dnOsx-bJIYkCTAF9lKJK3Nvci25vdwXIhVEx-TndgXZSegmMblD-001BEa09V0S4gCxEQ9dUc48IkzeF7QUzQRQCxfuTzgAFzQl4fQewmzZMI6HAIxF8LCj2VZ9g2Nwe06ta3MPT6PMh1VMfvIm2y1Yrusmm2uSKVw92zGmYXMRTIjEYBh1-0.NjAyMWJkZWUtMGMyOS00NmRkLThjZTMtODEyOTkzZTUyMTBi'


  //       map.current = new maplibregl.Map({
  //         container: mapContainer.current,
  //         renderWorldCopies: false,
  //         maxPitch: 85,
  //         maxZoom: 19,
  //         center: [lng, lat],
  //         zoom: zoom,
  //         pitch: pitch,
  //         bearing: bearing,
  //         // center: [filterState['viewProps']['lon'], filterState['viewProps']['lat']],
  //         // zoom: filterState['viewProps']['zoom'],
  //         // transformRequest: (url, resourceType) => {
  //         //   if (resourceType === 'Source' && url.startsWith('https://elevation-tiles-prod.s3.amazonaws.com')) {
  //         //     console.log('hello')
  //         //     return {
  //         //       url: url,
  //         //       //  headers: { 'my-custom-header': false},
  //         //       //  credentials: 'include'  // Include cookies for cross-origin requests
  //         //     }
  //         //   }
  //         // },
  //         // style: {
  //         //   version: 8,
  //         //   sources: {
  //         //     osm: {
  //         //       type: 'raster',
  //         //       tiles: ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'],
  //         //       tileSize: 256,
  //         //       attribution: '&copy; OpenStreetMap Contributors',
  //         //       maxzoom: 19
  //         //     },
  //         //     // Use a different source for terrain and hillshade layers, to improve render quality
  //         //     terrainSource: {
  //         //       type: 'raster-dem',
  //         //       encoding: 'terrarium',
  //         //       url: 'https://elevation-tiles-prod.s3.amazonaws.com/terrarium/{z}/{x}/{y}.png',
  //         //       tileSize: 256
  //         //     },
  //         //     hillshadeSource: {
  //         //       type: 'raster-dem',
  //         //       encoding: 'terrarium',
  //         //       url: 'https://elevation-tiles-prod.s3.amazonaws.com/terrarium/{z}/{x}/{y}.png',
  //         //       tileSize: 256
  //         //     }
  //         //   },
  //         //   layers: [
  //         //     {
  //         //       id: 'osm',
  //         //       type: 'raster',
  //         //       source: 'osm'
  //         //     },
  //         //     {
  //         //       id: 'hills',
  //         //       type: 'hillshade',
  //         //       source: 'hillshadeSource',
  //         //       layout: { visibility: 'visible' },
  //         //       paint: { 'hillshade-shadow-color': '#473B24' }
  //         //     }
  //         //   ],
  //         //   terrain: {
  //         //     source: 'terrainSource',
  //         //     exaggeration: 1
  //         //   }
  //         // },
  //         style: "https://maps.geo.us-east-2.amazonaws.com/maps/v0/maps/ExxonDemoMap-dev/style-descriptor",
  //         ...authHelper.getMapAuthenticationOptions(),
  //         // style: "https://maps.geo.us-east-2.amazonaws.com/maps/v0/maps/ExxonDemoMap-dev/style-descriptor?key=" + key,


  //       });

  //       // map.current.on('move', () => {
  //       //   setLng(map.current.getCenter().lng.toFixed(4));
  //       //   setLat(map.current.getCenter().lat.toFixed(4));
  //       //   setZoom(map.current.getZoom().toFixed(2));
  //       //   setPitch(map.current.getPitch().toFixed(2))
  //       //   setBearing(map.current.getBearing().toFixed(2))
  //       //   });
  //       // fetchDevices(); 
  //     }
  //     initializeMap()
  //     console.log('test')

  //   }
  // });

  //   'lat': 32.10275163,
  //   'lon': -103.80685014,
  //   'zoom': 14
  // });


  // const mapContainer = useRef(null);
  const map = useRef(null);

  const [lng] = useState(-103.80685014);
  const [lat] = useState(32.10275163);



  const [zoom] = useState(4);
  // const [zoom] = useState(24);
  // const [API_KEY] = useState('YOUR_MAPTILER_API_KEY_HERE');

  // const identityPoolId = "us-east-2:040418ec-4000-48b9-91b7-488671631f87"


  // const key = 'v1.public.eyJqdGkiOiI1NzdkY2U1Ni0wZGUyLTRiZmQtOWNmYS0zZGVkZGEyZmZkY2YifUqV5WiO8r_cY9220JcZ-7ZlFFv3dK9Of7vCxPgOM8QS-8IOMUunDrDNAXPAFJHv1n1sWWUPdnzn6mrHe54Fc5hGcMkrzI4TGBTpGWBNh_8IzfHJv9ZEU4qjwJlgfe6IB2SPPJ5swJylMUVL0WfQ5oMEjbG2WvGQ66dnOsx-bJIYkCTAF9lKJK3Nvci25vdwXIhVEx-TndgXZSegmMblD-001BEa09V0S4gCxEQ9dUc48IkzeF7QUzQRQCxfuTzgAFzQl4fQewmzZMI6HAIxF8LCj2VZ9g2Nwe06ta3MPT6PMh1VMfvIm2y1Yrusmm2uSKVw92zGmYXMRTIjEYBh1-0.NjAyMWJkZWUtMGMyOS00NmRkLThjZTMtODEyOTkzZTUyMTBi'


  useEffect(() => {
    // declare the data fetching function

    const apiKey = "v1.public.eyJqdGkiOiI1NzdkY2U1Ni0wZGUyLTRiZmQtOWNmYS0zZGVkZGEyZmZkY2YifUqV5WiO8r_cY9220JcZ-7ZlFFv3dK9Of7vCxPgOM8QS-8IOMUunDrDNAXPAFJHv1n1sWWUPdnzn6mrHe54Fc5hGcMkrzI4TGBTpGWBNh_8IzfHJv9ZEU4qjwJlgfe6IB2SPPJ5swJylMUVL0WfQ5oMEjbG2WvGQ66dnOsx-bJIYkCTAF9lKJK3Nvci25vdwXIhVEx-TndgXZSegmMblD-001BEa09V0S4gCxEQ9dUc48IkzeF7QUzQRQCxfuTzgAFzQl4fQewmzZMI6HAIxF8LCj2VZ9g2Nwe06ta3MPT6PMh1VMfvIm2y1Yrusmm2uSKVw92zGmYXMRTIjEYBh1-0.NjAyMWJkZWUtMGMyOS00NmRkLThjZTMtODEyOTkzZTUyMTBi";
    const mapName = "hybrid-dev";
    const region = "us-east-2";

    const fetchAuth = async () => {
      if (map.current) {
        // console.log('current')
        return;
      }
      // console.log('fetching')
      authHelper = await withIdentityPoolId2(identityPoolId);
      const authOptions = await authHelper.getMapAuthenticationOptions()
      // console.log('auth')
      map.current = new maplibregl.Map({
        container: mapContainer.current,
        renderWorldCopies: false,
        style: `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${apiKey}`,
        // ...authOptions,
        maxPitch: 85,
        maxZoom: 19,
        center: [lng, lat],
        zoom: zoom,
        // TerrainControl: false
      });
      // var modes = MapboxDraw.modes
      // modes.draw_polygon = DrawRectangle;
      // const draw = new MapboxDraw({
      //   modes: modes,
      //   displayControlsDefault: false,
      //   // defaultMode: 'draw_rectangle',
      //   controls: {
      //     polygon: true,
      //     trash: true
      //   }
      // });
      map.current.on('mousemove', (e) => {
        // console.log(e.lngLat.wrap())
        setCursorCoords(e.lngLat)
        // document.getElementById('info').innerHTML =
        //     // e.point is the x, y coordinates of the mousemove event relative
        //     // to the top-left corner of the map
        //     `${JSON.stringify(e.point)
        //     }<br />${
        //         // e.lngLat is the longitude, latitude geographical position of the event
        //         JSON.stringify(e.lngLat.wrap())}`;
      });
      // map.current.addControl(draw, 'bottom-right');
      // console.log(mapContainer.current)
      async function updateArea(e) {
        // console.log(e)
        const coordinates = e['features'][0]['geometry']['coordinates'][0]
        const minLon = coordinates[0][0]
        const maxLat = coordinates[0][1]
        const maxLon = coordinates[1][0]
        const minLat = coordinates[2][1]
        // console.log(minLon, maxLat, maxLon, minLat)
        await map.current.flyTo({
          center: [
            minLon,
            minLat
          ],
          essential: true // this animation is considered essential with respect to prefers-reduced-motion
        });


        // ([
        //   [minLon, minLat], // southwestern corner of the bounds
        //   [maxLon, maxLat] // northeastern corner of the bounds
        // ]);
      }
      map.current.on('draw.create', updateArea);


    }
    fetchDevices();




    // call the function
    fetchAuth()
      // make sure to catch any error
      .catch(console.error);
  }, [lng, lat, zoom, map])
  // const [map, setMap] = useState()



  // useEffect(() => {


  //   initializeMap();


  // }, [])




  var componentList = [];


  // useEffect(() => {
  //   fetchDevices()
  // }, [])

  const i18nStrings = {
    searchIconAriaLabel: 'Search',
    searchDismissIconAriaLabel: 'Close search',
    overflowMenuTriggerText: 'More',
    overflowMenuTitleText: 'All',
    overflowMenuBackIconAriaLabel: 'Back',
    overflowMenuDismissIconAriaLabel: 'Close menu',
  };

  const el = document.createElement('div');
  // const width = marker.properties.iconSize[0];
  // const height = marker.properties.iconSize[1];
  el.className = 'marker';
  el.style.backgroundImage = "url('flame-16.png')";
  el.style.width = '100px';
  el.style.height = '100px';
  el.style.backgroundSize = '100%';


  const [checked21, setChecked21] = React.useState(true)
  const [timeZoneVisible, setTimeZoneVisible] = React.useState(false);

  // const [flashbarItems, setFlashbarItems] = React.useState([
  //   {
  //     type: "info",
  //     dismissible: true,
  //     dismissLabel: "Dismiss message",
  //     onDismiss: () => setFlashbarItems([]),
  //     content: (
  //       <>
  //         <strong>Updates as of March 6, 2024</strong>
  //         <ul>
  //           <li>Added a <Icon name="settings" /> icon next to the profile dropdown to change the time zone.</li>
  //           <li>Removed the proof-of-concept satellite plume section.</li>
  //           <li>Added a separate color scale for background methane.</li>
  //           <li>Added buttons above balloon table to filter the table rows by plume markers visible in the map display.</li>
  //           <li>Added functionality to the HAPS table to highlight visible plumes selected by the checkbox in the leftmost column.</li>
  //           <li>Set default visibility for 3D balloon path to false.</li>
  //           <li>Moved the methane plume opacity slider to the HAPS tab.</li>
  //         </ul>
  //         <strong>General tips</strong>
  //         <ul>
  //           <li>Hold down right-click and move the mouse to change the pitch and bearing of the camera angle.</li>
  //           <li>Refresh your browser tab if the page loads and you don't see red dots, blue dots, and a green line.</li>
  //           <li>Optionally enable 3D terrain with the checkbox in the Layers menu.</li>
  //         </ul>

  //       </>
  //     ),
  //     id: "message_1"
  //   }
  // ]);

  const [checked2DBalloon, set2DBalloon] = React.useState(true)
  const [checked3DBalloon, set3DBalloon] = React.useState(false)

  const [checkedSentinel2a, setSentinel2a] = React.useState(false)
  const [checkedSentinel2b, setSentinel2b] = React.useState(false)
  const [checkedSentinel5p, setSentinel5p] = React.useState(false)
  
  const [checked2DBalloon_flight2, set2DBalloon_flight2] = React.useState(false)
  const [checked3DBalloon_flight2, set3DBalloon_flight2] = React.useState(false)
  const [checkedBalloonPlume, setBalloonPlume] = React.useState(true)
  const [checkedGroundSensors, setGroundSensors] = React.useState(true)
  const [checkedWells, setWells] = React.useState(false)
  const [checkedPipes, setPipes] = React.useState(true)
  const [checkedLandfills, setLandfills] = React.useState(false)
  const [checkedPlumeMarkers, setPlumeMarkersChecked] = React.useState(true)
  const [checkedTerrain, setTerrain] = React.useState(false)
  const [splitPanelPosition, setSplitPanelPosition] = React.useState('side')
  const [splitPanelOpen, setSplitPanelOpen] = React.useState(true)

  function setSentinel2aVis(e) {
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();
    // console.log(e)
    // console.log(map.current.getStyle().layers)




    // Toggle layer visibility by changing the layout object's visibility property.
    if (e == false) {
      map.current.setLayoutProperty('sentinel2aLayer', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setSentinel2a(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'sentinel2aLayer',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setSentinel2a(true)
    }
  };

  function setSentinel2bVis(e) {
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();
    // console.log(e)
    // console.log(map.current.getStyle().layers)




    // Toggle layer visibility by changing the layout object's visibility property.
    if (e == false) {
      map.current.setLayoutProperty('sentinel2bLayer', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setSentinel2b(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'sentinel2bLayer',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setSentinel2b(true)
    }
  };

  function setSentinel5pVis(e) {
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();
    // console.log(e)
    // console.log(map.current.getStyle().layers)




    // Toggle layer visibility by changing the layout object's visibility property.
    if (e == false) {
      map.current.setLayoutProperty('sentinel5pLayer', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setSentinel5p(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'sentinel5pLayer',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setSentinel5p(true)
    }
  };

  function set3DBalloonVis(e) {
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();
    // console.log(e)
    // console.log(map.current.getStyle().layers)




    // Toggle layer visibility by changing the layout object's visibility property.
    if (e == false) {
      map.current.setLayoutProperty('flightLayer', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      set3DBalloon(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'flightLayer',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      set3DBalloon(true)
    }
  };

  function set2DBalloonVis(e) {
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();
    // console.log(e)
    // console.log(map.current.getStyle().layers)




    // Toggle layer visibility by changing the layout object's visibility property.
    if (e == false) {
      map.current.setLayoutProperty('flightLineLayer', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      set2DBalloon(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'flightLineLayer',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      set2DBalloon(true)
    }
  };

  function set3DBalloonVis(e) {
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();
    // console.log(e)
    // console.log(map.current.getStyle().layers)




    // Toggle layer visibility by changing the layout object's visibility property.
    if (e == false) {
      map.current.setLayoutProperty('flightLayer', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      set3DBalloon(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'flightLayer',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      set3DBalloon(true)
    }
  };

  function set2DBalloonVis(e) {
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();
    // console.log(e)
    // console.log(map.current.getStyle().layers)




    // Toggle layer visibility by changing the layout object's visibility property.
    if (e == false) {
      map.current.setLayoutProperty('flightLineLayer', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      set2DBalloon(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'flightLineLayer',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      set2DBalloon(true)
    }
  };

  function set3DBalloonVis(e) {
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();
    // console.log(e)
    // console.log(map.current.getStyle().layers)




    // Toggle layer visibility by changing the layout object's visibility property.
    if (e == false) {
      map.current.setLayoutProperty('flightLayer', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      set3DBalloon(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'flightLayer',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      set3DBalloon(true)
    }
  };

  function set2DBalloonVis_flight2(e) {
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();
    // console.log(e)
    // console.log(map.current.getStyle().layers)




    // Toggle layer visibility by changing the layout object's visibility property.
    if (e == false) {
      map.current.setLayoutProperty('flightLine2Layer', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      set2DBalloon_flight2(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'flightLine2Layer',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      set2DBalloon_flight2(true)
    }
  };

  function set3DBalloonVis_flight2(e) {
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();
    // console.log(e)
    // console.log(map.current.getStyle().layers)




    // Toggle layer visibility by changing the layout object's visibility property.
    if (e == false) {
      map.current.setLayoutProperty('flight2Layer', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      set3DBalloon_flight2(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'flight2Layer',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      set3DBalloon_flight2(true)
    }
  };

  function setBalloonPlumeVis(e) {
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();
    // console.log(e)
    // console.log(map.current.getStyle().layers)




    // Toggle layer visibility by changing the layout object's visibility property.
    if (e == false) {
      map.current.setLayoutProperty('balloon1', 'visibility', 'none');
      map.current.setLayoutProperty('balloon2', 'visibility', 'none');
      map.current.setLayoutProperty('balloon3', 'visibility', 'none');
      map.current.setLayoutProperty('balloon4', 'visibility', 'none');
      map.current.setLayoutProperty('balloon5', 'visibility', 'none');
      map.current.setLayoutProperty('balloon6', 'visibility', 'none');
      map.current.setLayoutProperty('balloon7', 'visibility', 'none');
      map.current.setLayoutProperty('balloon8', 'visibility', 'none');
      map.current.setLayoutProperty('balloon9', 'visibility', 'none');
      map.current.setLayoutProperty('balloon10', 'visibility', 'none');
      map.current.setLayoutProperty('balloon11', 'visibility', 'none');
      map.current.setLayoutProperty('balloon12', 'visibility', 'none');
      map.current.setLayoutProperty('balloon13', 'visibility', 'none');
      map.current.setLayoutProperty('balloon14', 'visibility', 'none');
      map.current.setLayoutProperty('balloon15', 'visibility', 'none');
      map.current.setLayoutProperty('balloon16', 'visibility', 'none');
      map.current.setLayoutProperty('balloon17', 'visibility', 'none');
      map.current.setLayoutProperty('balloon18', 'visibility', 'none');
      map.current.setLayoutProperty('balloon19', 'visibility', 'none');
      map.current.setLayoutProperty('balloon20', 'visibility', 'none');

      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setBalloonPlume(false)
      setChecked1(false)
      setChecked2(false)
      setChecked3(false)
      setChecked4(false)
      setChecked5(false)
      setChecked6(false)
      setChecked7(false)
      setChecked8(false)
      setChecked9(false)
      setChecked10(false)
      setChecked11(false)
      setChecked12(false)
      setChecked13(false)
      setChecked14(false)
      setChecked15(false)
      setChecked16(false)
      setChecked17(false)
      setChecked18(false)
      setChecked19(false)
      setChecked20(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon1',
        'visibility',
        'visible'
      );
      map.current.setLayoutProperty(
        'balloon2',
        'visibility',
        'visible'
      );
      map.current.setLayoutProperty(
        'balloon3',
        'visibility',
        'visible'
      );
      map.current.setLayoutProperty(
        'balloon4',
        'visibility',
        'visible'
      );
      map.current.setLayoutProperty(
        'balloon5',
        'visibility',
        'visible'
      );
      map.current.setLayoutProperty(
        'balloon6',
        'visibility',
        'visible'
      );
      map.current.setLayoutProperty('balloon7', 'visibility', 'visible');
      map.current.setLayoutProperty('balloon8', 'visibility', 'visible');
      map.current.setLayoutProperty('balloon9', 'visibility', 'visible');
      map.current.setLayoutProperty('balloon10', 'visibility', 'visible');
      map.current.setLayoutProperty('balloon11', 'visibility', 'visible');
      map.current.setLayoutProperty('balloon12', 'visibility', 'visible');
      map.current.setLayoutProperty('balloon13', 'visibility', 'visible');
      map.current.setLayoutProperty('balloon14', 'visibility', 'visible');
      map.current.setLayoutProperty('balloon15', 'visibility', 'visible');
      map.current.setLayoutProperty('balloon16', 'visibility', 'visible');
      map.current.setLayoutProperty('balloon17', 'visibility', 'visible');
      map.current.setLayoutProperty('balloon18', 'visibility', 'visible');
      map.current.setLayoutProperty('balloon19', 'visibility', 'visible');
      map.current.setLayoutProperty('balloon20', 'visibility', 'visible');


      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setBalloonPlume(true)
      setChecked1(true)
      setChecked2(true)
      setChecked3(true)
      setChecked4(true)
      setChecked5(true)
      setChecked6(true)
      setChecked7(true)
      setChecked8(true)
      setChecked9(true)
      setChecked10(true)
      setChecked11(true)
      setChecked12(true)
      setChecked13(true)
      setChecked14(true)
      setChecked15(true)
      setChecked16(true)
      setChecked17(true)
      setChecked18(true)
      setChecked19(true)
      setChecked20(true)
    }
  };

  function setPlumeMarkerVis(e) {
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();
    // console.log(e)
    // console.log(map.current.getStyle().layers)




    // Toggle layer visibility by changing the layout object's visibility property.
    if (e == false) {
      map.current.setLayoutProperty(
        'plume-cluster-count',
        'visibility',
        'none'
      );
      map.current.setLayoutProperty(
        'unclustered-plume',
        'visibility',
        'none'
      );
      map.current.setLayoutProperty(
        'plumeClustersLayer',
        'visibility',
        'none'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setPlumeMarkersChecked(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'plume-cluster-count',
        'visibility',
        'visible'
      );
      map.current.setLayoutProperty(
        'unclustered-plume',
        'visibility',
        'visible'
      );
      map.current.setLayoutProperty(
        'plumeClustersLayer',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setPlumeMarkersChecked(true)
    }
  };

  function setTexasPipesVis(e) {
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();
    // console.log(e)
    // console.log(map.current.getStyle().layers)




    // Toggle layer visibility by changing the layout object's visibility property.
    if (e == false) {
      map.current.setLayoutProperty(
        'texasPipesLayer',
        'visibility',
        'none'
      );

      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setPipes(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'texasPipesLayer',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setPipes(true)
    }
  };

  function setLandfillsVis(e) {
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();
    // console.log(e)
    // console.log(map.current.getStyle().layers)




    // Toggle layer visibility by changing the layout object's visibility property.
    if (e == false) {
      map.current.setLayoutProperty(
        'landfillsClustersLayer',
        'visibility',
        'none'
      );
      map.current.setLayoutProperty(
        'landfills-cluster-count',
        'visibility',
        'none'
      );
      map.current.setLayoutProperty(
        'unclustered-landfills',
        'visibility',
        'none'
      );

      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setLandfills(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'landfillsClustersLayer',
        'visibility',
        'visible'
      );
      map.current.setLayoutProperty(
        'landfills-cluster-count',
        'visibility',
        'visible'
      );
      map.current.setLayoutProperty(
        'unclustered-landfills',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setLandfills(true)
    }
  };

  function setWellsVis(e) {
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();
    // console.log(e)
    // console.log(map.current.getStyle().layers)




    // Toggle layer visibility by changing the layout object's visibility property.
    if (e == false) {
      map.current.setLayoutProperty(
        'oil-gas-well-cluster-count',
        'visibility',
        'none'
      );
      map.current.setLayoutProperty(
        'unclustered-oil-gas-well',
        'visibility',
        'none'
      );
      map.current.setLayoutProperty(
        'oilGasWellClustersLayer',
        'visibility',
        'none'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setWells(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'oil-gas-well-cluster-count',
        'visibility',
        'visible'
      );
      map.current.setLayoutProperty(
        'unclustered-oil-gas-well',
        'visibility',
        'visible'
      );
      map.current.setLayoutProperty(
        'oilGasWellClustersLayer',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setWells(true)
    }
  };

  function setGroundSensorsVis(e) {
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();
    // console.log(e)
    // console.log(map.current.getStyle().layers)




    // Toggle layer visibility by changing the layout object's visibility property.
    if (e == false) {
      map.current.setLayoutProperty(
        'cluster-count',
        'visibility',
        'none'
      );
      map.current.setLayoutProperty(
        'unclustered-soofie',
        'visibility',
        'none'
      );
      map.current.setLayoutProperty(
        'soofieClustersLayer',
        'visibility',
        'none'
      );
      map.current.setLayoutProperty(
        'soofies-all-cluster-count',
        'visibility',
        'none'
      );
      map.current.setLayoutProperty(
        'unclustered-soofies-all',
        'visibility',
        'none'
      );
      map.current.setLayoutProperty(
        'soofiesAllClustersLayer',
        'visibility',
        'none'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setGroundSensors(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'cluster-count',
        'visibility',
        'visible'
      );
      map.current.setLayoutProperty(
        'unclustered-soofie',
        'visibility',
        'visible'
      );
      map.current.setLayoutProperty(
        'soofieClustersLayer',
        'visibility',
        'visible'
      );
      map.current.setLayoutProperty(
        'soofies-all-cluster-count',
        'visibility',
        'visible'
      );
      map.current.setLayoutProperty(
        'unclustered-soofies-all',
        'visibility',
        'visible'
      );
      map.current.setLayoutProperty(
        'soofiesAllClustersLayer',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setGroundSensors(true)
    }
  };

  function setTerrainVis(e) {
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();
    // console.log(e)
    // console.log(map.current.getStyle().layers)




    // Toggle layer visibility by changing the layout object's visibility property.
    if (e == false) {
      map.current.setTerrain();
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setTerrain(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setTerrain({
        source: 'terrain',
        exaggeration: 5
      })
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setTerrain(true)
    }
  };

  function setBallonVis1(e) {
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();
    // console.log(e)
    // console.log(map.current.getStyle().layers)




    // Toggle layer visibility by changing the layout object's visibility property.
    if (e == false) {
      map.current.setLayoutProperty('balloon1', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked1(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon1',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked1(true)
    }
  };

  function setBallonVis2(e) {
    if (e == false) {
      map.current.setLayoutProperty('balloon2', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked2(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon2',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked2(true)
    }
  };

  function setBallonVis3(e) {
    if (e == false) {
      map.current.setLayoutProperty('balloon3', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked3(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon3',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked3(true)
    }
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();

    // const visibility = map.current.getLayoutProperty(
    //   'balloon3',
    //   'visibility'
    // );

    // Toggle layer visibility by changing the layout object's visibility property.
    // if (visibility === 'visible') {
    //   map.current.setLayoutProperty('balloon3', 'visibility', 'none');
    //   setChecked3(false)
    //   // this.className = '';
    // } else {
    //   // this.className = 'active';
    //   map.current.setLayoutProperty(
    //     'balloon3',
    //     'visibility',
    //     'visible'
    //   );
    //   setChecked3(true)
    // }
  };

  function setBallonVis4(e) {
    if (e == false) {
      map.current.setLayoutProperty('balloon4', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked4(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon4',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked4(true)
    }
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();

    // const visibility = map.current.getLayoutProperty(
    //   'balloon3',
    //   'visibility'
    // );

    // Toggle layer visibility by changing the layout object's visibility property.
    // if (visibility === 'visible') {
    //   map.current.setLayoutProperty('balloon3', 'visibility', 'none');
    //   setChecked3(false)
    //   // this.className = '';
    // } else {
    //   // this.className = 'active';
    //   map.current.setLayoutProperty(
    //     'balloon3',
    //     'visibility',
    //     'visible'
    //   );
    //   setChecked3(true)
    // }
  };

  function setBallonVis5(e) {
    if (e == false) {
      map.current.setLayoutProperty('balloon5', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked5(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon5',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked5(true)
    }
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();

    // const visibility = map.current.getLayoutProperty(
    //   'balloon3',
    //   'visibility'
    // );

    // Toggle layer visibility by changing the layout object's visibility property.
    // if (visibility === 'visible') {
    //   map.current.setLayoutProperty('balloon3', 'visibility', 'none');
    //   setChecked3(false)
    //   // this.className = '';
    // } else {
    //   // this.className = 'active';
    //   map.current.setLayoutProperty(
    //     'balloon3',
    //     'visibility',
    //     'visible'
    //   );
    //   setChecked3(true)
    // }
  };

  function setBallonVis6(e) {
    if (e == false) {
      map.current.setLayoutProperty('balloon6', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked6(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon6',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked6(true)
    }
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();

    // const visibility = map.current.getLayoutProperty(
    //   'balloon3',
    //   'visibility'
    // );

    // Toggle layer visibility by changing the layout object's visibility property.
    // if (visibility === 'visible') {
    //   map.current.setLayoutProperty('balloon3', 'visibility', 'none');
    //   setChecked3(false)
    //   // this.className = '';
    // } else {
    //   // this.className = 'active';
    //   map.current.setLayoutProperty(
    //     'balloon3',
    //     'visibility',
    //     'visible'
    //   );
    //   setChecked3(true)
    // }
  };

  function setBallonVis7(e) {
    if (e == false) {
      map.current.setLayoutProperty('balloon7', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked7(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon7',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked7(true)
    }
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();

    // const visibility = map.current.getLayoutProperty(
    //   'balloon3',
    //   'visibility'
    // );

    // Toggle layer visibility by changing the layout object's visibility property.
    // if (visibility === 'visible') {
    //   map.current.setLayoutProperty('balloon3', 'visibility', 'none');
    //   setChecked3(false)
    //   // this.className = '';
    // } else {
    //   // this.className = 'active';
    //   map.current.setLayoutProperty(
    //     'balloon3',
    //     'visibility',
    //     'visible'
    //   );
    //   setChecked3(true)
    // }
  };

  function setBallonVis8(e) {
    if (e == false) {
      map.current.setLayoutProperty('balloon8', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked8(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon8',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked8(true)
    }
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();

    // const visibility = map.current.getLayoutProperty(
    //   'balloon3',
    //   'visibility'
    // );

    // Toggle layer visibility by changing the layout object's visibility property.
    // if (visibility === 'visible') {
    //   map.current.setLayoutProperty('balloon3', 'visibility', 'none');
    //   setChecked3(false)
    //   // this.className = '';
    // } else {
    //   // this.className = 'active';
    //   map.current.setLayoutProperty(
    //     'balloon3',
    //     'visibility',
    //     'visible'
    //   );
    //   setChecked3(true)
    // }
  };

  function setBallonVis9(e) {
    if (e == false) {
      map.current.setLayoutProperty('balloon9', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked9(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon9',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked9(true)
    }
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();

    // const visibility = map.current.getLayoutProperty(
    //   'balloon3',
    //   'visibility'
    // );

    // Toggle layer visibility by changing the layout object's visibility property.
    // if (visibility === 'visible') {
    //   map.current.setLayoutProperty('balloon3', 'visibility', 'none');
    //   setChecked3(false)
    //   // this.className = '';
    // } else {
    //   // this.className = 'active';
    //   map.current.setLayoutProperty(
    //     'balloon3',
    //     'visibility',
    //     'visible'
    //   );
    //   setChecked3(true)
    // }
  };

  function setBallonVis10(e) {
    console.log(e)
    console.log(checked10)
    if (e == false) {
      console.log('triggered')
      map.current.setLayoutProperty('balloon10', 'visibility', 'none');
      console.log('triggered2')
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked10(false)
      console.log(checked10)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon10',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked10(true)
    }
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();

    // const visibility = map.current.getLayoutProperty(
    //   'balloon3',
    //   'visibility'
    // );

    // Toggle layer visibility by changing the layout object's visibility property.
    // if (visibility === 'visible') {
    //   map.current.setLayoutProperty('balloon3', 'visibility', 'none');
    //   setChecked3(false)
    //   // this.className = '';
    // } else {
    //   // this.className = 'active';
    //   map.current.setLayoutProperty(
    //     'balloon3',
    //     'visibility',
    //     'visible'
    //   );
    //   setChecked3(true)
    // }
  };

  function setBallonVis11(e) {
    if (e == false) {
      map.current.setLayoutProperty('balloon11', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked11(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon11',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked11(true)
    }
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();

    // const visibility = map.current.getLayoutProperty(
    //   'balloon3',
    //   'visibility'
    // );

    // Toggle layer visibility by changing the layout object's visibility property.
    // if (visibility === 'visible') {
    //   map.current.setLayoutProperty('balloon3', 'visibility', 'none');
    //   setChecked3(false)
    //   // this.className = '';
    // } else {
    //   // this.className = 'active';
    //   map.current.setLayoutProperty(
    //     'balloon3',
    //     'visibility',
    //     'visible'
    //   );
    //   setChecked3(true)
    // }
  };

  function setBallonVis12(e) {
    if (e == false) {
      map.current.setLayoutProperty('balloon12', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked12(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon12',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked12(true)
    }
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();

    // const visibility = map.current.getLayoutProperty(
    //   'balloon3',
    //   'visibility'
    // );

    // Toggle layer visibility by changing the layout object's visibility property.
    // if (visibility === 'visible') {
    //   map.current.setLayoutProperty('balloon3', 'visibility', 'none');
    //   setChecked3(false)
    //   // this.className = '';
    // } else {
    //   // this.className = 'active';
    //   map.current.setLayoutProperty(
    //     'balloon3',
    //     'visibility',
    //     'visible'
    //   );
    //   setChecked3(true)
    // }
  };

  function setBallonVis13(e) {
    if (e == false) {
      map.current.setLayoutProperty('balloon13', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked13(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon13',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked13(true)
    }
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();

    // const visibility = map.current.getLayoutProperty(
    //   'balloon3',
    //   'visibility'
    // );

    // Toggle layer visibility by changing the layout object's visibility property.
    // if (visibility === 'visible') {
    //   map.current.setLayoutProperty('balloon3', 'visibility', 'none');
    //   setChecked3(false)
    //   // this.className = '';
    // } else {
    //   // this.className = 'active';
    //   map.current.setLayoutProperty(
    //     'balloon3',
    //     'visibility',
    //     'visible'
    //   );
    //   setChecked3(true)
    // }
  };

  function setBallonVis14(e) {
    if (e == false) {
      map.current.setLayoutProperty('balloon14', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked14(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon14',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked14(true)
    }
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();

    // const visibility = map.current.getLayoutProperty(
    //   'balloon3',
    //   'visibility'
    // );

    // Toggle layer visibility by changing the layout object's visibility property.
    // if (visibility === 'visible') {
    //   map.current.setLayoutProperty('balloon3', 'visibility', 'none');
    //   setChecked3(false)
    //   // this.className = '';
    // } else {
    //   // this.className = 'active';
    //   map.current.setLayoutProperty(
    //     'balloon3',
    //     'visibility',
    //     'visible'
    //   );
    //   setChecked3(true)
    // }
  };

  function setBallonVis15(e) {
    if (e == false) {
      map.current.setLayoutProperty('balloon15', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked15(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon15',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked15(true)
    }
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();

    // const visibility = map.current.getLayoutProperty(
    //   'balloon3',
    //   'visibility'
    // );

    // Toggle layer visibility by changing the layout object's visibility property.
    // if (visibility === 'visible') {
    //   map.current.setLayoutProperty('balloon3', 'visibility', 'none');
    //   setChecked3(false)
    //   // this.className = '';
    // } else {
    //   // this.className = 'active';
    //   map.current.setLayoutProperty(
    //     'balloon3',
    //     'visibility',
    //     'visible'
    //   );
    //   setChecked3(true)
    // }
  };

  function setBallonVis16(e) {
    if (e == false) {
      map.current.setLayoutProperty('balloon16', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked16(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon16',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked16(true)
    }
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();

    // const visibility = map.current.getLayoutProperty(
    //   'balloon3',
    //   'visibility'
    // );

    // Toggle layer visibility by changing the layout object's visibility property.
    // if (visibility === 'visible') {
    //   map.current.setLayoutProperty('balloon3', 'visibility', 'none');
    //   setChecked3(false)
    //   // this.className = '';
    // } else {
    //   // this.className = 'active';
    //   map.current.setLayoutProperty(
    //     'balloon3',
    //     'visibility',
    //     'visible'
    //   );
    //   setChecked3(true)
    // }
  };

  function setBallonVis17(e) {
    if (e == false) {
      map.current.setLayoutProperty('balloon17', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked17(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon17',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked17(true)
    }
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();

    // const visibility = map.current.getLayoutProperty(
    //   'balloon3',
    //   'visibility'
    // );

    // Toggle layer visibility by changing the layout object's visibility property.
    // if (visibility === 'visible') {
    //   map.current.setLayoutProperty('balloon3', 'visibility', 'none');
    //   setChecked3(false)
    //   // this.className = '';
    // } else {
    //   // this.className = 'active';
    //   map.current.setLayoutProperty(
    //     'balloon3',
    //     'visibility',
    //     'visible'
    //   );
    //   setChecked3(true)
    // }
  };

  function setBallonVis18(e) {
    if (e == false) {
      map.current.setLayoutProperty('balloon18', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked18(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon18',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked18(true)
    }
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();

    // const visibility = map.current.getLayoutProperty(
    //   'balloon3',
    //   'visibility'
    // );

    // Toggle layer visibility by changing the layout object's visibility property.
    // if (visibility === 'visible') {
    //   map.current.setLayoutProperty('balloon3', 'visibility', 'none');
    //   setChecked3(false)
    //   // this.className = '';
    // } else {
    //   // this.className = 'active';
    //   map.current.setLayoutProperty(
    //     'balloon3',
    //     'visibility',
    //     'visible'
    //   );
    //   setChecked3(true)
    // }
  };

  function setBallonVis19(e) {
    if (e == false) {
      map.current.setLayoutProperty('balloon19', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked19(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon19',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked19(true)
    }
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();

    // const visibility = map.current.getLayoutProperty(
    //   'balloon3',
    //   'visibility'
    // );

    // Toggle layer visibility by changing the layout object's visibility property.
    // if (visibility === 'visible') {
    //   map.current.setLayoutProperty('balloon3', 'visibility', 'none');
    //   setChecked3(false)
    //   // this.className = '';
    // } else {
    //   // this.className = 'active';
    //   map.current.setLayoutProperty(
    //     'balloon3',
    //     'visibility',
    //     'visible'
    //   );
    //   setChecked3(true)
    // }
  };

  function setBallonVis20(e) {
    if (e == false) {
      map.current.setLayoutProperty('balloon20', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked20(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon20',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked20(true)
    }
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();

    // const visibility = map.current.getLayoutProperty(
    //   'balloon3',
    //   'visibility'
    // );

    // Toggle layer visibility by changing the layout object's visibility property.
    // if (visibility === 'visible') {
    //   map.current.setLayoutProperty('balloon3', 'visibility', 'none');
    //   setChecked3(false)
    //   // this.className = '';
    // } else {
    //   // this.className = 'active';
    //   map.current.setLayoutProperty(
    //     'balloon3',
    //     'visibility',
    //     'visible'
    //   );
    //   setChecked3(true)
    // }
  };

  function setBallonVis21(e) {
    if (e == false) {
      map.current.setLayoutProperty('balloon21', 'visibility', 'none');
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      // map.current.triggerRepaint();
      setChecked21(false)
      // this.className = '';
    } else {
      // this.className = 'active';
      map.current.setLayoutProperty(
        'balloon21',
        'visibility',
        'visible'
      );
      // const visibility = map.current.getLayoutProperty(
      //   'balloon1',
      //   'visibility'
      // );
      // console.log(visibility)
      setChecked21(true)
    }
    // const clickedLayer = this.textContent;
    // e.preventDefault();
    // e.stopPropagation();

    // const visibility = map.current.getLayoutProperty(
    //   'balloon3',
    //   'visibility'
    // );

    // Toggle layer visibility by changing the layout object's visibility property.
    // if (visibility === 'visible') {
    //   map.current.setLayoutProperty('balloon3', 'visibility', 'none');
    //   setChecked3(false)
    //   // this.className = '';
    // } else {
    //   // this.className = 'active';
    //   map.current.setLayoutProperty(
    //     'balloon3',
    //     'visibility',
    //     'visible'
    //   );
    //   setChecked3(true)
    // }
  };







  function SoofieMeasurementsTable() {


    const columnDefinitions = [
      {
        id: "link",
        header: "Name",
        cell: e => e.name,
        // width: 100,
        sortingField: "name"
      },
      {
        id: "value",
        header: "Value",
        cell: e => e.value,
        sortingField: "value",
        // width: 100,
        // isRowHeader: true
      },
      {
        id: "time",
        header: "Time",
        cell: e => (new Date(e.time)).toLocaleString("en-US", {
          timeZone: timeZoneValue.value,
          timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
          minute: '2-digit'
        }),
        sortingField: "time",
        // width: 100,
      },

      {
        id: "name",
        header: "Name",
        cell: e => e.name,
        // width: 100,
        sortingField: "name"
      },
      // {
      //   id: "unit",
      //   header: "Unit",
      //   cell: e => e.unit,
      //   // width: 100,
      //   sortingField: "unit"
      // },
      // {
      //   id: "value",
      //   header: "Value",
      //   // width: 110,
      //   cell: e => e.value,
      //   sortingField: "value"
      // },
      // {
      //   id: "sensor",
      //   header: "Sensor",
      //   cell: e => e.sensor,
      //   sortingField: "sensor"
      // },
    ];

    const paginationLabels = {
      nextPageLabel: 'Next page',
      pageLabel: pageNumber => `Go to page ${pageNumber}`,
      previousPageLabel: 'Previous page',
    };

    const pageSizePreference = {
      title: 'Select page size',
      options: [
        // { value: 10, label: '10 resources' },
        { value: 20, label: '20 resources' },
      ],
    };

    const visibleContentPreference = {
      title: 'Select visible content',
      options: [
        {
          label: 'Main properties',
          options: columnDefinitions.map(({ id, header }) => ({ id, label: header, editable: id !== 'id' })),
        },
      ],
    };

    const collectionPreferencesProps = {
      pageSizePreference,
      visibleContentPreference,
      cancelLabel: 'Cancel',
      confirmLabel: 'Confirm',
      title: 'Preferences',
    };

    function EmptyState({ title, subtitle, action }) {
      return (
        <Box textAlign="center" color="inherit">
          <Box variant="strong" textAlign="center" color="inherit">
            {title}
          </Box>
          <Box variant="p" padding={{ bottom: 's' }} color="inherit">
            {subtitle}
          </Box>
          {action}
        </Box>
      );
    }

    const TableNoMatchState = () => (
      <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
          <div>
            <b>No matches</b>
            <Box variant="p" color="inherit">
              We can't find a match.
            </Box>
          </div>
          <Button onClick={() => {
            actions.setPropertyFiltering({ tokens: [], operation: 'and' });
          }}>Clear filter</Button>
        </SpaceBetween>
      </Box>
    );

    const TableEmptyState = () => (
      <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
          <div>
            {/* <b>No matches</b> */}
            <Box variant="p" color="inherit">
              No matches
            </Box>
          </div>
          {/* <Button>Create {resourceName.toLowerCase()}</Button> */}
        </SpaceBetween>
      </Box>
    );

    const filteringProperties = [
      // {
      //   propertyLabel: 'Site',
      //   key: 'site',
      //   groupValuesLabel: 'Sites',
      //   operators: [':', '!:', '=', '!='],
      // },
      {
        propertyLabel: 'Name',
        key: 'name',
        groupValuesLabel: 'Names',
        operators: [':', '!:', '=', '!='],
      },
      {
        propertyLabel: 'Value',
        key: 'value',
        groupValuesLabel: 'Values',
        operators: ['<=', '>=', '=', '!='],
      },
      // {
      //   propertyLabel: 'Value',
      //   key: 'value',
      //   groupValuesLabel: 'Values',
      //   operators: ['<', '>', '=', '!='],
      // },
      // {
      //   propertyLabel: 'Date',
      //   key: 'date',
      //   groupValuesLabel: 'Dates',
      //   operators: [':', '!:', '=', '!='],
      // },
      // {
      //   propertyLabel: 'Type',
      //   key: 'type',
      //   groupValuesLabel: 'Types',
      //   operators: [':', '!:', '=', '!='],
      // },
    ];

    // console.log(FILTERING_PROPERTIES)

    // console.log(filterState['fluxTable'])



    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(
      filterState['measurementTableData'],
      {
        propertyFiltering: {
          filteringProperties,
          empty: <TableEmptyState />,
          noMatch: (
            <TableNoMatchState
              onClearFilter={() => {
                actions.setPropertyFiltering({ tokens: [], operation: 'and' });
              }}
            />
          ),
        },
        pagination: { pageSize: preferencesMT.pageSize },
        // sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
        sorting: {},
        selection: {},
      }
    );
    const { selectedItems } = collectionProps;
    return (
      <Table
        {...collectionProps}
        variant='borderless'
        resizableColumns={true}
        wrapLines
        selectionType="single"
        selectedItems={selectedMeasurement}
        onSelectionChange={(e) => {
          map.current.flyTo({
            center: [
              e.detail.selectedItems[0].lon,
              e.detail.selectedItems[0].lat
            ],
            zoom: 17
            // essential: true // this animation is considered essential with respect to prefers-reduced-motion
          })
          setSelectedMeasurement(e.detail.selectedItems)

        }}
        // header={
        //   <Header
        //     counter={selectedItems.length}
        //   >
        //     Images
        //   </Header>
        // }
        columnDefinitions={columnDefinitions}
        visibleColumns={preferencesMT.visibleContent}
        items={items}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            i18nStrings={{
              filteringAriaLabel: 'Find data',
              filteringPlaceholder: 'Find data',
            }}
            // countText={getTextFilterCounterText(filteredItemsCount)}
            expandToViewport={true}
          />
        }
        preferences={
          <CollectionPreferences
            {...collectionPreferencesProps}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferencesMT(detail)}
          />
        }
      />
    )
  }

  function MsiCatalogCollectionHooksTable() {

    // console.log(filterCatalogTable)

    const columnDefinitions = [
      {
        id: "id",
        header: "ID",
        cell: e => e.id,
        // width: 100,
        sortingField: "id"
      },
      {
        id: "name",
        header: "Name",
        cell: e => <Link onFollow={() => {
          map.current.flyTo({
            center: [
              ((e.bbox[0] + e.bbox[2]) / 2),
              ((e.bbox[1] + e.bbox[3]) / 2)
            ],
            zoom: 12
            // essential: true // this animation is considered essential with respect to prefers-reduced-motion
          })
        }}>{e.id}</Link>,
        // width: 100,
        sortingField: "id"
      },

      {
        id: "date",
        header: "Date",
        cell: e => e.properties.datetime,
        // sortingField: "name",
        // width: 100,
        // isRowHeader: true
      },
      {
        id: "display",
        header: "Plume",
        // cell: e => <Link onFollow={() => { catalogDownload(e.assets.NRT_NDMI_COG.href) }}><Icon name='download'/>
        cell: e => <SpaceBetween direction='horizontal' size='m'><Link onFollow={() => { catalogDownload(e.assets.Plume_Layer.href) }}><Icon name='download'/>
                {/* cell: e => <Link onFollow={() => { catalogDownload(e.assets.NRT_NDMI_COG.href) }}>{e.assets.NRT_NDMI_COG.title} */}

        </Link><Link onFollow={() => { catalogVisualDisplay(e.assets.Plume_Layer.href,e.bbox) }}><Icon name='search'/></Link></SpaceBetween>,
        // sortingField: "background",
        // width: 100,
      },
      {
        id: "lat",
        header: "Lat",
        cell: e => ((e.bbox[1] + e.bbox[3]) / 2).toFixed(5),
        // sortingField: "lat",
        // width: 100,
      },
      {
        id: "lon",
        header: "Lon",
        cell: e => ((e.bbox[0] + e.bbox[2]) / 2).toFixed(5),
        // sortingField: "lon",
        // width: 100,
      },
      // {
      //   id: "mission",
      //   header: "Mission",
      //   cell: e => e.mission,
      //   sortingField: "mission",
      //   // width: 100,
      // },
      // {
      //   id: "cog",
      //   header: "COG",
      //   cell: e => <Link onFollow={() => { catalogDownload(e.assets.NRT_NDMI_COG.href) }}><Icon name='download'/>
      //           {/* cell: e => <Link onFollow={() => { catalogDownload(e.assets.NRT_NDMI_COG.href) }}>{e.assets.NRT_NDMI_COG.title} */}

      //   </Link>,
      //   // sortingField: "background",
      //   // width: 100,
      // },
      // {
      //   id: "h5",
      //   header: "H5",
      //   cell: e => <Link onFollow={() => { catalogDownload(e.assets.h5_data.href) }}>{e.assets.h5_data.title}
      //   </Link>,
      //   // sortingField: "mass",
      //   // width: 100,
      // },
      // {
      //   id: "rate",
      //   header: "Methane Emission Rate (kg/h)",
      //   cell: e => e.rate,
      //   sortingField: "rate",
      //   // width: 100,
      // },
      // {
      //   id: "download",
      //   header: "Download",
      //   cell: e => e.download,
      //   // width: 100,
      //   // sortingField: "name"
      // },
      // {
      //   id: "u10",
      //   header: "U 10 Direction (deg)",
      //   cell: e => e.u10,
      //   sortingField: "u10",
      //   // width: 100,
      // },
      // {
      //   id: "u10speed",
      //   header: "U 10 Speed",
      //   cell: e => e.u10speed,
      //   sortingField: "u10speed",
      //   // width: 100,
      // },
      // {
      //   id: "date",
      //   header: "Date",
      //   cell: e => e.date,
      //   sortingField: "date",
      //   // width: 100,
      // },

      // {
      //   id: "layerName",
      //   header: "Layer name",
      //   cell: e => e.layerName,
      //   sortingField: "layerName",
      //   // width: 100,
      // },
      // {
      //   id: "type",
      //   header: "Type",
      //   cell: e => e.type,
      //   // width: 100,
      //   sortingField: "type"
      // },
      // {
      //   id: "unit",
      //   header: "Unit",
      //   cell: e => e.unit,
      //   // width: 100,
      //   sortingField: "unit"
      // },
      // {
      //   id: "value",
      //   header: "Value",
      //   // width: 110,
      //   cell: e => e.value,
      //   sortingField: "value"
      // },
      // {
      //   id: "sensor",
      //   header: "Sensor",
      //   cell: e => e.sensor,
      //   sortingField: "sensor"
      // },
    ];

    const paginationLabels = {
      nextPageLabel: 'Next page',
      pageLabel: pageNumber => `Go to page ${pageNumber}`,
      previousPageLabel: 'Previous page',
    };

    const pageSizePreference = {
      title: 'Select page size',
      options: [
        { value: 10, label: '10 resources' },
        { value: 20, label: '20 resources' },
      ],
    };

    const visibleContentPreference = {
      title: 'Select visible content',
      options: [
        {
          label: 'Main properties',
          options: columnDefinitions.map(({ id, header }) => ({ id, label: header, editable: id !== 'id' })),
        },
      ],
    };

    const collectionPreferencesProps = {
      pageSizePreference,
      visibleContentPreference,
      cancelLabel: 'Cancel',
      confirmLabel: 'Confirm',
      title: 'Preferences',
    };

    function EmptyState({ title, subtitle, action }) {
      return (
        <Box textAlign="center" color="inherit">
          <Box variant="strong" textAlign="center" color="inherit">
            {title}
          </Box>
          <Box variant="p" padding={{ bottom: 's' }} color="inherit">
            {subtitle}
          </Box>
          {action}
        </Box>
      );
    }


    const TableNoMatchState = () => (
      <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
          <div>
            <b>No matches</b>
            <Box variant="p" color="inherit">
              We can't find a match.
            </Box>
          </div>
          <Button onClick={() => {
            actions.setPropertyFiltering({ tokens: [], operation: 'and' });
          }}>Clear filter</Button>
        </SpaceBetween>
      </Box>
    );

    const TableEmptyState = () => (
      <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
          <div>
            {/* <b>No matches</b> */}
            <Box variant="p" color="inherit">
              No matches
            </Box>
          </div>
          {/* <Button>Create {resourceName.toLowerCase()}</Button> */}
        </SpaceBetween>
      </Box>
    );

    const filteringProperties = [
      // {
      //   propertyLabel: 'Site',
      //   key: 'site',
      //   groupValuesLabel: 'Sites',
      //   operators: [':', '!:', '=', '!='],
      // },
      {
        propertyLabel: 'Date',
        key: 'date',
        groupValuesLabel: 'Dates',
        operators: [':', '!:', '=', '!=', '>=', '<='],
      },
      // {
      //   propertyLabel: 'Value',
      //   key: 'value',
      //   groupValuesLabel: 'Values',
      //   operators: ['<', '>', '=', '!='],
      // },
      // {
      //   propertyLabel: 'Date',
      //   key: 'date',
      //   groupValuesLabel: 'Dates',
      //   operators: [':', '!:', '=', '!='],
      // },
      // {
      //   propertyLabel: 'Type',
      //   key: 'type',
      //   groupValuesLabel: 'Types',
      //   operators: [':', '!:', '=', '!='],
      // },
    ];

    // console.log(FILTERING_PROPERTIES)

    // console.log(filterState['fluxTable'])
    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(
      filterMsiCatalogTable,
    
      {
        propertyFiltering: {
          filteringProperties,
          empty: <TableEmptyState />,
          noMatch: (
            <TableNoMatchState
              onClearFilter={() => {
                actions.setPropertyFiltering({ tokens: [], operation: 'and' });
              }}
            />
          ),
        },
        pagination: { pageSize: preferences.pageSize },
        // sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
        sorting: {},
        // selection: {},
      }
    );
    // const { selectedItems } = collectionProps;
    return (
      <Table
        {...collectionProps}
        variant='borderless'
        resizableColumns={true}
        wrapLines
        // selectionType="multi"
        // onSelectionChange={(e) => {
        //   console.log(e)
        //   setSelectedMsiCatalogItems(e.detail.selectedItems)


        //   for (let index = 0; index < allMsiCatalogTableData.length; index++) {
        //     // console.log('ballon'+index)
        //     map.current.setLayoutProperty(allMsiCatalogTableData[index].id, 'visibility', 'none');
        //   }
        //   for (let index = 0; index < e.detail.selectedItems.length; index++) {
        //     const visLayerName = e.detail.selectedItems[index].id;
        //     // console.log(visLayerName)
        //     map.current.setLayoutProperty(visLayerName, 'visibility', 'visible');

        //   }
        // }}

        // selectedItems={selectedMsiCatalogItems}
        
        // header={
        //   <Header
        //     // counter={selectedItems.length}
        //   >
        //     Captures
        //   </Header>
        // }
        columnDefinitions={columnDefinitions}
        visibleColumns={catalogPreferences.visibleContent}
        items={items}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            i18nStrings={{
              filteringAriaLabel: 'Find captures',
              filteringPlaceholder: 'Find captures',
            }}
            // countText={getTextFilterCounterText(filteredItemsCount)}
            expandToViewport={true}
          />
        }
        preferences={
          <CollectionPreferences
            {...collectionPreferencesProps}
            preferences={catalogPreferences}
            onConfirm={({ detail }) => setCatalogPreferences(detail)}
          />
        }
      />
    )
  }

  function TropomiCatalogCollectionHooksTable() {

    // console.log(filterCatalogTable)

    const columnDefinitions = [
      {
        id: "id",
        header: "ID",
        cell: e => e.id,
        // width: 100,
        sortingField: "id"
      },
      {
        id: "name",
        header: "Name",
        cell: e => <Link onFollow={() => {
          map.current.flyTo({
            center: [
              ((e.bbox[0] + e.bbox[2]) / 2),
              ((e.bbox[1] + e.bbox[3]) / 2)
            ],
            zoom: 11
            // essential: true // this animation is considered essential with respect to prefers-reduced-motion
          })
        }}>{e.id}</Link>,
        // width: 100,
        sortingField: "id"
      },

      {
        id: "date",
        header: "Date",
        cell: e => e.properties.datetime,
        // sortingField: "name",
        // width: 100,
        // isRowHeader: true
      },
      {
        id: "display",
        header: "Plume",
        // cell: e => <Link onFollow={() => { catalogDownload(e.assets.NRT_NDMI_COG.href) }}><Icon name='download'/>
        cell: e => <SpaceBetween direction='horizontal' size='m'><Link onFollow={() => { catalogDownload(e.assets.Plume_Layer.href) }}><Icon name='download'/>
                {/* cell: e => <Link onFollow={() => { catalogDownload(e.assets.NRT_NDMI_COG.href) }}>{e.assets.NRT_NDMI_COG.title} */}

        </Link><Link onFollow={() => { catalogVisualDisplay(e.assets.Plume_Layer.href,e.bbox) }}><Icon name='search'/></Link></SpaceBetween>,
        // sortingField: "background",
        // width: 100,
      },
      // {
      //   id: "cog",
      //   header: "NDMI",
      //   // cell: e => <Link onFollow={() => { catalogDownload(e.assets.NRT_NDMI_COG.href) }}><Icon name='download'/>
      //   cell: e => <SpaceBetween direction='horizontal' size='m'><Link onFollow={() => { catalogDownload(e.assets.NRT_NDMI_COG.href) }}><Icon name='download'/>
      //           {/* cell: e => <Link onFollow={() => { catalogDownload(e.assets.NRT_NDMI_COG.href) }}>{e.assets.NRT_NDMI_COG.title} */}

      //   </Link><Link onFollow={() => { catalogNdmiDisplay(e.assets.NRT_NDMI_COG.href,e.bbox) }}><Icon name='search'/></Link></SpaceBetween>,
      //   // sortingField: "background",
      //   // width: 100,
      // },

      {
        id: "lat",
        header: "Lat",
        cell: e => ((e.bbox[1] + e.bbox[3]) / 2).toFixed(5),
        // sortingField: "lat",
        // width: 100,
      },
      {
        id: "lon",
        header: "Lon",
        cell: e => ((e.bbox[0] + e.bbox[2]) / 2).toFixed(5),
        // sortingField: "lon",
        // width: 100,
      },
      // {
      //   id: "mission",
      //   header: "Mission",
      //   cell: e => e.mission,
      //   sortingField: "mission",
      //   // width: 100,
      // },
      
      // {
      //   id: "h5",
      //   header: "H5",
      //   cell: e => <Link onFollow={() => { catalogDownload(e.assets.h5_data.href) }}>{e.assets.h5_data.title}
      //   </Link>,
      //   // sortingField: "mass",
      //   // width: 100,
      // },
      // {
      //   id: "rate",
      //   header: "Methane Emission Rate (kg/h)",
      //   cell: e => e.rate,
      //   sortingField: "rate",
      //   // width: 100,
      // },
      // {
      //   id: "download",
      //   header: "Download",
      //   cell: e => e.download,
      //   // width: 100,
      //   // sortingField: "name"
      // },
      // {
      //   id: "u10",
      //   header: "U 10 Direction (deg)",
      //   cell: e => e.u10,
      //   sortingField: "u10",
      //   // width: 100,
      // },
      // {
      //   id: "u10speed",
      //   header: "U 10 Speed",
      //   cell: e => e.u10speed,
      //   sortingField: "u10speed",
      //   // width: 100,
      // },
      // {
      //   id: "date",
      //   header: "Date",
      //   cell: e => e.date,
      //   sortingField: "date",
      //   // width: 100,
      // },

      // {
      //   id: "layerName",
      //   header: "Layer name",
      //   cell: e => e.layerName,
      //   sortingField: "layerName",
      //   // width: 100,
      // },
      // {
      //   id: "type",
      //   header: "Type",
      //   cell: e => e.type,
      //   // width: 100,
      //   sortingField: "type"
      // },
      // {
      //   id: "unit",
      //   header: "Unit",
      //   cell: e => e.unit,
      //   // width: 100,
      //   sortingField: "unit"
      // },
      // {
      //   id: "value",
      //   header: "Value",
      //   // width: 110,
      //   cell: e => e.value,
      //   sortingField: "value"
      // },
      // {
      //   id: "sensor",
      //   header: "Sensor",
      //   cell: e => e.sensor,
      //   sortingField: "sensor"
      // },
    ];

    const paginationLabels = {
      nextPageLabel: 'Next page',
      pageLabel: pageNumber => `Go to page ${pageNumber}`,
      previousPageLabel: 'Previous page',
    };

    const pageSizePreference = {
      title: 'Select page size',
      options: [
        { value: 10, label: '10 resources' },
        { value: 20, label: '20 resources' },
      ],
    };

    const visibleContentPreference = {
      title: 'Select visible content',
      options: [
        {
          label: 'Main properties',
          options: columnDefinitions.map(({ id, header }) => ({ id, label: header, editable: id !== 'id' })),
        },
      ],
    };

    const collectionPreferencesProps = {
      pageSizePreference,
      visibleContentPreference,
      cancelLabel: 'Cancel',
      confirmLabel: 'Confirm',
      title: 'Preferences',
    };

    function EmptyState({ title, subtitle, action }) {
      return (
        <Box textAlign="center" color="inherit">
          <Box variant="strong" textAlign="center" color="inherit">
            {title}
          </Box>
          <Box variant="p" padding={{ bottom: 's' }} color="inherit">
            {subtitle}
          </Box>
          {action}
        </Box>
      );
    }


    const TableNoMatchState = () => (
      <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
          <div>
            <b>No matches</b>
            <Box variant="p" color="inherit">
              We can't find a match.
            </Box>
          </div>
          <Button onClick={() => {
            actions.setPropertyFiltering({ tokens: [], operation: 'and' });
          }}>Clear filter</Button>
        </SpaceBetween>
      </Box>
    );

    const TableEmptyState = () => (
      <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
          <div>
            {/* <b>No matches</b> */}
            <Box variant="p" color="inherit">
              No matches
            </Box>
          </div>
          {/* <Button>Create {resourceName.toLowerCase()}</Button> */}
        </SpaceBetween>
      </Box>
    );

    const filteringProperties = [
      // {
      //   propertyLabel: 'Site',
      //   key: 'site',
      //   groupValuesLabel: 'Sites',
      //   operators: [':', '!:', '=', '!='],
      // },
      {
        propertyLabel: 'Date',
        key: 'date',
        groupValuesLabel: 'Dates',
        operators: [':', '!:', '=', '!=', '>=', '<='],
      },
      // {
      //   propertyLabel: 'Value',
      //   key: 'value',
      //   groupValuesLabel: 'Values',
      //   operators: ['<', '>', '=', '!='],
      // },
      // {
      //   propertyLabel: 'Date',
      //   key: 'date',
      //   groupValuesLabel: 'Dates',
      //   operators: [':', '!:', '=', '!='],
      // },
      // {
      //   propertyLabel: 'Type',
      //   key: 'type',
      //   groupValuesLabel: 'Types',
      //   operators: [':', '!:', '=', '!='],
      // },
    ];

    // console.log(FILTERING_PROPERTIES)

    // console.log(filterState['fluxTable'])
    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(
      filterTropomiCatalogTable,
      {
        propertyFiltering: {
          filteringProperties,
          empty: <TableEmptyState />,
          noMatch: (
            <TableNoMatchState
              onClearFilter={() => {
                actions.setPropertyFiltering({ tokens: [], operation: 'and' });
              }}
            />
          ),
        },
        pagination: { pageSize: preferences.pageSize },
        // sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
        sorting: {},
        // selection: {},
      }
    );
    // const { selectedItems } = collectionProps;
    return (
      <Table
        {...collectionProps}
        variant='borderless'
        resizableColumns={true}
        wrapLines
        // selectionType="multi"
        // onSelectionChange={(e) => {
        //   console.log(e)
        //   setSelectedTropomiCatalogItems(e.detail.selectedItems)


        //   for (let index = 0; index < allTropomiCatalogTableData.length; index++) {
        //     // console.log('ballon'+index)
        //     map.current.setLayoutProperty(allTropomiCatalogTableData[index].id, 'visibility', 'none');
        //   }
        //   for (let index = 0; index < e.detail.selectedItems.length; index++) {
        //     const visLayerName = e.detail.selectedItems[index].id;
        //     // console.log(visLayerName)
        //     map.current.setLayoutProperty(visLayerName, 'visibility', 'visible');

        //   }
        // }}

        // selectedItems={selectedTropomiCatalogItems}
        
        // header={
        //   <Header
        //     // counter={selectedItems.length}
        //   >
        //     Captures
        //   </Header>
        // }
        columnDefinitions={columnDefinitions}
        visibleColumns={catalogPreferences.visibleContent}
        items={items}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            i18nStrings={{
              filteringAriaLabel: 'Find captures',
              filteringPlaceholder: 'Find captures',
            }}
            // countText={getTextFilterCounterText(filteredItemsCount)}
            expandToViewport={true}
          />
        }
        preferences={
          <CollectionPreferences
            {...collectionPreferencesProps}
            preferences={catalogPreferences}
            onConfirm={({ detail }) => setCatalogPreferences(detail)}
          />
        }
      />
    )
  }

  function CatalogCollectionHooksTable() {

    // console.log(filterCatalogTable)

    const columnDefinitions = [
      {
        id: "id",
        header: "ID",
        cell: e => e.id,
        // width: 100,
        sortingField: "id"
      },
      {
        id: "name",
        header: "Name",
        cell: e => <Link onFollow={() => {
          map.current.flyTo({
            center: [
              ((e.bbox[0] + e.bbox[2]) / 2),
              ((e.bbox[1] + e.bbox[3]) / 2)
            ],
            zoom: 12
            // essential: true // this animation is considered essential with respect to prefers-reduced-motion
          })
        }}>{e.id}</Link>,
        // width: 100,
        sortingField: "id"
      },

      {
        id: "date",
        header: "Date",
        cell: e => e.properties.start_datetime,
        // sortingField: "name",
        // width: 100,
        // isRowHeader: true
      },
      // {
      //   id: "display",
      //   header: "Display",
      //   // cell: e => e.properties.start_datetime,
      //   cell: e => <Select
      //   selectedOption={{ label: "Polygon", value: "1" }}
      //   // onChange={({ detail }) =>
      //   //   setSelectedOption(detail.selectedOption)
      //   // }
        
      //   options={[
      //     { label: "Polygon", value: "1" },
      //     { label: "NRT COG", value: "2", disabled:true },
      //     // { label: "Option 3", value: "3" },
      //     // { label: "Option 4", value: "4" },
      //     // { label: "Option 5", value: "5" }
      //   ]}
      // />,

      //   // sortingField: "date",
      //   // width: 100,
      //   // isRowHeader: true
      // },
      {
        id: "display",
        header: "Visual",
        // cell: e => <Link onFollow={() => { catalogDownload(e.assets.NRT_NDMI_COG.href) }}><Icon name='download'/>
        cell: e => <SpaceBetween direction='horizontal' size='m'><Link onFollow={() => { catalogDownload(e.assets.NRT_VISUAL_COG.href) }}><Icon name='download'/>
                {/* cell: e => <Link onFollow={() => { catalogDownload(e.assets.NRT_NDMI_COG.href) }}>{e.assets.NRT_NDMI_COG.title} */}

        </Link><Link onFollow={() => { catalogVisualDisplay(e.assets.NRT_VISUAL_COG.href,e.bbox) }}><Icon name='search'/></Link></SpaceBetween>,
        // sortingField: "background",
        // width: 100,
      },
      {
        id: "cog",
        header: "NDMI",
        // cell: e => <Link onFollow={() => { catalogDownload(e.assets.NRT_NDMI_COG.href) }}><Icon name='download'/>
        cell: e => <SpaceBetween direction='horizontal' size='m'><Link onFollow={() => { catalogDownload(e.assets.NRT_NDMI_COG.href) }}><Icon name='download'/>
                {/* cell: e => <Link onFollow={() => { catalogDownload(e.assets.NRT_NDMI_COG.href) }}>{e.assets.NRT_NDMI_COG.title} */}

        </Link><Link onFollow={() => { catalogNdmiDisplay(e.assets.NRT_NDMI_COG.href,e.bbox) }}><Icon name='search'/></Link></SpaceBetween>,
        // sortingField: "background",
        // width: 100,
      },
      {
        id: "lat",
        header: "Lat",
        cell: e => ((e.bbox[1] + e.bbox[3]) / 2).toFixed(5),
        // sortingField: "lat",
        // width: 100,
      },
      {
        id: "lon",
        header: "Lon",
        cell: e => ((e.bbox[0] + e.bbox[2]) / 2).toFixed(5),
        // sortingField: "lon",
        // width: 100,
      },
      // {
      //   id: "mission",
      //   header: "Mission",
      //   cell: e => e.mission,
      //   sortingField: "mission",
      //   // width: 100,
      // },
      
      // {
      //   id: "h5",
      //   header: "H5",
      //   cell: e => <Link onFollow={() => { catalogDownload(e.assets.h5_data.href) }}>{e.assets.h5_data.title}
      //   </Link>,
      //   // sortingField: "mass",
      //   // width: 100,
      // },
      // {
      //   id: "rate",
      //   header: "Methane Emission Rate (kg/h)",
      //   cell: e => e.rate,
      //   sortingField: "rate",
      //   // width: 100,
      // },
      // {
      //   id: "download",
      //   header: "Download",
      //   cell: e => e.download,
      //   // width: 100,
      //   // sortingField: "name"
      // },
      // {
      //   id: "u10",
      //   header: "U 10 Direction (deg)",
      //   cell: e => e.u10,
      //   sortingField: "u10",
      //   // width: 100,
      // },
      // {
      //   id: "u10speed",
      //   header: "U 10 Speed",
      //   cell: e => e.u10speed,
      //   sortingField: "u10speed",
      //   // width: 100,
      // },
      // {
      //   id: "date",
      //   header: "Date",
      //   cell: e => e.date,
      //   sortingField: "date",
      //   // width: 100,
      // },

      // {
      //   id: "layerName",
      //   header: "Layer name",
      //   cell: e => e.layerName,
      //   sortingField: "layerName",
      //   // width: 100,
      // },
      // {
      //   id: "type",
      //   header: "Type",
      //   cell: e => e.type,
      //   // width: 100,
      //   sortingField: "type"
      // },
      // {
      //   id: "unit",
      //   header: "Unit",
      //   cell: e => e.unit,
      //   // width: 100,
      //   sortingField: "unit"
      // },
      // {
      //   id: "value",
      //   header: "Value",
      //   // width: 110,
      //   cell: e => e.value,
      //   sortingField: "value"
      // },
      // {
      //   id: "sensor",
      //   header: "Sensor",
      //   cell: e => e.sensor,
      //   sortingField: "sensor"
      // },
    ];

    const paginationLabels = {
      nextPageLabel: 'Next page',
      pageLabel: pageNumber => `Go to page ${pageNumber}`,
      previousPageLabel: 'Previous page',
    };

    const pageSizePreference = {
      title: 'Select page size',
      options: [
        { value: 10, label: '10 resources' },
        { value: 20, label: '20 resources' },
      ],
    };

    const visibleContentPreference = {
      title: 'Select visible content',
      options: [
        {
          label: 'Main properties',
          options: columnDefinitions.map(({ id, header }) => ({ id, label: header, editable: id !== 'id' })),
        },
      ],
    };

    const collectionPreferencesProps = {
      pageSizePreference,
      visibleContentPreference,
      cancelLabel: 'Cancel',
      confirmLabel: 'Confirm',
      title: 'Preferences',
    };

    function EmptyState({ title, subtitle, action }) {
      return (
        <Box textAlign="center" color="inherit">
          <Box variant="strong" textAlign="center" color="inherit">
            {title}
          </Box>
          <Box variant="p" padding={{ bottom: 's' }} color="inherit">
            {subtitle}
          </Box>
          {action}
        </Box>
      );
    }


    const TableNoMatchState = () => (
      <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
          <div>
            <b>No matches</b>
            <Box variant="p" color="inherit">
              We can't find a match.
            </Box>
          </div>
          <Button onClick={() => {
            actions.setPropertyFiltering({ tokens: [], operation: 'and' });
          }}>Clear filter</Button>
        </SpaceBetween>
      </Box>
    );

    const TableEmptyState = () => (
      <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
          <div>
            {/* <b>No matches</b> */}
            <Box variant="p" color="inherit">
              No matches
            </Box>
          </div>
          {/* <Button>Create {resourceName.toLowerCase()}</Button> */}
        </SpaceBetween>
      </Box>
    );

    const filteringProperties = [
      // {
      //   propertyLabel: 'Site',
      //   key: 'site',
      //   groupValuesLabel: 'Sites',
      //   operators: [':', '!:', '=', '!='],
      // },
      {
        propertyLabel: 'Date',
        key: 'date',
        groupValuesLabel: 'Dates',
        operators: [':', '!:', '=', '!=', '>=', '<='],
      },
      // {
      //   propertyLabel: 'Value',
      //   key: 'value',
      //   groupValuesLabel: 'Values',
      //   operators: ['<', '>', '=', '!='],
      // },
      // {
      //   propertyLabel: 'Date',
      //   key: 'date',
      //   groupValuesLabel: 'Dates',
      //   operators: [':', '!:', '=', '!='],
      // },
      // {
      //   propertyLabel: 'Type',
      //   key: 'type',
      //   groupValuesLabel: 'Types',
      //   operators: [':', '!:', '=', '!='],
      // },
    ];

    // console.log(FILTERING_PROPERTIES)

    // console.log(filterState['fluxTable'])
    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(
      filterCatalogTable,
      {
        propertyFiltering: {
          filteringProperties,
          empty: <TableEmptyState />,
          noMatch: (
            <TableNoMatchState
              onClearFilter={() => {
                actions.setPropertyFiltering({ tokens: [], operation: 'and' });
              }}
            />
          ),
        },
        pagination: { pageSize: preferences.pageSize },
        // sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
        sorting: {},
        // selection: {},
      }
    );
    // const { selectedItems } = collectionProps;
    return (
      <Table
        {...collectionProps}
        variant='borderless'
        resizableColumns={true}
        wrapLines
        selectionType="multi"
        onSelectionChange={(e) => {
          console.log(e)
          setSelectedCatalogItems(e.detail.selectedItems)


          for (let index = 0; index < allCatalogTableData.length; index++) {
            // console.log('ballon'+index)
            map.current.setLayoutProperty(allCatalogTableData[index].id, 'visibility', 'none');
          }
          for (let index = 0; index < e.detail.selectedItems.length; index++) {
            const visLayerName = e.detail.selectedItems[index].id;
            // console.log(visLayerName)
            map.current.setLayoutProperty(visLayerName, 'visibility', 'visible');

          }
        }}

        selectedItems={selectedCatalogItems}
        
        // header={
        //   <Header
        //     // counter={selectedItems.length}
        //   >
        //     Captures
        //   </Header>
        // }
        columnDefinitions={columnDefinitions}
        visibleColumns={catalogPreferences.visibleContent}
        items={items}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            i18nStrings={{
              filteringAriaLabel: 'Find captures',
              filteringPlaceholder: 'Find captures',
            }}
            // countText={getTextFilterCounterText(filteredItemsCount)}
            expandToViewport={true}
          />
        }
        preferences={
          <CollectionPreferences
            {...collectionPreferencesProps}
            preferences={catalogPreferences}
            onConfirm={({ detail }) => setCatalogPreferences(detail)}
          />
        }
      />
    )
  }

  function CollectionHooksTable() {


    const columnDefinitions = [
      {
        id: "link",
        header: "Time",
        cell: e => e.link,
        // width: 100,
        sortingField: "name"
      },

      {
        id: "name",
        header: "Name",
        cell: e => e.name,
        sortingField: "name",
        // width: 100,
        // isRowHeader: true
      },
      // {
      //   id: "visible",
      //   header: "Visible",
      //   cell: e => e.visible,
      //   sortingField: "visible",
      //   // width: 100,
      // },
      {
        id: "background",
        header: "Background CH4 Column (g/m^2)",
        cell: e => e.background,
        sortingField: "background",
        // width: 100,
      },
      {
        id: "mass",
        header: "Integrated Mass Enhancement (g)",
        cell: e => e.mass,
        sortingField: "mass",
        // width: 100,
      },
      {
        id: "rate",
        header: "Methane Emission Rate (kg/h)",
        cell: e => e.rate,
        sortingField: "rate",
        // width: 100,
      },
      {
        id: "download",
        header: "Download",
        cell: e => e.download,
        // width: 100,
        // sortingField: "name"
      },
      {
        id: "u10",
        header: "U 10 Direction (deg)",
        cell: e => e.u10,
        sortingField: "u10",
        // width: 100,
      },
      {
        id: "u10speed",
        header: "U 10 Speed",
        cell: e => e.u10speed,
        sortingField: "u10speed",
        // width: 100,
      },
      {
        id: "date",
        header: "Date",
        cell: e => e.date,
        sortingField: "date",
        // width: 100,
      },
      {
        id: "lat",
        header: "Lat",
        cell: e => e.lat + soofieOffsetLat,
        sortingField: "lat",
        // width: 100,
      },
      {
        id: "lon",
        header: "Lon",
        cell: e => e.lon + soofieOffsetLon,
        sortingField: "lon",
        // width: 100,
      },
      {
        id: "layerName",
        header: "Layer name",
        cell: e => e.layerName,
        sortingField: "layerName",
        // width: 100,
      },
      // {
      //   id: "type",
      //   header: "Type",
      //   cell: e => e.type,
      //   // width: 100,
      //   sortingField: "type"
      // },
      // {
      //   id: "unit",
      //   header: "Unit",
      //   cell: e => e.unit,
      //   // width: 100,
      //   sortingField: "unit"
      // },
      // {
      //   id: "value",
      //   header: "Value",
      //   // width: 110,
      //   cell: e => e.value,
      //   sortingField: "value"
      // },
      // {
      //   id: "sensor",
      //   header: "Sensor",
      //   cell: e => e.sensor,
      //   sortingField: "sensor"
      // },
    ];

    const paginationLabels = {
      nextPageLabel: 'Next page',
      pageLabel: pageNumber => `Go to page ${pageNumber}`,
      previousPageLabel: 'Previous page',
    };

    const pageSizePreference = {
      title: 'Select page size',
      options: [
        { value: 10, label: '10 resources' },
        { value: 20, label: '20 resources' },
      ],
    };

    const visibleContentPreference = {
      title: 'Select visible content',
      options: [
        {
          label: 'Main properties',
          options: columnDefinitions.map(({ id, header }) => ({ id, label: header, editable: id !== 'id' })),
        },
      ],
    };

    const collectionPreferencesProps = {
      pageSizePreference,
      visibleContentPreference,
      cancelLabel: 'Cancel',
      confirmLabel: 'Confirm',
      title: 'Preferences',
    };

    function EmptyState({ title, subtitle, action }) {
      return (
        <Box textAlign="center" color="inherit">
          <Box variant="strong" textAlign="center" color="inherit">
            {title}
          </Box>
          <Box variant="p" padding={{ bottom: 's' }} color="inherit">
            {subtitle}
          </Box>
          {action}
        </Box>
      );
    }


    const TableNoMatchState = () => (
      <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
          <div>
            <b>No matches</b>
            <Box variant="p" color="inherit">
              We can't find a match.
            </Box>
          </div>
          <Button onClick={() => {
            actions.setPropertyFiltering({ tokens: [], operation: 'and' });
          }}>Clear filter</Button>
        </SpaceBetween>
      </Box>
    );

    const TableEmptyState = () => (
      <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
          <div>
            {/* <b>No matches</b> */}
            <Box variant="p" color="inherit">
              No matches
            </Box>
          </div>
          {/* <Button>Create {resourceName.toLowerCase()}</Button> */}
        </SpaceBetween>
      </Box>
    );

    const filteringProperties = [
      // {
      //   propertyLabel: 'Site',
      //   key: 'site',
      //   groupValuesLabel: 'Sites',
      //   operators: [':', '!:', '=', '!='],
      // },
      {
        propertyLabel: 'Name',
        key: 'name',
        groupValuesLabel: 'Names',
        operators: [':', '!:', '=', '!=', '>=', '<='],
      },
      // {
      //   propertyLabel: 'Value',
      //   key: 'value',
      //   groupValuesLabel: 'Values',
      //   operators: ['<', '>', '=', '!='],
      // },
      // {
      //   propertyLabel: 'Date',
      //   key: 'date',
      //   groupValuesLabel: 'Dates',
      //   operators: [':', '!:', '=', '!='],
      // },
      // {
      //   propertyLabel: 'Type',
      //   key: 'type',
      //   groupValuesLabel: 'Types',
      //   operators: [':', '!:', '=', '!='],
      // },
    ];

    // console.log(FILTERING_PROPERTIES)

    // console.log(filterState['fluxTable'])
    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(
      filterPlumeTable,
      {
        propertyFiltering: {
          filteringProperties,
          empty: <TableEmptyState />,
          noMatch: (
            <TableNoMatchState
              onClearFilter={() => {
                actions.setPropertyFiltering({ tokens: [], operation: 'and' });
              }}
            />
          ),
        },
        pagination: { pageSize: preferences.pageSize },
        // sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
        sorting: {},
        // selection: {},
      }
    );
    // const { selectedItems } = collectionProps;
    return (
      <Table
        {...collectionProps}
        variant='borderless'
        resizableColumns={true}
        wrapLines
        selectionType="multi"
        onSelectionChange={(e) => {
          // console.log(e)
          setSelectedItems(e.detail.selectedItems)
          for (let index = 1; index < 21; index++) {
            // console.log('ballon'+index)
            map.current.setLayoutProperty('balloon' + index, 'visibility', 'none');
          }
          for (let index = 0; index < e.detail.selectedItems.length; index++) {
            const visLayerName = e.detail.selectedItems[index]['layerName'];
            // console.log(visLayerName)
            map.current.setLayoutProperty(visLayerName, 'visibility', 'visible');

          }
        }}
        selectedItems={selectedItems}
        // header={
        //   <Header
        //     counter={selectedItems.length}
        //   >
        //     Images
        //   </Header>
        // }
        columnDefinitions={columnDefinitions}
        visibleColumns={preferences.visibleContent}
        items={items}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            i18nStrings={{
              filteringAriaLabel: 'Find plumes',
              filteringPlaceholder: 'Find plumes',
            }}
            // countText={getTextFilterCounterText(filteredItemsCount)}
            expandToViewport={true}
          />
        }
        preferences={
          <CollectionPreferences
            {...collectionPreferencesProps}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
          />
        }
      />
    )
  }

  function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }

  // usage
  async function download() {
    const result = await Storage.get('patch-notes/patch-notes-20240307.pdf', { download: true });
    downloadBlob(result.Body, 'patch-notes-20240307.pdf');
  }

  async function downloadPlume(plumeName) {
    const result = await Storage.get(plumeName, { download: true });
    downloadBlob(result.Body, plumeName);
  }

  async function fetchDevices() {
    try {
      // await DataStore.stop();
      // await DataStore.start();

      // initializeMap();
      // try {
      //   initializeMap();
      // } catch (error) {
      //   console.log(error)
      // }

      setFilterPlumeTable(tableData);
      setAllTableData(tableData);

      // setFilterCatalogTable(catalogTableData);
      // setAllCatalogTableData(catalogTableData);

      API.get('stacfastapi-test', '/collections/haps_swir/items', {queryStringParameters: {
        limit: 36 // OPTIONAL
      }})
        .then((response) => {
          console.log(response)
          
          let filtered_features = [];
          // console.log(response.features)
          for (let index = 0; index < response.features.length; index++) {
            console.log(response.features[index].properties['scepter:qa_pass'])
            if (response.features[index].properties['scepter:qa_pass']=='false') {
              console.log('SKIPPED')
              continue
            }
            filtered_features.push(response.features[index])
            map.current.addSource(response.features[index].id, {
              type: 'geojson',
              // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
              data: response.features[index],
              // cluster: true,
              // clusterRadius: 50,
              // clusterMaxZoom: 17, // Max zoom to cluster points on
            });

            map.current.addLayer({
              id: response.features[index].id,
              type: 'fill',
              source: response.features[index].id,
              // filter: ['!', ['has', 'point_count']],
              layout: {'visibility': 'none'},
              paint: {
                'fill-color': '#800080',
                'fill-opacity': 0.5,
                // 'circle-radius': 7,
                // 'circle-stroke-width': 1,
                // 'circle-stroke-color': '#000'
              }
            });
           


          }

          setCatalogData(filtered_features)
          setFilterCatalogTable(filtered_features);
          setAllCatalogTableData(filtered_features);

          // Add your code here
        })
        .catch((error) => {
          console.log(error)
          console.log(error.response);
        });

        API.get('stacfastapi-test', '/collections/tropomi_hotspots/items', {queryStringParameters: {
          limit: 100 // OPTIONAL
        }})
        .then((response) => {
          console.log(response)
          setTropomiCatalogData(response.features)
          setTropomiFilterCatalogTable(response.features);
          setAllTropomiCatalogTableData(response.features);
          
          // console.log(response.features)
          for (let index = 0; index < response.features.length; index++) {
            // console.log(response.features[index])
            map.current.addSource(response.features[index].id, {
              type: 'geojson',
              // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
              data: response.features[index],
              // cluster: true,
              // clusterRadius: 50,
              // clusterMaxZoom: 17, // Max zoom to cluster points on
            });

            map.current.addLayer({
              id: response.features[index].id,
              type: 'fill',
              source: response.features[index].id,
              // filter: ['!', ['has', 'point_count']],
              layout: {'visibility': 'none'},
              paint: {
                'fill-color': '#800080',
                'fill-opacity': 0.5,
                // 'circle-radius': 7,
                // 'circle-stroke-width': 1,
                // 'circle-stroke-color': '#000'
              }
            });
           


          }

          // Add your code here
        })
        .catch((error) => {
          console.log(error)
          console.log(error.response);
        });

        API.get('stacfastapi-test', '/collections/msi_plumes/items', {queryStringParameters: {
          limit: 100 // OPTIONAL
        }})
        .then((response) => {
          console.log(response)
          setMsiCatalogData(response.features)
          setMsiFilterCatalogTable(response.features);
          setAllMsiCatalogTableData(response.features);
          // console.log(response.features)
          for (let index = 0; index < response.features.length; index++) {
            // console.log(response.features[index])
            map.current.addSource(response.features[index].id, {
              type: 'geojson',
              // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
              data: response.features[index],
              // cluster: true,
              // clusterRadius: 50,
              // clusterMaxZoom: 17, // Max zoom to cluster points on
            });

            map.current.addLayer({
              id: response.features[index].id,
              type: 'fill',
              source: response.features[index].id,
              // filter: ['!', ['has', 'point_count']],
              layout: {'visibility': 'none'},
              paint: {
                'fill-color': '#800080',
                'fill-opacity': 0.5,
                // 'circle-radius': 7,
                // 'circle-stroke-width': 1,
                // 'circle-stroke-color': '#000'
              }
            });
           


          }

          // Add your code here
        })
        .catch((error) => {
          console.log(error)
          console.log(error.response);
        });


      // const flightKey = 'flight.json'
      const flightKey = 'flight-20240430.json'
      const flightUrl = await Storage.get(flightKey, { useAccelerateEndpoint: true, expiration: 28800 });
      // console.log(flightUrl);
      var flightJson = ''
      fetch(flightUrl)
        .then(res => res.json())
        .then(out => {
          // console.log(out)
          var flightsource = map.current.addSource('flightSource', {
            type: 'geojson',
            // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
            data: out,
            // filter: ["==", "time", '00'],
            // cluster: true,
            // clusterRadius: 50,
            // clusterMaxZoom: 17, // Max zoom to cluster points on
          });



          map.current.addLayer({
            id: 'flightLayer',
            type: 'fill-extrusion',
            layout: { 'visibility': 'none' },
            source: 'flightSource',
            // minzoom: 10,
            'paint': {
              // See the MapLibre Style Specification for details on data expressions.
              // https://maplibre.org/maplibre-style-spec/expressions/

              // Get the fill-extrusion-color from the source 'color' property.
              'fill-extrusion-color': '#53ed60',

              // Get fill-extrusion-height from the source 'height' property.
              'fill-extrusion-height': ['get', 'height'],

              // Get fill-extrusion-base from the source 'base_height' property.
              'fill-extrusion-base': ['get', 'base_height'],

              // Make extrusions slightly opaque for see through indoor walls.
              'fill-extrusion-opacity': 1
            }
          })


        })
        // flightJson = out)
        .catch(err => { throw err });

      const flight2Key = 'flight.json'
      // const flightKey = 'flight-20240430.json'
      const flight2Url = await Storage.get(flight2Key, { useAccelerateEndpoint: true, expiration: 28800 });
      // console.log(flightUrl);
      var flightJson = ''
      fetch(flight2Url)
        .then(res => res.json())
        .then(out => {
          // console.log(out)
          var flight2source = map.current.addSource('flight2Source', {
            type: 'geojson',
            // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
            data: out,
            // filter: ["==", "time", '00'],
            // cluster: true,
            // clusterRadius: 50,
            // clusterMaxZoom: 17, // Max zoom to cluster points on
          });



          map.current.addLayer({
            id: 'flight2Layer',
            type: 'fill-extrusion',
            layout: { 'visibility': 'none' },
            source: 'flight2Source',
            // minzoom: 10,
            'paint': {
              // See the MapLibre Style Specification for details on data expressions.
              // https://maplibre.org/maplibre-style-spec/expressions/

              // Get the fill-extrusion-color from the source 'color' property.
              'fill-extrusion-color': '#53ed60',

              // Get fill-extrusion-height from the source 'height' property.
              'fill-extrusion-height': ['get', 'height'],

              // Get fill-extrusion-base from the source 'base_height' property.
              'fill-extrusion-base': ['get', 'base_height'],

              // Make extrusions slightly opaque for see through indoor walls.
              'fill-extrusion-opacity': 1
            }
          })


        })
        // flightJson = out)
        .catch(err => { throw err });

      await new Promise(r => setTimeout(r, 500));
      const soofiesAllKey = 'soofie/exxon_data/soofies_Confidential_offset.json'
      const soofiesAllUrl = await Storage.get(soofiesAllKey, { useAccelerateEndpoint: true, expiration: 28800 });
      // console.log(oilGasWellsUrl)
      fetch(soofiesAllUrl)
        .then(res => res.json())
        .then(out => {
          // flightLineJson = out
          // console.log(out)
          map.current.addSource('soofiesAllSource', {
            type: 'geojson',
            // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
            data: out,
            cluster: true,
            clusterRadius: 50,
            clusterMaxZoom: 17, // Max zoom to cluster points on
          });



          map.current.addLayer({
            id: 'soofiesAllClustersLayer',
            type: 'circle',
            source: 'soofiesAllSource',
            // minzoom: 6,
            filter: ['has', 'point_count'],
            paint: {
              // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
              // with three steps to implement three types of circles:
              //   * Blue, 20px circles when point count is less than 100
              //   * Yellow, 30px circles when point count is between 100 and 750
              //   * Pink, 40px circles when point count is greater than or equal to 750
              'circle-color': [
                'step',
                ['get', 'point_count'],
                '#abf5f2',
                5,
                '#abf5f2',
                20,
                '#abf5f2'
              ],
              'circle-stroke-width': [
                'step',
                ['get', 'point_count'],
                1,
                5,
                1,
                20,
                1
              ],
              'circle-stroke-color': [
                'step',
                ['get', 'point_count'],
                '#000',
                5,
                '#000',
                20,
                '#000'
              ],
              'circle-radius': [
                'step',
                ['get', 'point_count'],
                10,
                5,
                15,
                20,
                20
              ]
            }
          });

          // console.log(map)

          map.current.addLayer({
            id: 'soofies-all-cluster-count',
            type: 'symbol',
            source: 'soofiesAllSource',
            filter: ['has', 'point_count'],
            layout: {
              'text-field': '{point_count_abbreviated}',
              'text-font': ['literal', ['Fira GO Regular']],
              'text-size': 12
            }
          });


          map.current.addLayer({
            id: 'unclustered-soofies-all',
            type: 'circle',
            source: 'soofiesAllSource',
            filter: ['!', ['has', 'point_count']],
            paint: {
              'circle-color': '#abf5f2',
              'circle-radius': 7,
              'circle-stroke-width': 1,
              'circle-stroke-color': '#000'
            }
          });

          map.current.on('click', 'unclustered-soofies-all', (e) => {
            console.log(e.features[0].properties)
            const coordinates = e.features[0].geometry.coordinates.slice();
            const name = e.features[0].properties.name;
            // const stats = e.features[0].properties;
            // console.log(stats)
            const site = e.features[0].properties.site;
            // <b>Site:</b> ${site}<br />
            // <b>Name</b>: ${name} <br />
            // <b>Site</b>: ${site} <br />
            var text = `
           
            <b>Lon</b>: ${coordinates[0]} <br />
            <b>Lat</b>: ${coordinates[1]} <br />
                  `

            // const mag = e.features[0].properties.mag;
            // const tsunami =
            // e.features[0].properties.tsunami === 1 ? 'yes' : 'no';

            // Ensure that if the map is zoomed out such that
            // multiple copies of the feature are visible, the
            // popup appears over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            new maplibregl.Popup({ offset: 10, closeOnClick: true })
              .setLngLat(coordinates)
              // .trackPointer()
              .setHTML(
                text
              )
              .addTo(map.current);
          });

          map.current.on('click', 'soofiesAllClustersLayer', (e) => {
            // console.log(e)
            const features = map.current.queryRenderedFeatures(e.point, {
              layers: ['soofiesAllClustersLayer']
            });
            const clusterId = features[0].properties.cluster_id;
            map.current.getSource('soofiesAllSource').getClusterExpansionZoom(
              clusterId,
              (err, zoom) => {
                if (err) return;

                map.current.easeTo({
                  center: features[0].geometry.coordinates,
                  zoom: zoom
                });
              }
            );
          });
        })
        .catch(err => { throw err });

      // const oilGasWellsKey = 'oil-and-gas-database/new-mexico-wells.json'
      const oilGasWellsKey = 'oil-and-gas-database/texas-wells.json'
      const landfillsKey = 'landfills/landfills.json'
      const landfillsUrl = await Storage.get(landfillsKey, { useAccelerateEndpoint: true, expiration: 28800 });

      const oilGasWellsUrl = await Storage.get(oilGasWellsKey, { useAccelerateEndpoint: true, expiration: 28800 });
      // console.log(oilGasWellsUrl)
      fetch(oilGasWellsUrl)
        .then(res => res.json())
        .then(out => {
          // flightLineJson = out
          // console.log(out)
          map.current.addSource('oilGasWellSource', {
            type: 'geojson',
            // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
            data: out,
            cluster: true,
            clusterRadius: 50,
            clusterMaxZoom: 17, // Max zoom to cluster points on
          });



          map.current.addLayer({
            id: 'oilGasWellClustersLayer',
            type: 'circle',
            source: 'oilGasWellSource',
            // minzoom: 6,
            filter: ['has', 'point_count'],
            layout: {'visibility':'none'},
            paint: {
              // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
              // with three steps to implement three types of circles:
              //   * Blue, 20px circles when point count is less than 100
              //   * Yellow, 30px circles when point count is between 100 and 750
              //   * Pink, 40px circles when point count is greater than or equal to 750
              'circle-color': [
                'step',
                ['get', 'point_count'],
                '#ffff00',
                5,
                '#ffff00',
                20,
                '#ffff00'
              ],
              'circle-stroke-width': [
                'step',
                ['get', 'point_count'],
                1,
                5,
                1,
                20,
                1
              ],
              'circle-stroke-color': [
                'step',
                ['get', 'point_count'],
                '#000',
                5,
                '#000',
                20,
                '#000'
              ],
              'circle-radius': [
                'step',
                ['get', 'point_count'],
                10,
                5,
                15,
                20,
                20
              ]
            }
          });

          // console.log(map)

          map.current.addLayer({
            id: 'oil-gas-well-cluster-count',
            type: 'symbol',
            source: 'oilGasWellSource',
            filter: ['has', 'point_count'],
            layout: {
              'text-field': '{point_count_abbreviated}',
              'text-font': ['literal', ['Fira GO Regular']],
              'text-size': 12,
            'visibility':'none'
            }
          });


          map.current.addLayer({
            id: 'unclustered-oil-gas-well',
            type: 'circle',
            source: 'oilGasWellSource',
            filter: ['!', ['has', 'point_count']],
            layout: {'visibility':'none'},
            paint: {
              'circle-color': '#ffff00',
              'circle-radius': 7,
              'circle-stroke-width': 1,
              'circle-stroke-color': '#000'
            }
          });

          // map.current.on('click', 'unclustered-oil-gas-well', (e) => {
          //   console.log(e.features[0].properties)
          //   const coordinates = e.features[0].geometry.coordinates.slice();
          //   const id = e.features[0].properties.OGIM_ID;
          //   const stats = e.features[0].properties;
          //   // console.log(stats)
          //   // const site = e.features[0].properties.site;
          //   // <b>Site:</b> ${site}<br />
          //   var text = `
          //   <b>Operator</b>: ${stats.OPERATOR} <br />
          //   <b>Facility</b>: ${stats.FAC_NAME} <br />
          //         `

          //   // const mag = e.features[0].properties.mag;
          //   // const tsunami =
          //   // e.features[0].properties.tsunami === 1 ? 'yes' : 'no';

          //   // Ensure that if the map is zoomed out such that
          //   // multiple copies of the feature are visible, the
          //   // popup appears over the copy being pointed to.
          //   while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          //     coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          //   }

          //   new maplibregl.Popup({ offset: 10, closeOnClick: true })
          //     .setLngLat(coordinates)
          //     // .trackPointer()
          //     .setHTML(
          //       text
          //     )
          //     .addTo(map.current);
          // });

          map.current.on('click', 'oilGasWellClustersLayer', (e) => {
            // console.log(e)
            const features = map.current.queryRenderedFeatures(e.point, {
              layers: ['oilGasWellClustersLayer']
            });
            const clusterId = features[0].properties.cluster_id;
            map.current.getSource('oilGasWellSource').getClusterExpansionZoom(
              clusterId,
              (err, zoom) => {
                if (err) return;

                map.current.easeTo({
                  center: features[0].geometry.coordinates,
                  zoom: zoom
                });
              }
            );
          });
        })
        .catch(err => { throw err });


      fetch(landfillsUrl)
        .then(res => res.json())
        .then(out => {
          // flightLineJson = out
          // console.log(out)
          map.current.addSource('landfillsSource', {
            type: 'geojson',
            // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
            data: out,
            cluster: true,
            clusterRadius: 50,
            clusterMaxZoom: 17, // Max zoom to cluster points on
          });



          map.current.addLayer({
            id: 'landfillsClustersLayer',
            type: 'circle',
            source: 'landfillsSource',
            // minzoom: 6,
            filter: ['has', 'point_count'],
            layout: {'visibility':'none'},
            paint: {
              // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
              // with three steps to implement three types of circles:
              //   * Blue, 20px circles when point count is less than 100
              //   * Yellow, 30px circles when point count is between 100 and 750
              //   * Pink, 40px circles when point count is greater than or equal to 750
              'circle-color': [
                'step',
                ['get', 'point_count'],
                '#ffa500',
                5,
                '#ffa500',
                20,
                '#ffa500'
              ],
              'circle-stroke-width': [
                'step',
                ['get', 'point_count'],
                1,
                5,
                1,
                20,
                1
              ],
              'circle-stroke-color': [
                'step',
                ['get', 'point_count'],
                '#000',
                5,
                '#000',
                20,
                '#000'
              ],
              'circle-radius': [
                'step',
                ['get', 'point_count'],
                10,
                5,
                15,
                20,
                20
              ]
            }
          });

          // console.log(map)

          map.current.addLayer({
            id: 'landfills-cluster-count',
            type: 'symbol',
            source: 'landfillsSource',
            filter: ['has', 'point_count'],
            layout: {
              'text-field': '{point_count_abbreviated}',
              'text-font': ['literal', ['Fira GO Regular']],
              'text-size': 12,
              'visibility': 'none'
            }
          });


          map.current.addLayer({
            id: 'unclustered-landfills',
            type: 'circle',
            source: 'landfillsSource',
            filter: ['!', ['has', 'point_count']],
            paint: {
              'circle-color': '#ffa500',
              'circle-radius': 7,
              'circle-stroke-width': 1,
              'circle-stroke-color': '#000'
            },
            layout: {
              'visibility': 'none'
            }
          });

          // map.current.on('click', 'unclustered-oil-gas-well', (e) => {
          //   console.log(e.features[0].properties)
          //   const coordinates = e.features[0].geometry.coordinates.slice();
          //   const id = e.features[0].properties.OGIM_ID;
          //   const stats = e.features[0].properties;
          //   // console.log(stats)
          //   // const site = e.features[0].properties.site;
          //   // <b>Site:</b> ${site}<br />
          //   var text = `
          //   <b>Operator</b>: ${stats.OPERATOR} <br />
          //   <b>Facility</b>: ${stats.FAC_NAME} <br />
          //         `

          //   // const mag = e.features[0].properties.mag;
          //   // const tsunami =
          //   // e.features[0].properties.tsunami === 1 ? 'yes' : 'no';

          //   // Ensure that if the map is zoomed out such that
          //   // multiple copies of the feature are visible, the
          //   // popup appears over the copy being pointed to.
          //   while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          //     coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          //   }

          //   new maplibregl.Popup({ offset: 10, closeOnClick: true })
          //     .setLngLat(coordinates)
          //     // .trackPointer()
          //     .setHTML(
          //       text
          //     )
          //     .addTo(map.current);
          // });

          map.current.on('click', 'landfillsClustersLayer', (e) => {
            // console.log(e)
            const features = map.current.queryRenderedFeatures(e.point, {
              layers: ['landfillsClustersLayer']
            });
            const clusterId = features[0].properties.cluster_id;
            map.current.getSource('landfillsSource').getClusterExpansionZoom(
              clusterId,
              (err, zoom) => {
                if (err) return;

                map.current.easeTo({
                  center: features[0].geometry.coordinates,
                  zoom: zoom
                });
              }
            );
          });
        })
        .catch(err => { throw err });

      const texasPipesKey = 'oil-and-gas-database/texas-pipes.json'
      const texasPipesUrl = await Storage.get(texasPipesKey, { useAccelerateEndpoint: true, expiration: 28800 });
      // console.log(flightUrl);
      var texasPipesJson = ''
      fetch(texasPipesUrl)
        .then(res => res.json())
        .then(out => {
          // console.log(out)
          // flightLineJson = out
          map.current.addSource('texasPipesSource', {
            type: 'geojson',
            // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
            data: out,
            // filter: ["==", "time", '00'],
            // cluster: true,
            // clusterRadius: 50,
            // clusterMaxZoom: 17, // Max zoom to cluster points on
          });

          map.current.addLayer({
            'id': 'texasPipesLayer',
            'type': 'line',
            'source': 'texasPipesSource',
            'layout': {
              'line-join': 'round',
              'line-cap': 'round'
            },
            'paint': {
              'line-color': '#ffff00',
              'line-width': 2
            }
          })
        })
        .catch(err => { throw err });

      await new Promise(r => setTimeout(r, 500));
      const sentinel2aKey = 'satellites/sentinel-2a/2024/5/sentinel2a_20240503.json'
      // const flightLineKey = 'flight_line.json'
      const sentinel2aUrl = await Storage.get(sentinel2aKey, { useAccelerateEndpoint: true, expiration: 28800 });
      // console.log(flightUrl);
      var sentinel2aJson = ''
      fetch(sentinel2aUrl)
        .then(res => res.json())
        .then(out => {
          sentinel2aJson = out
          map.current.addSource('sentinel2aSource', {
            type: 'geojson',
            // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
            data: sentinel2aJson,
            // filter: ["==", "time", '00'],
            // cluster: true,
            // clusterRadius: 50,
            // clusterMaxZoom: 17, // Max zoom to cluster points on
          });

          map.current.addLayer({
            'id': 'sentinel2aLayer',
            'type': 'circle',
            'source': 'sentinel2aSource',
            'layout': {
              // 'line-join': 'round',
              // 'line-cap': 'round',
              'visibility':'none'
            },
            'paint': {
              'circle-color': '#4266f5',
              'circle-stroke-width': 1
              // 'line-width': 8
            }
          })

          // map.current.on('click', 'sentinel5pLayer', (e) => {
          //   const coordinates = e.features[0].geometry.coordinates.slice();
          //   // const id = e.features[0].properties.id;
          //   // const index = e.features[0].properties.index;
          //   // const stats = JSON.parse(e.features[0].properties.stats);
          //   // console.log(stats)
          //   // const site = e.features[0].properties.site;
          //   // <b>Site:</b> ${site}<br />
          //   // <b>Name:</b> sensor-${index.toString().padStart(2, '0')} <br />
          //   var text = `
          //         <b>Satellite:</b> Sentinel 5-P <br />
          //         <b>LAT:</b> ${coordinates[1].toFixed(5)}<br />
          //       <b>LON:</b> ${coordinates[0].toFixed(5)} <br />
          //         `

          //   // const mag = e.features[0].properties.mag;
          //   // const tsunami =
          //   // e.features[0].properties.tsunami === 1 ? 'yes' : 'no';

          //   // Ensure that if the map is zoomed out such that
          //   // multiple copies of the feature are visible, the
          //   // popup appears over the copy being pointed to.
          //   while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          //     coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          //   }

          //   new maplibregl.Popup({ offset: 10, closeOnClick: true })
          //     .setLngLat(coordinates)
          //     // .trackPointer()
          //     .setHTML(
          //       text
          //     )
          //     .addTo(map.current);
          // });
        })
        .catch(err => { throw err });

        const sentinel2bKey = 'satellites/sentinel-2b/2024/5/sentinel2b_20240503.json'
      // const flightLineKey = 'flight_line.json'
      const sentinel2bUrl = await Storage.get(sentinel2bKey, { useAccelerateEndpoint: true, expiration: 28800 });
      // console.log(flightUrl);
      var sentinel2bJson = ''
      fetch(sentinel2bUrl)
        .then(res => res.json())
        .then(out => {
          sentinel2bJson = out
          map.current.addSource('sentinel2bSource', {
            type: 'geojson',
            // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
            data: sentinel2bJson,
            // filter: ["==", "time", '00'],
            // cluster: true,
            // clusterRadius: 50,
            // clusterMaxZoom: 17, // Max zoom to cluster points on
          });

          map.current.addLayer({
            'id': 'sentinel2bLayer',
            'type': 'circle',
            'source': 'sentinel2bSource',
            'layout': {
              // 'line-join': 'round',
              // 'line-cap': 'round',
              'visibility':'none'
            },
            'paint': {
              'circle-color': '#f542e6',
              'circle-stroke-width': 1
              // 'line-width': 8
            }
          })

          // map.current.on('click', 'sentinel5pLayer', (e) => {
          //   const coordinates = e.features[0].geometry.coordinates.slice();
          //   // const id = e.features[0].properties.id;
          //   // const index = e.features[0].properties.index;
          //   // const stats = JSON.parse(e.features[0].properties.stats);
          //   // console.log(stats)
          //   // const site = e.features[0].properties.site;
          //   // <b>Site:</b> ${site}<br />
          //   // <b>Name:</b> sensor-${index.toString().padStart(2, '0')} <br />
          //   var text = `
          //         <b>Satellite:</b> Sentinel 5-P <br />
          //         <b>LAT:</b> ${coordinates[1].toFixed(5)}<br />
          //       <b>LON:</b> ${coordinates[0].toFixed(5)} <br />
          //         `

          //   // const mag = e.features[0].properties.mag;
          //   // const tsunami =
          //   // e.features[0].properties.tsunami === 1 ? 'yes' : 'no';

          //   // Ensure that if the map is zoomed out such that
          //   // multiple copies of the feature are visible, the
          //   // popup appears over the copy being pointed to.
          //   while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          //     coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          //   }

          //   new maplibregl.Popup({ offset: 10, closeOnClick: true })
          //     .setLngLat(coordinates)
          //     // .trackPointer()
          //     .setHTML(
          //       text
          //     )
          //     .addTo(map.current);
          // });
        })
        .catch(err => { throw err });

      const sentinel5pKey = 'satellites/sentinel-5p/2024/5/sentinel5p_20240503.json'
      // const flightLineKey = 'flight_line.json'
      const sentinel5pUrl = await Storage.get(sentinel5pKey, { useAccelerateEndpoint: true, expiration: 28800 });
      // console.log(flightUrl);
      var sentinel5pJson = ''
      fetch(sentinel5pUrl)
        .then(res => res.json())
        .then(out => {
          sentinel5pJson = out
          map.current.addSource('sentinel5pSource', {
            type: 'geojson',
            // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
            data: sentinel5pJson,
            // filter: ["==", "time", '00'],
            // cluster: true,
            // clusterRadius: 50,
            // clusterMaxZoom: 17, // Max zoom to cluster points on
          });

          map.current.addLayer({
            'id': 'sentinel5pLayer',
            'type': 'circle',
            'source': 'sentinel5pSource',
            'layout': {
              // 'line-join': 'round',
              // 'line-cap': 'round',
              'visibility':'none'
            },
            'paint': {
              'circle-color': '#ffa500',
              // 'circle-stroke-color': 'ffffff',
              'circle-stroke-width': 1
              // 'line-width': 8
            }
          })

          map.current.on('click', 'sentinel5pLayer', (e) => {
            const coordinates = e.features[0].geometry.coordinates.slice();
            // const id = e.features[0].properties.id;
            // const index = e.features[0].properties.index;
            // const stats = JSON.parse(e.features[0].properties.stats);
            // console.log(stats)
            // const site = e.features[0].properties.site;
            // <b>Site:</b> ${site}<br />
            // <b>Name:</b> sensor-${index.toString().padStart(2, '0')} <br />
            var text = `
                  <b>Satellite:</b> Sentinel 5-P <br />
                  <b>LAT:</b> ${coordinates[1].toFixed(5)}<br />
                <b>LON:</b> ${coordinates[0].toFixed(5)} <br />
                  `

            // const mag = e.features[0].properties.mag;
            // const tsunami =
            // e.features[0].properties.tsunami === 1 ? 'yes' : 'no';

            // Ensure that if the map is zoomed out such that
            // multiple copies of the feature are visible, the
            // popup appears over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            new maplibregl.Popup({ offset: 10, closeOnClick: true })
              .setLngLat(coordinates)
              // .trackPointer()
              .setHTML(
                text
              )
              .addTo(map.current);
          });
        })
        .catch(err => { throw err });


      const flightLineKey = 'flight_line-20240430.json'
      // const flightLineKey = 'flight_line.json'
      const flightLineUrl = await Storage.get(flightLineKey, { useAccelerateEndpoint: true, expiration: 28800 });
      // console.log(flightUrl);
      var flightLineJson = ''
      fetch(flightLineUrl)
        .then(res => res.json())
        .then(out => {
          flightLineJson = out
          map.current.addSource('flightLineSource', {
            type: 'geojson',
            // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
            data: flightLineJson,
            // filter: ["==", "time", '00'],
            // cluster: true,
            // clusterRadius: 50,
            // clusterMaxZoom: 17, // Max zoom to cluster points on
          });

          map.current.addLayer({
            'id': 'flightLineLayer',
            'type': 'line',
            'source': 'flightLineSource',
            'layout': {
              'line-join': 'round',
              'line-cap': 'round',
              // 'visibility':'none'
            },
            'paint': {
              'line-color': '#34ebae',
              'line-width': 8
            }
          })
        })
        .catch(err => { throw err });

        // const flightLineKey = 'flight_line-20240430.json'
        const flightLine2Key = 'flight_line.json'
        const flightLine2Url = await Storage.get(flightLine2Key, { useAccelerateEndpoint: true, expiration: 28800 });
        // console.log(flightUrl);
        var flightLine2Json = ''
        fetch(flightLine2Url)
          .then(res => res.json())
          .then(out => {
            flightLine2Json = out
            map.current.addSource('flightLine2Source', {
              type: 'geojson',
              // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
              data: flightLine2Json,
              // filter: ["==", "time", '00'],
              // cluster: true,
              // clusterRadius: 50,
              // clusterMaxZoom: 17, // Max zoom to cluster points on
            });
  
            map.current.addLayer({
              'id': 'flightLine2Layer',
              'type': 'line',
              'source': 'flightLine2Source',
              'layout': {
                'line-join': 'round',
                'line-cap': 'round',
                'visibility':'none'
              },
              'paint': {
                'line-color': '#34ebae',
                'line-width': 8
              }
            })
          })
          .catch(err => { throw err });

      // const soofieKey = 'soofie-locations.json'
      let soofieDateYear = dateFilter.slice(0, 4)
      let soofieDateMonth = parseInt(dateFilter.slice(5, 7)).toString()
      let soofieDateDay = parseInt(dateFilter.slice(8)).toString()
      const soofieKey = 'soofie/' + soofieDateYear + '/' + soofieDateMonth + '/' + soofieDateDay + '/methane-summary.json'
      // const soofieKey = 'methane-summary.json'
      const soofieUrl = await Storage.get(soofieKey, { useAccelerateEndpoint: true, expiration: 28800 });
      // console.log(soofieUrl)
      await new Promise(r => setTimeout(r, 1000));
      // console.log(soofieUrl)
      var soofieJson = ''
      fetch(soofieUrl)
        .then(res => res.json())
        .then(out => {
          // console.log(out)
          soofieJson = out
          const soofielayer = {
            type: 'FeatureCollection',
            features:

              soofieJson.map((sensor, index) => (
                {
                  type: "Feature",
                  properties:
                  {
                    id: sensor['name'],
                    stats: sensor['stats'],
                    index: index,
                    // site: sensor['site']
                  },
                  geometry:
                  {
                    type: "Point",
                    coordinates:
                      [
                        sensor['lon'] + soofieOffsetLon,
                        sensor['lat'] + soofieOffsetLat
                      ]
                  }
                }
              ))
            // }
          };

          map.current.addSource('soofieSource', {
            type: 'geojson',
            // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
            data: soofielayer,
            cluster: true,
            clusterRadius: 50,
            clusterMaxZoom: 17, // Max zoom to cluster points on
          });



          const devLayer = map.current.addLayer({
            id: 'soofieClustersLayer',
            type: 'circle',
            source: 'soofieSource',
            // minzoom: 6,
            filter: ['has', 'point_count'],
            paint: {
              // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
              // with three steps to implement three types of circles:
              //   * Blue, 20px circles when point count is less than 100
              //   * Yellow, 30px circles when point count is between 100 and 750
              //   * Pink, 40px circles when point count is greater than or equal to 750
              'circle-color': [
                'step',
                ['get', 'point_count'],
                '#abf5f2',
                5,
                '#abf5f2',
                20,
                '#abf5f2'
              ],
              'circle-stroke-width': [
                'step',
                ['get', 'point_count'],
                2,
                5,
                2,
                20,
                2
              ],
              'circle-stroke-color': [
                'step',
                ['get', 'point_count'],
                '#ff0000',
                5,
                '#ff0000',
                20,
                '#ff0000'
              ],
              'circle-radius': [
                'step',
                ['get', 'point_count'],
                10,
                5,
                15,
                20,
                20
              ]
            }
          });

          // console.log(map)

          map.current.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'soofieSource',
            filter: ['has', 'point_count'],
            layout: {
              'text-field': '{point_count_abbreviated}',
              'text-font': ['literal', ['Fira GO Regular']],
              'text-size': 12
            }
          });


          map.current.addLayer({
            id: 'unclustered-soofie',
            type: 'circle',
            source: 'soofieSource',
            filter: ['!', ['has', 'point_count']],
            paint: {
              'circle-color': '#abf5f2',
              'circle-radius': 9,
              'circle-stroke-width': 1,
              'circle-stroke-color': '#ff0000'
            }
          });

          map.current.on('click', 'unclustered-soofie', (e) => {
            const coordinates = e.features[0].geometry.coordinates.slice();
            const id = e.features[0].properties.id;
            const index = e.features[0].properties.index;
            const stats = JSON.parse(e.features[0].properties.stats);
            // console.log(stats)
            // const site = e.features[0].properties.site;
            // <b>Site:</b> ${site}<br />
            var text = `
                  <b>Name:</b> sensor-${index.toString().padStart(2, '0')} <br />
                  <b>Max methane:</b> ${stats['max'].toFixed(2)} ppm <br />
                  <b>Avg methane:</b> ${stats['avg'].toFixed(2)} ppm <br />
                  <b>Samples:</b> ${stats['count']} <br />

                  <b>LAT:</b> ${coordinates[1].toFixed(5)}<br />
                <b>LON:</b> ${coordinates[0].toFixed(5)} <br />
                  `

            // const mag = e.features[0].properties.mag;
            // const tsunami =
            // e.features[0].properties.tsunami === 1 ? 'yes' : 'no';

            // Ensure that if the map is zoomed out such that
            // multiple copies of the feature are visible, the
            // popup appears over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            new maplibregl.Popup({ offset: 10, closeOnClick: true })
              .setLngLat(coordinates)
              // .trackPointer()
              .setHTML(
                text
              )
              .addTo(map.current);
          });

          map.current.on('click', 'soofieClustersLayer', (e) => {
            // console.log(e)
            const features = map.current.queryRenderedFeatures(e.point, {
              layers: ['soofieClustersLayer']
            });
            const clusterId = features[0].properties.cluster_id;
            map.current.getSource('soofieSource').getClusterExpansionZoom(
              clusterId,
              (err, zoom) => {
                if (err) return;

                map.current.easeTo({
                  center: features[0].geometry.coordinates,
                  zoom: zoom
                });
              }
            );
          });
        })
        .catch(err => { throw err });

      // const siteKey = 'site-locations.json'
      const siteKey = 'soofie/exxon_data/soofie_sites_Confidential.json'
      const siteUrl = await Storage.get(siteKey, { useAccelerateEndpoint: true, expires: 28800 });

      // console.log(soofieUrl)
      var siteJson = ''
      fetch(siteUrl)
        .then(res => res.json())
        .then(out => {
          // console.log(out)
          setSiteOptions(out.map((site, index) => {
            // console.log(site)
            // return { label: site.name, value: site.name, position: { lng: site.longitude, lat: site.latitude, zoom: 17 } }

            return { label: 'Site-' + index.toString().padStart(2, '0'), value: site.name, position: { lng: site.longitude, lat: site.latitude, zoom: 17 } }
          }).sort(function (a, b) {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          })
          )
        })
        .catch(err => { throw err });


      // console.log(dateFilter)
      let dateYear = dateFilter.slice(0, 4)
      let dateMonth = parseInt(dateFilter.slice(5, 7)).toString()
      let dateDay = parseInt(dateFilter.slice(8)).toString()
      const measurementsKey = 'soofie/' + dateYear + '/' + dateMonth + '/' + dateDay + '/Methane.json'
      const measurementsUrl = await Storage.get(measurementsKey);

      // const measurementsKey = 'measurements.json'
      // const measurementsUrl = await Storage.get(measurementsKey);

      // console.log(measurementsUrl)

      var measurementJson = ''
      fetch(measurementsUrl)
        .then(res => res.json())
        .then(out => {
          measurementJson = out
          let visTpList = []
          // const devs = DataStore.query(RecentSource);
          const visDevs = Object.keys(measurementJson.reduce((r, { name }) => (r[name] = '', r), {}))
          // console.log(visDevs)
          // console.log(devs)
          // console.log(filterState['devices'])

          for (let i = 0; i < visDevs.length; i++) {


            var subset = measurementJson.filter(function (tp) {
              return tp['name'] == visDevs[i];
            });

            if (subset.length > 0) {
              // console.log(subset)

              let deviceData = {
                // title: visDevs[i],
                title: 'sensor-' + i.toString().padStart(2, '0'),
                type: "line",
                data: subset.filter(function (tp) {
                  // if (tp['value'].toString().length > 6) {
                  //                         console.log('skipped')
                  //                         return false; // skip
                  //                       } else 
                  if (tp['time'].includes(':00:00Z')) {
                    return false;
                  }
                  return true;
                }).map((tp, index) => (

                  {
                    x: new Date(tp['time']),
                    y: parseFloat(tp['value'])
                  }
                )
                ).sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return b.x - a.x;
                })
              }

              // console.log(deviceData)

              visTpList.push(deviceData)
            }
          }

          // console.log(visTpList)

          visTpList.sort(function (a, b) {
            if (a.title < b.title) {
              return -1;
            }
            if (a.title > b.title) {
              return 1;
            }
            return 0;
          });

          // await new Promise(r => setTimeout(r, 10000));
          // console.log(flightLineJson)
          // console.log(flightJson)

          // await new Promise(r => setTimeout(r, 10000));

          const measurementTableData = measurementJson.map((tp, index) => (
            {
              // name: tp['name'],
              name: 'sensor-' + index.toString().padStart(2, '0'),
              value: tp['value'].toFixed(2),
              lat: tp['lat'] + soofieOffsetLat,
              lon: tp['lon'] + soofieOffsetLon,
              time: (new Date(tp['time'])).toLocaleString("en-US", {
                timeZone: timeZoneValue.value,
                timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                minute: '2-digit'
              }),

              link: <Link onFollow={() => {
                console.log(selectedMeasurement)
                // setSelectedMeasurement([
                //   {
                //     name: tp['name'],
                //     value: tp['value'].toFixed(2),
                //     time: (new Date(tp['time'])).toLocaleString("en-US", {
                //       timeZone: timeZoneValue.value,
                //       timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                //       minute: '2-digit'
                //     }),
                //     // sensor-{visDevs.indexOf(tp['name']).toString().padStart(2, '0')}
                //     link: <Link onFollow={() => {
                //       map.current.flyTo({
                //         center: [
                //           tp['lon'] + soofieOffsetLon,
                //           tp['lat'] + soofieOffsetLat
                //         ],
                //         zoom: 17
                //         // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                //       })
                //     }}>
                //       {tp['name']}
                //     </Link>
                //   }
                // ])
                map.current.flyTo({
                  center: [
                    tp['lon'] + soofieOffsetLon,
                    tp['lat'] + soofieOffsetLat
                  ],
                  zoom: 17
                  // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })

              }}>
                sensor-{visDevs.indexOf(tp['name']).toString().padStart(2, '0')}

              </Link>
            }
          )
          ).sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return b.value - a.value;
          })



          setFilterState(
            {
              measurementFilterState: visTpList,
              measurementSeries: visTpList,
              measurementTableData: measurementTableData,
              measurementLoading: 'finished'
            }
          )
        })
        .catch(err => { throw err });



      let tropomiDateYear = dateFilter.slice(0, 4)
      let tropomiDateMonth = dateFilter.slice(5, 7)
      let tropomiDateDay = dateFilter.slice(8)
      let tropomiPrefix = 'tropomi/' + tropomiDateYear + '/' + tropomiDateMonth + '/' + tropomiDateDay + '/'

      const tropomiUrls = Storage.list(tropomiPrefix) // for listing ALL files without prefix, pass '' instead
        .then(({ results }) => {
          // console.log(results)
          var tropomiIndex = 0
          let tropomiCardsTemp = []
          for (let index = 0; index < results.length; index++) {
            if (results[index]['key'].endsWith('ratio_4326.tif')) {
              // console.log(results[index]['key'])
              const url = Storage.get(results[index]['key'], { useAccelerateEndpoint: true, expiration: 28800 }).then(function (result) {
                // console.log(tropomiIndex)
                map.current.addSource('tropomi' + index.toString(), {
                  'type': 'raster',
                  'tiles': [
                    "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?&rescale=1800%2C2400&colormap_name=ylorrd&return_mask=true&url=" + encodeURIComponent(result)
                  ],
                });
                map.current.addLayer(
                  {
                    'id': 'tropomi' + index.toString(), // Layer ID
                    'type': 'raster',
                    'source': 'tropomi' + index.toString(), // ID of the tile source created above
                    'paint': {
                      'raster-fade-duration': 0,
                      'raster-opacity': 0.6
                      // 'raster-opacity': 0.2
                    }
                  },
                );

                let stats = JSON.parse(Get('https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/statistics?url=' + encodeURIComponent(result)))
                let card = {
                  name: "TROPOMI " + index.toString(),
                  alt: "TROPOMI " + index.toString(),
                  maximum: stats['b1']['max'].toFixed(2).toString() + " ppb",
                  minimum: stats['b1']['min'].toFixed(2).toString() + " ppb",
                  median: stats['b1']['median'].toFixed(2).toString() + " ppb",
                  mean: stats['b1']['mean'].toFixed(2).toString() + " ppb",
                  std: stats['b1']['std'].toFixed(2).toString() + " ppb",
                  preview: <img width="100%" src={'https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/preview?rescale=1800%2C2400&colormap_name=ylorrd&return_mask=true&url=' + encodeURIComponent(result)}></img>
                }
                tropomiCardsTemp.push(card)
              })

            }
          }
          setTropomiCards(tropomiCardsTemp)
        })



        .catch((err) => console.log(err));
      // const tropomiUrls = Storage.list(tropomiPrefix) // for listing ALL files without prefix, pass '' instead
      //   .then(({ results }) => {
      //     // console.log(results)
      //     let tropomiIndex = 0
      //     let tropomiCardsTemp = []
      //     for (let index = 0; index < 100; index++) {
      //       try {
      //         map.current.removeLayer('tropomi' + index.toString())
      //         map.current.removeSource('tropomi' + index.toString())
      //       } catch (error) {

      //       }

      //     }
      //     for (let index = 0; index < 14; index++) {
      //       // for (let index = 0; index < results.length; index++) {

      //       if (results[index]['key'].endsWith('ratio_4326.tif')) {
      //         console.log(results[index]['key'])
      //         const url = Storage.get(results[index]['key']).then(function (result) {
      //           map.current.addSource('tropomi' + tropomiIndex.toString(), {
      //             'type': 'raster',
      //             'tiles': [
      //               "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?&rescale=1800%2C2400&colormap_name=reds&return_mask=true&url=" + encodeURIComponent(result)
      //             ],
      //           });
      //           map.current.addLayer(
      //             {
      //               'id': 'tropomi' + tropomiIndex.toString(), // Layer ID
      //               'type': 'raster',
      //               'source': 'tropomi' + tropomiIndex.toString(), // ID of the tile source created above
      //               'paint': {
      //                 'raster-fade-duration': 0,
      //                 'raster-opacity': 0.6
      //                 // 'raster-opacity': 0.2
      //               }
      //             },
      //           );
      //           let card = {
      //             name: "TROPOMI " + tropomiIndex.toString(),
      //             alt: "TROPOMI " + tropomiIndex.toString(),
      //             maximum: "2.001 ppm",
      //             minimum: "1.563 ppm",
      //             median: "1.857 ppm",
      //             mean: "1.852 ppm",
      //             std: "0.047 ppm",
      //             preview: <img width="400" src={'https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/preview?rescale=1800%2C2400&colormap_name=reds&return_mask=true&url=' + encodeURIComponent(result)}></img>
      //           }
      //           tropomiCardsTemp.push(card)
      //         })
      //         tropomiIndex = tropomiIndex + 1
      //       }
      //     }
      //     setTropomiCards(tropomiCardsTemp)
      //     return results
      //   })
      //   .catch((err) => console.log(err));



      // for (let index = 0; index < tableData.length; index++) {
      //   const element = tableData[index];

      //   var text = `     
      //             <b>Time:</b> ${element['date']} <br />
      //             <b>Rate:</b> ${element['rate'].toFixed(2)} kg/hr <br />
      //             <b>Lat:</b> ${element['lat'].toFixed(5)} deg<br />
      //             <b>Lon:</b> ${element['lon'].toFixed(5)} deg<br />
      //             `

      //   // const mag = e.features[0].properties.mag;
      //   // const tsunami =
      //   // e.features[0].properties.tsunami === 1 ? 'yes' : 'no';

      //   // Ensure that if the map is zoomed out such that
      //   // multiple copies of the feature are visible, the
      //   // popup appears over the copy being pointed to.
      //   // while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      //   //   coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      //   // }
      //   new maplibregl.Popup({ offset: 10, closeOnClick: true })
      //     .setLngLat([element['lon'], element['lat']])
      //     // .trackPointer()
      //     .setHTML(
      //       text
      //     )
      //     // .isOpen(popupOpen[element['time']])
      //     .addTo(map.current);

      // }

      await new Promise(r => setTimeout(r, 1000));



      const msiLayer2 = 'msi_layers/2023-11-21.tif';
      const signedMsiLayer2 = await Storage.get(msiLayer2, { useAccelerateEndpoint: true, expiration: 28800 })
        .then(out => {
          // console.log(out);

          map.current.addSource('msi2', {
            'type': 'raster',
            'tiles': [
              "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?return_mask=true&rescale=-0.07%2C0&colormap_name=plasma_r&url=" + encodeURIComponent(out)
            ],
            'tileSize': 64,
            'minZoom': 12,
            'maxZoom': 13,
            'bounds': [
              -101.90006871009459,
              32.40549422284675,
              -101.6999085272571,
              32.46451218600251
            ]
          });
          map.current.addLayer(
            {
              'id': '2023-11-21', // Layer ID
              'type': 'raster',
              'source': 'msi2', // ID of the tile source created above
              // 'layout': {
              //   'visibility': 'none'
              // },
              'paint': {
                'raster-fade-duration': 0,
                'raster-opacity': 1
                // 'raster-opacity': 0.2
              }
            },
          );
        });

      const msiLayer3 = 'msi_layers/2023-11-23.tif';
      const signedMsiLayer3 = await Storage.get(msiLayer3, { useAccelerateEndpoint: true, expiration: 28800 })
        .then(out => {
          // console.log(out);

          map.current.addSource('msi3', {
            'type': 'raster',
            'tiles': [
              "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?return_mask=true&rescale=-0.07%2C0&colormap_name=plasma_r&url=" + encodeURIComponent(out)
            ],
            'tileSize': 64,
            'minZoom': 12,
            'maxZoom': 13,
            'bounds': [
              -101.90006871009459,
              32.40549422284675,
              -101.6999085272571,
              32.46451218600251
            ]
          });
          map.current.addLayer(
            {
              'id': '2023-11-23', // Layer ID
              'type': 'raster',
              'source': 'msi3', // ID of the tile source created above
              // 'layout': {
              //   'visibility': 'none'
              // },
              'paint': {
                'raster-fade-duration': 0,
                'raster-opacity': 1
                // 'raster-opacity': 0.2
              }
            },
          );
        });

      const msiLayer4 = 'msi_layers/2023-12-11.tif';
      const signedMsiLayer4 = await Storage.get(msiLayer4, { useAccelerateEndpoint: true, expiration: 28800 })
        .then(out => {
          // console.log(out);

          map.current.addSource('msi4', {
            'type': 'raster',
            'tiles': [
              "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?return_mask=true&rescale=-0.07%2C0&colormap_name=plasma_r&url=" + encodeURIComponent(out)
            ],
            'tileSize': 64,
            'minZoom': 12,
            'maxZoom': 13,
            'bounds': [
              -101.90006871009459,
              32.40549422284675,
              -101.6999085272571,
              32.46451218600251
            ]
          });
          map.current.addLayer(
            {
              'id': '2023-12-11', // Layer ID
              'type': 'raster',
              'source': 'msi4', // ID of the tile source created above
              // 'layout': {
              //   'visibility': 'none'
              // },
              'paint': {
                'raster-fade-duration': 0,
                'raster-opacity': 1
                // 'raster-opacity': 0.2
              }
            },
          );
        });
      
      // const balloonUrl = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023292161430000_e2023292161629000_c2023347060911852.corr_R004.tif'
      // const signedBalloonUrl = await Storage.get(balloonUrl, { useAccelerateEndpoint: true, expiration: 28800 });
      // // console.log(signedBalloonUrl)

      // const balloonUrl2 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023292163731000_e2023292163920000_c2023347080326174.corr_R004.tif'
      // const signedBalloonUrl2 = await Storage.get(balloonUrl2, { useAccelerateEndpoint: true, expiration: 28800 });
      // // console.log(signedBalloonUrl2)

      // const balloonUrl3 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023292203024000_e2023292203224000_c2023348064456379.corr_R004.tif'
      // const signedBalloonUrl3 = await Storage.get(balloonUrl3, { useAccelerateEndpoint: true, expiration: 28800 });

      // const balloonUrl4 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293154928000_e2023293155128000_c2023346225706667.corr_R004.tif'
      // const signedBalloonUrl4 = await Storage.get(balloonUrl4, { useAccelerateEndpoint: true, expiration: 28800 });

      // const balloonUrl5 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293155128000_e2023293155328000_c2023346230934079.corr_R004.tif'
      // const signedBalloonUrl5 = await Storage.get(balloonUrl5, { useAccelerateEndpoint: true, expiration: 28800 });

      // const balloonUrl6 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293155707000_e2023293155857000_c2023346234014485.corr_R004.tif'
      // const signedBalloonUrl6 = await Storage.get(balloonUrl6, { useAccelerateEndpoint: true, expiration: 28800 });

      // const balloonUrl7 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293160058000_e2023293160258000_c2023346235946816.corr_R004.tif'
      // const signedBalloonUrl7 = await Storage.get(balloonUrl7, { useAccelerateEndpoint: true, expiration: 28800 });

      // const balloonUrl8 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293162459000_e2023293162659000_c2023347020259277.corr_R004.tif'
      // const signedBalloonUrl8 = await Storage.get(balloonUrl8, { useAccelerateEndpoint: true, expiration: 28800 });

      // const balloonUrl9 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293165629000_e2023293165829000_c2023347044644047.corr_R004.tif'
      // const signedBalloonUrl9 = await Storage.get(balloonUrl9, { useAccelerateEndpoint: true, expiration: 28800 });

      // const balloonUrl10 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293170513000_e2023293170713000_c2023347053251420.corr_R004.tif'
      // const signedBalloonUrl10 = await Storage.get(balloonUrl10, { useAccelerateEndpoint: true, expiration: 28800 });

      // const balloonUrl11 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293172914000_e2023293173114000_c2023347074234047.corr_R004.tif'
      // const signedBalloonUrl11 = await Storage.get(balloonUrl11, { useAccelerateEndpoint: true, expiration: 28800 });

      // const balloonUrl12 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293184333000_e2023293184533000_c2023347134949889.corr_R004.tif'
      // const signedBalloonUrl12 = await Storage.get(balloonUrl12, { useAccelerateEndpoint: true, expiration: 28800 });

      // const balloonUrl13 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293184933000_e2023293185056000_c2023347142101528.corr_R004.tif'
      // const signedBalloonUrl13 = await Storage.get(balloonUrl13, { useAccelerateEndpoint: true, expiration: 28800 });

      // const balloonUrl14 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293191301000_e2023293191501000_c2023347162651403.corr_R004.tif'
      // const signedBalloonUrl14 = await Storage.get(balloonUrl14, { useAccelerateEndpoint: true, expiration: 28800 });

      // const balloonUrl15 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293191501000_e2023293191701000_c2023347163743939.corr_R004.tif'
      // const signedBalloonUrl15 = await Storage.get(balloonUrl15, { useAccelerateEndpoint: true, expiration: 28800 });

      // const balloonUrl16 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293191701000_e2023293191901000_c2023347164845355.corr_R004.tif'
      // const signedBalloonUrl16 = await Storage.get(balloonUrl16, { useAccelerateEndpoint: true, expiration: 28800 });

      // const balloonUrl17 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293192101000_e2023293192225000_c2023347171041238.corr_R004.tif'
      // const signedBalloonUrl17 = await Storage.get(balloonUrl17, { useAccelerateEndpoint: true, expiration: 28800 });

      // const balloonUrl18 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293194231000_e2023293194431000_c2023347190821037.corr_R004.tif'
      // const signedBalloonUrl18 = await Storage.get(balloonUrl18, { useAccelerateEndpoint: true, expiration: 28800 });

      // const balloonUrl19 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293194831000_e2023293195031000_c2023347194102457.corr_R004.tif'
      // const signedBalloonUrl19 = await Storage.get(balloonUrl19, { useAccelerateEndpoint: true, expiration: 28800 });

      // const balloonUrl20 = 'balloon/plumes/plume_DE_DAEDALUS-002_MethaneL2_s2023293201203000_e2023293201403000_c2023347221003180.corr_R004.tif'
      // const signedBalloonUrl20 = await Storage.get(balloonUrl20, { useAccelerateEndpoint: true, expiration: 28800 });

      // const samplePlumeUrl = 'balloon/plumes/sample_plume.tif'
      // const signedBalloonUrl21 = await Storage.get(samplePlumeUrl, { useAccelerateEndpoint: true, expiration: 28800 });


      const balloonUrl = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023292161430000_e2023292161629000_c2023347060911852.corr_R004.tif'
      const signedBalloonUrl = await Storage.get(balloonUrl, { useAccelerateEndpoint: true, expiration: 28800 });
      // console.log(signedBalloonUrl)

      const balloonUrl2 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023292163731000_e2023292163920000_c2023347080326174.corr_R004.tif'
      const signedBalloonUrl2 = await Storage.get(balloonUrl2, { useAccelerateEndpoint: true, expiration: 28800 });
      // console.log(signedBalloonUrl2)

      const balloonUrl3 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023292203024000_e2023292203224000_c2023348064456379.corr_R004.tif'
      const signedBalloonUrl3 = await Storage.get(balloonUrl3, { useAccelerateEndpoint: true, expiration: 28800 });

      const balloonUrl4 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293154928000_e2023293155128000_c2023346225706667.corr_R004.tif'
      const signedBalloonUrl4 = await Storage.get(balloonUrl4, { useAccelerateEndpoint: true, expiration: 28800 });

      const balloonUrl5 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293155128000_e2023293155328000_c2023346230934079.corr_R004.tif'
      const signedBalloonUrl5 = await Storage.get(balloonUrl5, { useAccelerateEndpoint: true, expiration: 28800 });

      const balloonUrl6 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293155707000_e2023293155857000_c2023346234014485.corr_R004.tif'
      const signedBalloonUrl6 = await Storage.get(balloonUrl6, { useAccelerateEndpoint: true, expiration: 28800 });

      const balloonUrl7 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293160058000_e2023293160258000_c2023346235946816.corr_R004.tif'
      const signedBalloonUrl7 = await Storage.get(balloonUrl7, { useAccelerateEndpoint: true, expiration: 28800 });

      const balloonUrl8 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293162459000_e2023293162659000_c2023347020259277.corr_R004.tif'
      const signedBalloonUrl8 = await Storage.get(balloonUrl8, { useAccelerateEndpoint: true, expiration: 28800 });

      const balloonUrl9 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293165629000_e2023293165829000_c2023347044644047.corr_R004.tif'
      const signedBalloonUrl9 = await Storage.get(balloonUrl9, { useAccelerateEndpoint: true, expiration: 28800 });

      const balloonUrl10 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293170513000_e2023293170713000_c2023347053251420.corr_R004.tif'
      const signedBalloonUrl10 = await Storage.get(balloonUrl10, { useAccelerateEndpoint: true, expiration: 28800 });

      const balloonUrl11 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293172914000_e2023293173114000_c2023347074234047.corr_R004.tif'
      const signedBalloonUrl11 = await Storage.get(balloonUrl11, { useAccelerateEndpoint: true, expiration: 28800 });

      const balloonUrl12 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293184333000_e2023293184533000_c2023347134949889.corr_R004.tif'
      const signedBalloonUrl12 = await Storage.get(balloonUrl12, { useAccelerateEndpoint: true, expiration: 28800 });

      const balloonUrl13 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293184933000_e2023293185056000_c2023347142101528.corr_R004.tif'
      const signedBalloonUrl13 = await Storage.get(balloonUrl13, { useAccelerateEndpoint: true, expiration: 28800 });

      const balloonUrl14 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293191301000_e2023293191501000_c2023347162651403.corr_R004.tif'
      const signedBalloonUrl14 = await Storage.get(balloonUrl14, { useAccelerateEndpoint: true, expiration: 28800 });

      const balloonUrl15 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293191501000_e2023293191701000_c2023347163743939.corr_R004.tif'
      const signedBalloonUrl15 = await Storage.get(balloonUrl15, { useAccelerateEndpoint: true, expiration: 28800 });

      const balloonUrl16 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293191701000_e2023293191901000_c2023347164845355.corr_R004.tif'
      const signedBalloonUrl16 = await Storage.get(balloonUrl16, { useAccelerateEndpoint: true, expiration: 28800 });

      const balloonUrl17 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293192101000_e2023293192225000_c2023347171041238.corr_R004.tif'
      const signedBalloonUrl17 = await Storage.get(balloonUrl17, { useAccelerateEndpoint: true, expiration: 28800 });

      const balloonUrl18 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293194231000_e2023293194431000_c2023347190821037.corr_R004.tif'
      const signedBalloonUrl18 = await Storage.get(balloonUrl18, { useAccelerateEndpoint: true, expiration: 28800 });

      const balloonUrl19 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293194831000_e2023293195031000_c2023347194102457.corr_R004.tif'
      const signedBalloonUrl19 = await Storage.get(balloonUrl19, { useAccelerateEndpoint: true, expiration: 28800 });

      const balloonUrl20 = 'balloon/demo/plume_DE_DAEDALUS-002_MethaneL2_s2023293201203000_e2023293201403000_c2023347221003180.corr_R004.tif'
      const signedBalloonUrl20 = await Storage.get(balloonUrl20, { useAccelerateEndpoint: true, expiration: 28800 });

      const samplePlumeUrl = 'balloon/demo/sample_plume.tif'
      const signedBalloonUrl21 = await Storage.get(samplePlumeUrl, { useAccelerateEndpoint: true, expiration: 28800 });

      await new Promise(r => setTimeout(r, 1000));

      // title: visDevs[i],
      //       type: "line",
      //       data: subset.map((tp, index) => (
      //         {
      //           x: new Date(tp['time']),
      //           y: parseFloat(tp['value'])
      //         }
      //       )



      // const sentinelUrl = 'ffa82a6d-9716-4958-8ad7-ef9995c16aa5_0_b12_tg.tif'
      // const signedSentinelUrl = await Storage.get(sentinelUrl);
      // // console.log(signedSentinelUrl)

      // // wind stuff
      // // const signedURL01 = await Storage.get('2023-09-21T01:07:37.322889.json');

      // const signedURL01 = await Storage.get('2023-09-21T02:30:19.364189.json');
      // const signedURL02 = await Storage.get('2023-09-21T03:30:18.912505.json');



      function Get(yourUrl) {
        var Httpreq = new XMLHttpRequest(); // a new request
        Httpreq.open("GET", yourUrl, false);
        Httpreq.send(null);
        return Httpreq.responseText;
      }



      const apiName = 'scepteruimfarestapi';
      const path = '/ddb';





      // await new Promise(r => setTimeout(r, 5000));







      // await new Promise(r => setTimeout(r, 1000));

      // map.current.on('load', () => {
      map.current.addSource('balloon1', {
        'type': 'raster',
        'tiles': [
          "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?return_mask=true&rescale=9%2C10&colormap_name=plasma&url=" + encodeURIComponent(signedBalloonUrl)
        ],
        'tileSize': 64,
        'minZoom': 12,
        'maxZoom': 13,
        'bounds': [
          -104.17535919160112 + soofieOffsetLon,
          32.13120701957594 + soofieOffsetLat,
          -104.05629795862964 + soofieOffsetLon,
          32.24963514425541 + soofieOffsetLat
        ]
      });
      map.current.addLayer(
        {
          'id': 'balloon1', // Layer ID
          'type': 'raster',
          'source': 'balloon1', // ID of the tile source created above
          'layout': {
            'visibility': 'none'
          },
          'paint': {
            'raster-fade-duration': 0,
            'raster-opacity': 1
            // 'raster-opacity': 0.2
          }
        },
      );
      // const arrowURL = await Storage.get('whitearrow.png');
      // // console.log(arrowURL)
      try {


        // map.current.addSource('tropomi13', {
        //   'type': 'raster',
        //   'tiles': [
        //     "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https://meeo-s5p.s3.eu-central-1.amazonaws.com/COGT/OFFL/L2__CH4___/2023/09/05/S5P_OFFL_L2__CH4____20230905T062551_20230905T080721_30544_03_020500_20230906T222728_PRODUCT_methane_mixing_ratio_4326.tif&rescale=1600%2C2200&colormap_name=plasma&return_mask=true"
        //   ],
        // });
        // map.current.addLayer(
        //   {
        //     'id': 'tropomi13', // Layer ID
        //     'type': 'raster',
        //     'source': 'tropomi13', // ID of the tile source created above
        //     'paint': {
        //       'raster-fade-duration': 0,
        //       'raster-opacity': 0.6
        //       // 'raster-opacity': 0.2
        //     }
        //   },
        // );

        // map.current.addSource('tropomi12', {
        //   'type': 'raster',
        //   'tiles': [
        //     "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https://meeo-s5p.s3.eu-central-1.amazonaws.com/COGT/OFFL/L2__CH4___/2023/09/05/S5P_OFFL_L2__CH4____20230905T044422_20230905T062551_30543_03_020500_20230906T205126_PRODUCT_methane_mixing_ratio_4326.tif&rescale=1600%2C2200&colormap_name=plasma&return_mask=true"
        //   ],
        // });
        // map.current.addLayer(
        //   {
        //     'id': 'tropomi12', // Layer ID
        //     'type': 'raster',
        //     'source': 'tropomi12', // ID of the tile source created above
        //     'paint': {
        //       'raster-fade-duration': 0,
        //       'raster-opacity': 0.6
        //       // 'raster-opacity': 0.2
        //     }
        //   },
        // );

        // map.current.addSource('tropomi11', {
        //   'type': 'raster',
        //   'tiles': [
        //     "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https://meeo-s5p.s3.eu-central-1.amazonaws.com/COGT/OFFL/L2__CH4___/2023/09/05/S5P_OFFL_L2__CH4____20230905T030252_20230905T044422_30542_03_020500_20230906T185320_PRODUCT_methane_mixing_ratio_4326.tif&rescale=1600%2C2200&colormap_name=plasma&return_mask=true"
        //   ],
        // });
        // map.current.addLayer(
        //   {
        //     'id': 'tropomi11', // Layer ID
        //     'type': 'raster',
        //     'source': 'tropomi11', // ID of the tile source created above
        //     'paint': {
        //       'raster-fade-duration': 0,
        //       'raster-opacity': 0.6
        //       // 'raster-opacity': 0.2
        //     }
        //   },
        // );

        // map.current.addSource('tropomi10', {
        //   'type': 'raster',
        //   'tiles': [
        //     "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https://meeo-s5p.s3.eu-central-1.amazonaws.com/COGT/OFFL/L2__CH4___/2023/09/05/S5P_OFFL_L2__CH4____20230905T012123_20230905T030252_30541_03_020500_20230906T171010_PRODUCT_methane_mixing_ratio_4326.tif&rescale=1600%2C2200&colormap_name=plasma&return_mask=true"
        //   ],
        // });
        // map.current.addLayer(
        //   {
        //     'id': 'tropomi10', // Layer ID
        //     'type': 'raster',
        //     'source': 'tropomi10', // ID of the tile source created above
        //     'paint': {
        //       'raster-fade-duration': 0,
        //       'raster-opacity': 0.6
        //       // 'raster-opacity': 0.2
        //     }
        //   },
        // );

        // map.current.addSource('tropomi9', {
        //   'type': 'raster',
        //   'tiles': [
        //     "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https://meeo-s5p.s3.eu-central-1.amazonaws.com/COGT/OFFL/L2__CH4___/2023/09/05/S5P_OFFL_L2__CH4____20230905T181618_20230905T195747_30551_03_020500_20230907T103606_PRODUCT_methane_mixing_ratio_4326.tif&rescale=1600%2C2200&colormap_name=plasma&return_mask=true"
        //   ],
        // });
        // map.current.addLayer(
        //   {
        //     'id': 'tropomi9', // Layer ID
        //     'type': 'raster',
        //     'source': 'tropomi9', // ID of the tile source created above
        //     'paint': {
        //       'raster-fade-duration': 0,
        //       'raster-opacity': 0.6
        //       // 'raster-opacity': 0.2
        //     }
        //   },
        // );
        // map.current.addSource('tropomi8', {
        //   'type': 'raster',
        //   'tiles': [
        //     "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https://meeo-s5p.s3.eu-central-1.amazonaws.com/COGT/OFFL/L2__CH4___/2023/09/05/S5P_OFFL_L2__CH4____20230905T195747_20230905T213917_30552_03_020500_20230907T120437_PRODUCT_methane_mixing_ratio_4326.tif&rescale=1600%2C2200&colormap_name=plasma&return_mask=true"
        //   ],
        // });
        // map.current.addLayer(
        //   {
        //     'id': 'tropomi8', // Layer ID
        //     'type': 'raster',
        //     'source': 'tropomi8', // ID of the tile source created above
        //     'paint': {
        //       'raster-fade-duration': 0,
        //       'raster-opacity': 0.6
        //       // 'raster-opacity': 0.2
        //     }
        //   },
        // );
        // map.current.addSource('tropomi7', {
        //   'type': 'raster',
        //   'tiles': [
        //     "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https://meeo-s5p.s3.eu-central-1.amazonaws.com/COGT/OFFL/L2__CH4___/2023/09/05/S5P_OFFL_L2__CH4____20230905T213917_20230905T232046_30553_03_020500_20230907T132940_PRODUCT_methane_mixing_ratio_4326.tif&rescale=1600%2C2200&colormap_name=plasma&return_mask=true"
        //   ],
        // });
        // map.current.addLayer(
        //   {
        //     'id': 'tropomi7', // Layer ID
        //     'type': 'raster',
        //     'source': 'tropomi7', // ID of the tile source created above
        //     'paint': {
        //       'raster-fade-duration': 0,
        //       'raster-opacity': 0.6
        //       // 'raster-opacity': 0.2
        //     }
        //   },
        // );
        // map.current.addSource('tropomi6', {
        //   'type': 'raster',
        //   'tiles': [
        //     "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https://meeo-s5p.s3.eu-central-1.amazonaws.com/COGT/OFFL/L2__CH4___/2023/09/05/S5P_OFFL_L2__CH4____20230905T080721_20230905T094850_30545_03_020500_20230907T001403_PRODUCT_methane_mixing_ratio_4326.tif&rescale=1600%2C2200&colormap_name=plasma&return_mask=true"
        //   ],
        // });
        // map.current.addLayer(
        //   {
        //     'id': 'tropomi6', // Layer ID
        //     'type': 'raster',
        //     'source': 'tropomi6', // ID of the tile source created above
        //     'paint': {
        //       'raster-fade-duration': 0,
        //       'raster-opacity': 0.6
        //       // 'raster-opacity': 0.2
        //     }
        //   },
        // );


        // map.current.addSource('tropomi5', {
        //   'type': 'raster',
        //   'tiles': [
        //     "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https://meeo-s5p.s3.eu-central-1.amazonaws.com/COGT/OFFL/L2__CH4___/2023/09/05/S5P_OFFL_L2__CH4____20230905T094850_20230905T113020_30546_03_020500_20230907T020238_PRODUCT_methane_mixing_ratio_4326.tif&rescale=1600%2C2200&colormap_name=plasma&return_mask=true"
        //   ],
        // });
        // map.current.addLayer(
        //   {
        //     'id': 'tropomi5', // Layer ID
        //     'type': 'raster',
        //     'source': 'tropomi5', // ID of the tile source created above
        //     'paint': {
        //       'raster-fade-duration': 0,
        //       'raster-opacity': 0.6
        //       // 'raster-opacity': 0.2
        //     }
        //   },
        // );

        // map.current.addSource('tropomi4', {
        //   'type': 'raster',
        //   'tiles': [

        //     "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https://meeo-s5p.s3.eu-central-1.amazonaws.com/COGT/OFFL/L2__CH4___/2023/09/06/S5P_OFFL_L2__CH4____20230906T010216_20230906T024345_30555_03_020500_20230907T165112_PRODUCT_methane_mixing_ratio_4326.tif&rescale=1600%2C2200&colormap_name=plasma&return_mask=true",
        //   ],
        // });
        // map.current.addLayer(
        //   {
        //     'id': 'tropomi4', // Layer ID
        //     'type': 'raster',
        //     'source': 'tropomi4', // ID of the tile source created above
        //     'paint': {
        //       'raster-fade-duration': 0,
        //       'raster-opacity': 0.6
        //       // 'raster-opacity': 0.2
        //     }
        //   },
        // );

        // map.current.addSource('tropomi3', {
        //   'type': 'raster',
        //   'tiles': [
        //     "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https://meeo-s5p.s3.eu-central-1.amazonaws.com/COGT/OFFL/L2__CH4___/2023/09/05/S5P_OFFL_L2__CH4____20230905T232046_20230906T010216_30554_03_020500_20230907T151208_PRODUCT_methane_mixing_ratio_4326.tif&rescale=1600%2C2200&colormap_name=plasma&return_mask=true",
        //   ],
        // });
        // map.current.addLayer(
        //   {
        //     'id': 'tropomi3', // Layer ID
        //     'type': 'raster',
        //     'source': 'tropomi3', // ID of the tile source created above
        //     'paint': {
        //       'raster-fade-duration': 0,
        //       'raster-opacity': 0.6
        //       // 'raster-opacity': 0.2
        //     }
        //   },
        // );
        // map.current.addSource('tropomi2', {
        //   'type': 'raster',
        //   'tiles': [

        //     "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https://meeo-s5p.s3.eu-central-1.amazonaws.com/COGT/OFFL/L2__CH4___/2023/09/05/S5P_OFFL_L2__CH4____20230905T163448_20230905T181618_30550_03_020500_20230907T084933_PRODUCT_methane_mixing_ratio_4326.tif&rescale=1600%2C2200&colormap_name=plasma&return_mask=true",
        //   ],
        // });
        // map.current.addLayer(
        //   {
        //     'id': 'tropomi2', // Layer ID
        //     'type': 'raster',
        //     'source': 'tropomi2', // ID of the tile source created above
        //     'paint': {
        //       'raster-fade-duration': 0,
        //       'raster-opacity': 0.6
        //       // 'raster-opacity': 0.2
        //     }
        //   },
        // );

        // map.current.addSource('tropomi0', {
        //   'type': 'raster',
        //   'tiles': [
        //     "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https://meeo-s5p.s3.eu-central-1.amazonaws.com/COGT/OFFL/L2__CH4___/2023/09/05/S5P_OFFL_L2__CH4____20230905T145319_20230905T163448_30549_03_020500_20230907T063502_PRODUCT_methane_mixing_ratio_4326.tif&rescale=1600%2C2200&colormap_name=plasma&return_mask=true"
        //   ],
        // });
        // map.current.addLayer(
        //   {
        //     'id': 'tropomi0', // Layer ID
        //     'type': 'raster',
        //     'source': 'tropomi0', // ID of the tile source created above
        //     'paint': {
        //       'raster-fade-duration': 0,
        //       'raster-opacity': 0.6
        //       // 'raster-opacity': 0.2
        //     }
        //   },
        // );

        const signedUrl = await Storage.get(sentinelUrl, { useAccelerateEndpoint: true, expiration: 28800 });
        // console.log(signedUrl)

        function Get(yourUrl) {
          var Httpreq = new XMLHttpRequest(); // a new request
          Httpreq.open("GET", yourUrl, false);
          Httpreq.send(null);
          return Httpreq.responseText;
        }

        const previewUrl = Get("https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/info?url=" + encodeURIComponent(signedUrl))

        // console.log(previewUrl)

        map.current.addSource('sentinel', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?rescale=3%2C255&colormap_name=plasma&return_mask=true&url=" + encodeURIComponent(signedUrl)

            // "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?colormap_name=winter&url=" + encodeURIComponent(signedUrl)
          ],
          'tileSize': 64,
          'minZoom': 13,
          'maxZoom': 13,
          'bounds': [54.19296199528005, 38.49182463103535, 54.2000366048042, 38.49721001962594]
        });
        map.current.addLayer(
          {
            'id': 'sentinel', // Layer ID
            'type': 'raster',
            'source': 'sentinel', // ID of the tile source created above
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 0.6
              // 'raster-opacity': 0.2
            }
          },
        );


        // console.log(map.current.getStyle().layers)




        // console.log(map)
        // map.current.setTransformRequest((url, resourceType) => {
        //   if (resourceType === 'Source' && url.startsWith('https://elevation-tiles-prod.s3')) {
        //     console.log('hello')
        //     return {
        //       url: url,
        //       // headers: { 'my-custom-header': true },
        //       // credentials: 'include'  // Include cookies for cross-origin requests
        //     }
        //   }
        //   });



        map.current.addSource('terrain', {
          type: 'raster-dem',
          encoding: 'terrarium',
          // url: 'https://demotiles.maplibre.org/terrain-tiles/tiles.json'
          tiles: ['https://elevation-tiles-prod.s3.amazonaws.com/terrarium/{z}/{x}/{y}.png']

          // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
          // data: flightJson,
          // filter: ["==", "time", '00'],
          // cluster: true,
          // clusterRadius: 50,
          // clusterMaxZoom: 17, // Max zoom to cluster points on
        });

        // map.current.addSource('hillshade', {
        //   type: 'raster-dem',
        //   encoding: 'terrarium',
        //   // url: 'https://demotiles.maplibre.org/terrain-tiles/tiles.json'https://elevation-tiles-prod.s3.amazonaws.com/normal/0/0/0.png
        //   tiles: ['https://elevation-tiles-prod.s3.amazonaws.com/terrarium/{z}/{x}/{y}.png']

        //   // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
        //   // data: flightJson,
        //   // filter: ["==", "time", '00'],
        //   // cluster: true,
        //   // clusterRadius: 50,
        //   // clusterMaxZoom: 17, // Max zoom to cluster points on
        // });



        let nav = new maplibregl.NavigationControl();
        map.current.addControl(nav, 'bottom-right');
        // map.current.addTerrainControl({
        //   source: 'terrain'
        // })







        // console.log(signedBalloonUrl)

        // map.current.addSource('radar', {
        //   'type': 'image',
        //   'url': "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/preview?url=" + encodeURIComponent(signedBalloonUrl),
        //   'coordinates': [
        //   [-105.65090100233088, 34.908000684213974],
        //   [-105.58665705277805, 34.908000684213974],
        //   [-105.58665705277805, 34.82606104261229],
        //   [-105.65090100233088, 34.82606104261229]
        //   ]
        //   });
        //   map.current.addLayer({
        //   id: 'radar-layer',
        //   'type': 'raster',
        //   'source': 'radar',
        //   'paint': {
        //   'raster-fade-duration': 0
        //   }
        //   });




        //  map.current.addLayer({
        //   id: 'hills',
        //   type: 'hillshade',
        //   source: 'hillshade',
        //   minzoom: 10,
        //   'paint': {
        //     'hillshade-shadow-color': '#473B24'
        //   }
        // })

        // rescale=0%2C12&return_mask=true


        // console.log(signedBalloonUrl)
        // console.log(signedBalloonUrl2)
        // console.log('3')
        // console.log(signedBalloonUrl3)
        // console.log('4')
        // console.log(signedBalloonUrl4)
        // console.log('5')
        // console.log(signedBalloonUrl5)
        // console.log('6')
        // console.log(signedBalloonUrl6)
        // console.log('7')
        // console.log(signedBalloonUrl7)
        // console.log('8')
        // console.log(signedBalloonUrl8)
        // console.log('9')
        // console.log(signedBalloonUrl9)
        // console.log('10')
        // console.log(signedBalloonUrl10)
        // console.log('11')
        // console.log(signedBalloonUrl11)
        // console.log('12')
        // console.log(signedBalloonUrl12)
        // console.log('13')
        // console.log(signedBalloonUrl13)
        // console.log('14')
        // console.log(signedBalloonUrl14)
        // console.log('15')
        // console.log(signedBalloonUrl15)
        // console.log('16')
        // console.log(signedBalloonUrl16)
        // console.log('17')
        // console.log(signedBalloonUrl17)
        // console.log('18')
        // console.log(signedBalloonUrl18)
        // console.log('19')
        // console.log(signedBalloonUrl19)
        // console.log('20')
        // console.log(signedBalloonUrl20)
        // console.log('21')
        // console.log(signedBalloonUrl21)

        map.current.addSource('balloon2', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?rescale=9%2C10&colormap_name=plasma&return_mask=true&url=" + encodeURIComponent(signedBalloonUrl2)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          'bounds': [
            -104.23463184485036 + soofieOffsetLon,
            31.961531124593076 + soofieOffsetLat,
            -104.11819481873346 + soofieOffsetLon,
            32.05904924533885 + soofieOffsetLat
          ]
        });
        map.current.addLayer(
          {
            'id': 'balloon2', // Layer ID
            'type': 'raster',
            'source': 'balloon2', // ID of the tile source created above
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );

        map.current.addSource('balloon3', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?rescale=9%2C10&colormap_name=plasma&return_mask=true&url=" + encodeURIComponent(signedBalloonUrl3)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          'bounds': [
            -104.17426618344598 + soofieOffsetLon,
            32.174182848675315 + soofieOffsetLat,
            -104.04361247728916 + soofieOffsetLon,
            32.29375624383685 + soofieOffsetLat
          ]
        });
        map.current.addLayer(
          {
            'id': 'balloon3', // Layer ID
            'type': 'raster',
            'source': 'balloon3', // ID of the tile source created above
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );

        map.current.addSource('balloon4', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?rescale=9%2C10&colormap_name=plasma&return_mask=true&rescale=9.6%2C10&colormap_name=plasma&url=" + encodeURIComponent(signedBalloonUrl4)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          'bounds': [
            -103.8496278584924 + soofieOffsetLon,
            32.296037918421575 + soofieOffsetLat,
            -103.71494712674674 + soofieOffsetLon,
            32.406122939677225 + soofieOffsetLat
          ]
        });
        map.current.addLayer(
          {
            'id': 'balloon4', // Layer ID
            'type': 'raster',
            'source': 'balloon4', // ID of the tile source created above
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );

        map.current.addSource('balloon5', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?rescale=9%2C10&colormap_name=plasma&return_mask=true&url=" + encodeURIComponent(signedBalloonUrl5)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          'bounds': [
            -103.860422210718 + soofieOffsetLon,
            32.214863331984525 + soofieOffsetLat,
            -103.75231590739254 + soofieOffsetLon,
            32.30401805434442 + soofieOffsetLat
          ]
        });
        map.current.addLayer(
          {
            'id': 'balloon5', // Layer ID
            'type': 'raster',
            'source': 'balloon5', // ID of the tile source created above
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );

        map.current.addSource('balloon6', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?rescale=9%2C10&colormap_name=plasma&return_mask=true&url=" + encodeURIComponent(signedBalloonUrl6)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          'bounds': [
            -103.94546479044959 + soofieOffsetLon,
            32.08146609086218 + soofieOffsetLat,
            -103.83642805345988 + soofieOffsetLon,
            32.21722771120476 + soofieOffsetLat
          ]
        });
        map.current.addLayer(
          {
            'id': 'balloon6', // Layer ID
            'type': 'raster',
            'source': 'balloon6', // ID of the tile source created above
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );

        map.current.addSource('balloon7', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?rescale=9%2C10&colormap_name=plasma&return_mask=true&url=" + encodeURIComponent(signedBalloonUrl7)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          'bounds': [
            -103.82973100544602 + soofieOffsetLon,
            32.20290079585839 + soofieOffsetLat,
            -103.71433175461723 + soofieOffsetLon,
            32.31818300377175 + soofieOffsetLat
          ]
        });
        map.current.addLayer(
          {
            'id': 'balloon7', // Layer ID
            'type': 'raster',
            'source': 'balloon7', // ID of the tile source created above
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );

        map.current.addSource('balloon8', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?rescale=9%2C10&colormap_name=plasma&return_mask=true&url=" + encodeURIComponent(signedBalloonUrl8)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          'bounds': [
            -103.89796429080714 + soofieOffsetLon,
            32.18874934092645 + soofieOffsetLat,
            -103.79850215176987 + soofieOffsetLon,
            32.27674436419197 + soofieOffsetLat
          ]
        });
        map.current.addLayer(
          {
            'id': 'balloon8', // Layer ID
            'type': 'raster',
            'source': 'balloon8', // ID of the tile source created above
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );

        map.current.addSource('balloon9', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?rescale=9%2C10&colormap_name=plasma&return_mask=true&url=" + encodeURIComponent(signedBalloonUrl9)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          'bounds': [
            -103.88767709023938 + soofieOffsetLon,
            32.232215900372275 + soofieOffsetLat,
            -103.77968797506631 + soofieOffsetLon,
            32.30095555050978 + soofieOffsetLat
          ]
        });
        map.current.addLayer(
          {
            'id': 'balloon9', // Layer ID
            'type': 'raster',
            'source': 'balloon9', // ID of the tile source created above
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );

        map.current.addSource('balloon10', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?rescale=9%2C10&colormap_name=plasma&return_mask=true&url=" + encodeURIComponent(signedBalloonUrl10)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          'bounds': [
            -103.92195529387509 + soofieOffsetLon,
            32.25480741365713 + soofieOffsetLat,
            -103.76360969840894 + soofieOffsetLon,
            32.342078993461136 + soofieOffsetLat
          ]
        });
        map.current.addLayer(
          {
            'id': 'balloon10', // Layer ID
            'type': 'raster',
            'source': 'balloon10', // ID of the tile source created above
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );

        map.current.addSource('balloon11', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?rescale=9%2C10&colormap_name=plasma&return_mask=true&url=" + encodeURIComponent(signedBalloonUrl11)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          'bounds': [
            -103.83195202530563 + soofieOffsetLon,
            32.13267284921806 + soofieOffsetLat,
            -103.75090604520894 + soofieOffsetLon,
            32.40086849789595 + soofieOffsetLat
          ]
        });
        map.current.addLayer(
          {
            'id': 'balloon11', // Layer ID
            'type': 'raster',
            'source': 'balloon11', // ID of the tile source created above
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );

        map.current.addSource('balloon12', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?rescale=9%2C10&colormap_name=plasma&return_mask=true&url=" + encodeURIComponent(signedBalloonUrl12)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          'bounds': [
            -103.82338757237213 + soofieOffsetLon,
            32.277035333260656 + soofieOffsetLat,
            -103.68353683745772 + soofieOffsetLon,
            32.42241137613697 + soofieOffsetLat
          ]
        });
        map.current.addLayer(
          {
            'id': 'balloon12', // Layer ID
            'type': 'raster',
            'source': 'balloon12', // ID of the tile source created above
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );

        map.current.addSource('balloon13', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?rescale=9%2C10&colormap_name=plasma&return_mask=true&url=" + encodeURIComponent(signedBalloonUrl13)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          'bounds': [
            -104.05677773012977 + soofieOffsetLon,
            32.15980678914316 + soofieOffsetLat,
            -103.93374651859239 + soofieOffsetLon,
            32.276686594334336 + soofieOffsetLat
          ]
        });
        map.current.addLayer(
          {
            'id': 'balloon13', // Layer ID
            'type': 'raster',
            'source': 'balloon13', // ID of the tile source created above
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );

        map.current.addSource('balloon14', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?rescale=9%2C10&colormap_name=plasma&return_mask=true&url=" + encodeURIComponent(signedBalloonUrl14)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          'bounds': [
            -103.98067014433822 + soofieOffsetLon,
            32.26292924147919 + soofieOffsetLat,
            -103.86145080495224 + soofieOffsetLon,
            32.36705410501292 + soofieOffsetLat
          ]
        });
        map.current.addLayer(
          {
            'id': 'balloon14', // Layer ID
            'type': 'raster',
            'source': 'balloon14', // ID of the tile source created above
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );

        map.current.addSource('balloon15', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?rescale=9%2C10&colormap_name=plasma&return_mask=true&url=" + encodeURIComponent(signedBalloonUrl15)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          'bounds': [
            -103.86427673618451 + soofieOffsetLon,
            32.28970769697267 + soofieOffsetLat,
            -103.69494691782444 + soofieOffsetLon,
            32.39539234593755 + soofieOffsetLat
          ]
        });
        map.current.addLayer(
          {
            'id': 'balloon15', // Layer ID
            'type': 'raster',
            'source': 'balloon15', // ID of the tile source created above
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );

        map.current.addSource('balloon16', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?rescale=9%2C10&colormap_name=plasma&return_mask=true&url=" + encodeURIComponent(signedBalloonUrl16)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          'bounds': [
            -103.95117138587484 + soofieOffsetLon,
            32.26056057551111 + soofieOffsetLat,
            -103.81915137515807 + soofieOffsetLon,
            32.356092291519154 + soofieOffsetLat
          ]
        });
        map.current.addLayer(
          {
            'id': 'balloon16', // Layer ID
            'type': 'raster',
            'source': 'balloon16', // ID of the tile source created above
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );

        map.current.addSource('balloon17', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?rescale=9%2C10&colormap_name=plasma&return_mask=true&url=" + encodeURIComponent(signedBalloonUrl17)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          'bounds': [
            -104.09948616339186 + soofieOffsetLon,
            32.19851552755 + soofieOffsetLat,
            -104.00564000341166 + soofieOffsetLon,
            32.30431553167403 + soofieOffsetLat
          ]
        });
        map.current.addLayer(
          {
            'id': 'balloon17', // Layer ID
            'type': 'raster',
            'source': 'balloon17', // ID of the tile source created above
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );

        map.current.addSource('balloon18', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?rescale=9%2C10&colormap_name=plasma&return_mask=true&url=" + encodeURIComponent(signedBalloonUrl18)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          'bounds': [
            -104.0913072175459 + soofieOffsetLon,
            32.219679282035294 + soofieOffsetLat,
            -103.9159324860662 + soofieOffsetLon,
            32.316087505238265 + soofieOffsetLat
          ]
        });
        map.current.addLayer(
          {
            'id': 'balloon18', // Layer ID
            'type': 'raster',
            'source': 'balloon18', // ID of the tile source created above
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );

        map.current.addSource('balloon19', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?rescale=9%2C10&colormap_name=plasma&return_mask=true&url=" + encodeURIComponent(signedBalloonUrl19)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          'bounds': [
            -103.99012843212542 + soofieOffsetLon,
            32.289319835127024 + soofieOffsetLat,
            -103.87171484580355 + soofieOffsetLon,
            32.37139829691429 + soofieOffsetLat
          ]
        });
        map.current.addLayer(
          {
            'id': 'balloon19', // Layer ID
            'type': 'raster',
            'source': 'balloon19', // ID of the tile source created above
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );

        map.current.addSource('balloon20', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?rescale=9%2C10&colormap_name=plasma&return_mask=true&url=" + encodeURIComponent(signedBalloonUrl20)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          'bounds': [
            -104.092125869863 + soofieOffsetLon,
            32.17064244227005 + soofieOffsetLat,
            -103.96658913013702 + soofieOffsetLon,
            32.288142795825166 + soofieOffsetLat
          ]
        });
        map.current.addLayer(
          {
            'id': 'balloon20', // Layer ID
            'type': 'raster',
            'source': 'balloon20', // ID of the tile source created above
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );

        map.current.addSource('balloon21', {
          'type': 'raster',
          'tiles': [
            "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?rescale=9%2C10&colormap_name=plasma&return_mask=true&url=" + encodeURIComponent(signedBalloonUrl21)
          ],
          'tileSize': 64,
          'minZoom': 12,
          'maxZoom': 13,
          'bounds': [
            -102.33970429080722 + soofieOffsetLon,
            31.870716846771828 + soofieOffsetLat,
            -102.2402421517698 + soofieOffsetLon,
            32.42405392990251 + soofieOffsetLat
          ]
        });
        map.current.addLayer(
          {
            'id': 'balloon21', // Layer ID
            'type': 'raster',
            'source': 'balloon21', // ID of the tile source created above
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'raster-fade-duration': 0,
              'raster-opacity': 1
              // 'raster-opacity': 0.2
            }
          },
        );

        setSignedSentinelUrl(signedUrl)

        const plumelayer = {
          type: 'FeatureCollection',
          features:

            tableData.map((plume, index) => (
              {
                type: "Feature",
                properties:
                {
                  id: plume['name'],
                  date: plume['date'],
                  rate: plume['rate'],
                  lat: plume['lat'] + soofieOffsetLat,
                  lon: plume['lon'] + soofieOffsetLon

                  // site: sensor['site']
                },
                geometry:
                {
                  type: "Point",
                  coordinates:
                    [
                      plume['lon'] + soofieOffsetLon,
                      plume['lat'] + soofieOffsetLat
                    ]
                }
              }
            ))
          // }
        };

        map.current.addSource('plumeSource', {
          type: 'geojson',
          // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
          data: plumelayer,
          cluster: true,
          clusterRadius: 50,
          clusterMaxZoom: 17, // Max zoom to cluster points on
        });



        map.current.addLayer({
          id: 'plumeClustersLayer',
          type: 'circle',
          source: 'plumeSource',
          // minzoom: 6,
          filter: ['has', 'point_count'],
          paint: {
            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            'circle-color': [
              'step',
              ['get', 'point_count'],
              '#ff0000',
              5,
              '#ff0000',
              20,
              '#ff0000'
            ],
            'circle-stroke-width': [
              'step',
              ['get', 'point_count'],
              1,
              5,
              1,
              20,
              1
            ],
            'circle-stroke-color': [
              'step',
              ['get', 'point_count'],
              '#000',
              5,
              '#000',
              20,
              '#000'
            ],
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              10,
              5,
              15,
              20,
              20
            ]
          }
        });

        // // console.log(map)

        map.current.addLayer({
          id: 'plume-cluster-count',
          type: 'symbol',
          source: 'plumeSource',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['literal', ['Fira GO Regular']],
            'text-size': 12
          }
        });


        map.current.addLayer({
          id: 'unclustered-plume',
          type: 'circle',
          source: 'plumeSource',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#ff0000',
            'circle-radius': 7,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#000'
          }
        });

        for (let index = 0; index < plumelayer.features.length; index++) {

          const e = plumelayer.features[index];
          // console.log(e)
          const coordinates = e.geometry.coordinates.slice();
          const id = e.properties.id;
          // console.log(timeZoneValue)
          // console.log(e.features[0].properties.date)
          // const stats = JSON.parse(e.features[0].properties.stats);
          // // console.log(stats)
          // const site = e.features[0].properties.site;
          // <b>Site:</b> ${site}<br />
          // <b>Time:</b> ${(new Date(e.features[0].properties.date)).toLocaleString("en-US", {
          //   timeZone: timeZoneValue.value.value,timeZoneName: 'short', year:'numeric',month:'numeric',day:'numeric', hour: '2-digit',
          //   minute: '2-digit'
          // })}. <br />
          var text = `     
            <b>Time:</b> ${(new Date(e.properties.date)).toLocaleString('en-US', {
            timeZone: timeZoneValue.value, timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
            minute: '2-digit'
          })} <br />
            <b>Rate:</b> ${e.properties.rate.toFixed(2)} kg/hr <br />
            <b>Lat:</b> ${e.properties.lat.toFixed(5)} deg<br />
            <b>Lon:</b> ${e.properties.lon.toFixed(5)} deg<br />
            `

          // const mag = e.features[0].properties.mag;
          // const tsunami =
          // e.features[0].properties.tsunami === 1 ? 'yes' : 'no';

          // Ensure that if the map is zoomed out such that
          // multiple copies of the feature are visible, the
          // popup appears over the copy being pointed to.
          // while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          //   coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          // }

          const newPopup = new maplibregl.Popup({ offset: 10, closeOnClick: true })
            .setLngLat(coordinates)
            // .trackPointer()
            .setHTML(
              text
            )
          // .addTo(map.current)
          // .isOpen(false);

          popups[id] = newPopup
          // console.log(popups)
          // console.log(id)
          setPopups(popups)

        }

        map.current.on('click', 'unclustered-plume', onPlumeClick.bind(null, timeZoneValue.value));

        map.current.on('click', 'plumeClustersLayer', (e) => {
          // console.log(e)
          const features = map.current.queryRenderedFeatures(e.point, {
            layers: ['plumeClustersLayer']
          });
          const clusterId = features[0].properties.cluster_id;
          map.current.getSource('plumeSource').getClusterExpansionZoom(
            clusterId,
            (err, zoom) => {
              if (err) return;

              map.current.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom
              });
            }
          );
        });


      } catch (error) {
        console.log(error);
      }




      // });





      // await new Promise(r => setTimeout(r, 10000));







      // setMeasurementsFilterState(visTpList);

      // alert(lastUpload[0]['time'])
      // setFilterState({
      //   // alertsChecked: filterState['alertsChecked'],
      //   // tropomiChecked: filterState['tropomiChecked'],
      //   // alertMarkers: alertsMarkerList,
      //   // alertMarkers: filterState['alertMarkers'],
      //   // viewProps: filterState['viewProps'],
      //   // site: filterState['site'],
      //   // sensorsChecked: filterState['sensorsChecked'],
      //   // windChecked: filterState['windChecked'],
      //   // noaaWindChecked: filterState['noaaWindChecked'],
      //   // sensorMarkers: devicesMarkerList,
      //   sensorMarkers: filterState['sensorMarkers'],
      //   // startDate: filterState['startDate'],
      //   // endDate: filterState['endDate'],
      //   // devices: deviceList,
      //   // devices: updateDevs,
      //   // fluxList: newTps,
      //   // sites: newSites,
      //   // sites: allSites,
      //   // sites: filterState['sites'],
      //   // components: [],
      //   // lastUpload: lastUpload[0]['time'],
      //   // lastUpload: lastTime,
      //   // fluxTable: tpTable,
      //   // plumeUrls: signedSentinelUrl,
      //   // visibleTimeSeries: visTpList,
      //   // visibleTimeSeriesFilter: visTpList,
      //   // activeMainTab: filterState['activeMainTab'],
      //   // visibleFluxSeries: dataList,
      //   // visibleFluxSeriesFilter: dataList,
      //   // recSearchTlLat: filterState['recSearchTlLat'],
      //   // recSearchTlLon: filterState['recSearchTlLon'],
      //   // recSearchBrLat: filterState['recSearchBrLat'],
      //   // recSearchBrLon: filterState['recSearchBrLon']
      // })


      // setDevices(deviceList)






    } catch (err) { console.log(err) }
  }


  // const {current: map} = useMap();


  const mapContainer = useRef(null);





  // const data = require('/GetSoofieSites_ExxonMobilConfidential.json');
  // console.log(data);


















  // const NavigationBar = () => {
  //   let activeStyle = {
  //     color: "white",
  //     textDecoration: 'none'
  //   };

  //   let inactiveStyle = {
  //     color: "white",
  //     textDecoration: 'none'
  //   };

  //   let activeClassName = "underline";

  //   const { tokens } = useTheme();

  //   const renderOption = (option, value) => {
  //     const { label } = option;
  //     return (
  //       <HighlightMatch color='white' query={value}>{option}</HighlightMatch>
  //     );
  //   };





  //   function getMatchesCountText(count) {
  //     return count === 1 ? `1 match` : `${count} matches`;
  //   }

  //   function formatDate(date) {
  //     const dateFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'long' });
  //     const timeFormatter = new Intl.DateTimeFormat('en-US', { timeStyle: 'short', hour12: false });
  //     return `${dateFormatter.format(date)}, ${timeFormatter.format(date)}`;
  //   }

  //   function createLabelFunction(columnName) {
  //     return ({ sorted, descending }) => {
  //       const sortState = sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted';
  //       return `${columnName}, ${sortState}.`;
  //     };
  //   }


  // }



  const theme = {
    name: 'my-theme',
    overrides: [defaultDarkModeOverride],
    overrides: [
      {
        colorMode: 'dark',
        tokens: {
          colors: {
            font: {
              primary: { value: '{colors.white}' },
              secondary: { value: '{colors.neutral.100}' },
              tertiary: { value: '{colors.neutral.90}' },
            },
            background: {
              primary: { value: 'colors.grey.10' },
              secondary: { value: '{colors.grey.20}' },
              tertiary: { value: '{colors.grey.40}' },
            },
            border: {
              primary: { value: '{colors.neutral.60}' },
              secondary: { value: '{colors.neutral.40}' },
              tertiary: { value: '{colors.neutral.20}' },
            },
          },
        },
      },
    ],
  };

  const currentDate = new Date();
  var pastDate = new Date();
  pastDate.setHours(pastDate.getHours() - 1);
  // console.log(pastDate);
  // const { tokens } = useTheme();
  // const [searchValue, setSearchValue] = useState('');


  return (
    // <ThemeProvider>
    <>

      <Modal
        onDismiss={() => setTimeZoneVisible(false)}
        visible={timeZoneVisible}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              {/* <Button variant="link" onClick={() => setTimeZoneVisible(false)}>Cancel</Button> */}
              <Button variant="primary" onClick={() => setTimeZoneVisible(false)}>Ok</Button>
            </SpaceBetween>
          </Box>
        }
        header="Time zone"
      >
        <RadioGroup
          onChange={({ detail }) => {
            // console.log(detail)
            setTimeZoneValue(detail)
            setSelectedItems([])
            for (let index = 1; index < 21; index++) {
              // console.log('ballon'+index)
              map.current.setLayoutProperty('balloon' + index, 'visibility', 'none');
            }

            let newTableData = [
              {
                // name: (new Date(Date.UTC(2023, 10, 19, 16, 14, 30))).toLocaleString("en-US", {
                //   timeZone: timeZoneValue.value, timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                //   minute: '2-digit'
                // }),
                date: (new Date(Date.UTC(2023, 9, 19, 16, 14, 30))).toLocaleString("en-US", {
                  timeZone: detail.value, timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }),
                name: 'Oct 19, 16:14:30Z - 16:16:29Z',
                // date: 'Oct 19, 16:14:30Z - 16:16:29Z',
                background: 9.518,
                mass: 16453.287,
                rate: 288.196,
                layerName: 'balloon1',
                u10: 352.120,
                u10speed: 1.890,
                lat: 32.19139,
                lon: -104.10488,
                link: <Link onFollow={() => {
                  map.current.flyTo({
                    center: [
                      -104.10488,
                      32.19139
                    ],
                    zoom: 15
                    // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                  })
                }}>{
                    (new Date(Date.UTC(2023, 9, 19, 16, 14, 30))).toLocaleString("en-US", {
                      timeZone: detail.value, timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                      minute: '2-digit'
                    })}
                </Link>,
                visible: <Checkbox
                  onChange={({ detail }) =>
                    setBallonVis1(detail.checked)
                  }
                  checked={checked1}
                ></Checkbox>
                // availabilityZone: 'us-east-1c',
                // state: 'Healthy',
                // lastModified: new Date(2019, 3, 28, 4, 16),
              },
              {
                name: 'Oct 19, 16:37:31Z - 16:39:20Z',
                date: (new Date(Date.UTC(2023, 9, 19, 16, 37, 31))).toLocaleString("en-US", {
                  timeZone: detail.value, timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }),
                background: 9.469,
                mass: 420609.426,
                rate: 1548.330,
                u10: 18.498,
                u10speed: 1.125,
                layerName: 'balloon2',
                visible: <Checkbox
                  onChange={({ detail }) =>
                    setBallonVis2(detail.checked)
                  }
                  checked={checked2}
                ></Checkbox>,
                lat: 31.99759,
                lon: -104.14920,
                link: <Link onFollow={() => map.current.flyTo({
                  center: [
                    -104.14920 + soofieOffsetLon,
                    31.99759 + soofieOffsetLat
                  ],
                  zoom: 15
                  // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })}>{
                    (new Date(Date.UTC(2023, 9, 19, 16, 37, 31))).toLocaleString("en-US", {
                      timeZone: detail.value, timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                      minute: '2-digit'
                    })}</Link>,
                // availabilityZone: 'us-east-1a',
                // state: 'Healthy',
                // lastModified: new Date(2022, 5, 5, 15, 32),
              },
              {
                name: 'Oct 19, 20:30:24Z - 20:32:24Z',
                date: (new Date(Date.UTC(2023, 9, 19, 20, 30, 24))).toLocaleString("en-US", {
                  timeZone: detail.value, timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }),
                background: 9.545,
                mass: 2933.730,
                rate: 216.022,
                u10: 166.734,
                layerName: 'balloon3',
                u10speed: 4.288,
                visible: <Checkbox
                  onChange={({ detail }) =>
                    setBallonVis3(detail.checked)
                  }
                  checked={checked3}
                ></Checkbox>,
                lon: -104.13220,
                lat: 32.26482,
                link: <Link onFollow={() => map.current.flyTo({
                  center: [
                    -104.13220,
                    32.26482
                  ],
                  zoom: 15
                  // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })}>{
                    (new Date(Date.UTC(2023, 9, 19, 20, 30, 24))).toLocaleString("en-US", {
                      timeZone: detail.value, timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                      minute: '2-digit'
                    })}</Link>,
                // availabilityZone: 'us-east-1d',
                // state: 'Healthy',
                // lastModified: new Date(2020, 2, 8, 9, 24),
              },
              {
                name: 'Oct 20, 15:49:28Z - 15:51:28Z',
                date: (new Date(Date.UTC(2023, 9, 20, 15, 49, 28))).toLocaleString("en-US", {
                  timeZone: detail.value,
                  timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }),
                background: 9.438,
                mass: 3666.964,
                rate: 19.153,
                u10: 226.981,
                u10speed: 0.502,
                layerName: 'balloon4',
                visible: <Checkbox
                  onChange={({ detail }) =>
                    setBallonVis4(detail.checked)
                  }
                  checked={checked4}
                ></Checkbox>,
                lon: -103.79525,
                lat: 32.31426,
                link: <Link onFollow={() => map.current.flyTo({
                  center: [
                    -103.79525,
                    32.31426
                  ],
                  zoom: 15
                  // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })}>{
                    (new Date(Date.UTC(2023, 9, 20, 15, 49, 28))).toLocaleString("en-US", {
                      timeZone: detail.value,
                      timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                      minute: '2-digit'
                    })}</Link>,
                // availabilityZone: 'us-east-1d',
                // state: 'Healthy',
                // lastModified: new Date(2020, 2, 8, 9, 24),
              },
              {
                name: 'Oct 20, 15:51:28Z - 15:53:28Z',
                date: (new Date(Date.UTC(2023, 9, 20, 15, 51, 28))).toLocaleString("en-US", {
                  timeZone: detail.value,
                  timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }),
                background: 9.387,
                mass: 2407.385,
                rate: 29.920,
                u10: 190.289,
                u10speed: 0.339,
                layerName: 'balloon5',
                visible: <Checkbox
                  onChange={({ detail }) =>
                    setBallonVis5(detail.checked)
                  }
                  checked={checked5}
                ></Checkbox>,
                lon: -103.79262,
                lat: 32.27590,
                link: <Link onFollow={() => map.current.flyTo({
                  center: [
                    -103.79262,
                    32.27590
                  ],
                  zoom: 15
                  // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })}>{
                    (new Date(Date.UTC(2023, 9, 20, 15, 51, 28))).toLocaleString("en-US", {
                      timeZone: detail.value,
                      timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                      minute: '2-digit'
                    })}</Link>,
                // availabilityZone: 'us-east-1d',
                // state: 'Healthy',
                // lastModified: new Date(2020, 2, 8, 9, 24),
              },
              {
                name: 'Oct 20, 15:57:07Z - 15:58:57Z',
                date: (new Date(Date.UTC(2023, 9, 20, 15, 57, 7))).toLocaleString("en-US", {
                  timeZone: detail.value,
                  timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }),
                background: 9.444,
                mass: 1487.160,
                rate: 31.312,
                u10: 262.428,
                u10speed: 0.650,
                layerName: 'balloon6',
                visible: <Checkbox
                  onChange={({ detail }) =>
                    setBallonVis6(detail.checked)
                  }
                  checked={checked6}
                ></Checkbox>,
                lon: -103.87758,
                lat: 32.14216,
                link: <Link onFollow={() => map.current.flyTo({
                  center: [
                    -103.87758,
                    32.14216
                  ],
                  zoom: 15
                  // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })}>{
                    (new Date(Date.UTC(2023, 9, 20, 15, 57, 7))).toLocaleString("en-US", {
                      timeZone: detail.value,
                      timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                      minute: '2-digit'
                    })}</Link>,
                // availabilityZone: 'us-east-1d',
                // state: 'Healthy',
                // lastModified: new Date(2020, 2, 8, 9, 24),
              },
              {
                name: 'plume_georef_DE_DAEDALUS-002_MethaneL2_s2023293194831000_e2023293195031000_c2023347194102457.corr_R004',
                date: (new Date(Date.UTC(2023, 9, 20, 16, 0, 58))).toLocaleString("en-US", {
                  timeZone: detail.value,
                  timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }),
                background: 9.504,
                mass: 11727.962,
                rate: 130.395,
                u10: 296.329,
                layerName: 'balloon7',
                u10speed: 0.864,
                visible: <Checkbox
                  onChange={({ detail }) =>
                    setBallonVis7(detail.checked)
                  }
                  checked={checked7}
                ></Checkbox>,
                lon: -103.79139,
                lat: 32.27529,
                link: <Link onFollow={() => map.current.flyTo({
                  center: [
                    -103.79139,
                    32.27529
                  ],
                  zoom: 15
                  // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })}>{
                    (new Date(Date.UTC(2023, 9, 20, 16, 0, 58))).toLocaleString("en-US", {
                      timeZone: detail.value,
                      timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                      minute: '2-digit'
                    })}</Link>,
                // availabilityZone: 'us-east-1d',
                // state: 'Healthy',
                // lastModified: new Date(2020, 2, 8, 9, 24),
              },

              {
                name: 'Oct 20, 16:24:59Z - 16:26:59Z',
                date: (new Date(Date.UTC(2023, 9, 20, 16, 24, 59))).toLocaleString("en-US", {
                  timeZone: detail.value,
                  timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }),
                background: 9.473,
                mass: 225.689,
                rate: 27.495,
                u10: 268.775,
                layerName: 'balloon8',
                u10speed: 0.764,
                visible: <Checkbox
                  onChange={({ detail }) =>
                    setBallonVis8(detail.checked)
                  }
                  checked={checked8}
                ></Checkbox>,
                lon: -103.82898,
                lat: 32.22837,
                link: <Link onFollow={() => map.current.flyTo({
                  center: [
                    -103.82898,
                    32.22837
                  ],
                  zoom: 15
                  // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })}>{
                    (new Date(Date.UTC(2023, 9, 20, 16, 24, 59))).toLocaleString("en-US", {
                      timeZone: detail.value,
                      timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                      minute: '2-digit'
                    })}</Link>,
                // availabilityZone: 'us-east-1d',
                // state: 'Healthy',
                // lastModified: new Date(2020, 2, 8, 9, 24),
              },
              {
                name: 'Oct 20, 16:56:29Z - 16:58:29Z',
                date: (new Date(Date.UTC(2023, 9, 20, 16, 56, 29))).toLocaleString("en-US", {
                  timeZone: detail.value,
                  timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }),
                background: 9.490,
                mass: 3055.096,
                rate: 111.308,
                u10: 261.822,
                u10speed: 1.095,
                layerName: 'balloon9',
                visible: <Checkbox
                  onChange={({ detail }) =>
                    setBallonVis9(detail.checked)
                  }
                  checked={checked9}
                ></Checkbox>,
                lon: -103.79031,
                lat: 32.27091,
                link: <Link onFollow={() => map.current.flyTo({
                  center: [
                    -103.79031,
                    32.27091
                  ],
                  zoom: 15
                  // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })}>{
                    (new Date(Date.UTC(2023, 9, 20, 16, 56, 29))).toLocaleString("en-US", {
                      timeZone: detail.value,
                      timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                      minute: '2-digit'
                    })}</Link>,
                // availabilityZone: 'us-east-1d',
                // state: 'Healthy',
                // lastModified: new Date(2020, 2, 8, 9, 24),
              },
              {
                name: 'Oct 20, 17:05:13Z - 17:07:13Z',
                date: (new Date(Date.UTC(2023, 9, 20, 17, 5, 13))).toLocaleString("en-US", {
                  timeZone: detail.value,
                  timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }),
                background: 9.485,
                mass: 162.229,
                rate: 30.862,
                u10: 289.225,
                layerName: 'balloon10',
                u10speed: 0.913,
                visible: <Checkbox
                  onChange={({ detail }) =>
                    setBallonVis10(detail.checked)
                  }
                  checked={checked10}
                ></Checkbox>,
                lon: -103.79309,
                lat: 32.28055,
                link: <Link onFollow={() => map.current.flyTo({
                  center: [
                    -103.79309,
                    32.28055
                  ],
                  zoom: 15
                  // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })}>{
                    (new Date(Date.UTC(2023, 9, 20, 17, 5, 13))).toLocaleString("en-US", {
                      timeZone: detail.value,
                      timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                      minute: '2-digit'
                    })}</Link>,
                // availabilityZone: 'us-east-1d',
                // state: 'Healthy',
                // lastModified: new Date(2020, 2, 8, 9, 24),
              },
              {
                name: 'Oct 20, 17:29:14Z - 17:31:14Z',
                date: (new Date(Date.UTC(2023, 9, 20, 17, 29, 14))).toLocaleString("en-US", {
                  timeZone: detail.value,
                  timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }),
                background: 9.472,
                mass: 3441.932,
                rate: 97.570,
                u10: 249.219,
                layerName: 'balloon11',
                u10speed: 1.236,
                visible: <Checkbox
                  onChange={({ detail }) =>
                    setBallonVis11(detail.checked)
                  }
                  checked={checked11}
                ></Checkbox>,
                lon: -103.79255,
                lat: 32.1098,
                link: <Link onFollow={() => map.current.flyTo({
                  center: [
                    -103.79255,
                    32.1098
                  ],
                  zoom: 15
                  // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })}>{
                    (new Date(Date.UTC(2023, 9, 20, 17, 29, 14))).toLocaleString("en-US", {
                      timeZone: detail.value,
                      timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                      minute: '2-digit'
                    })}</Link>,
                // availabilityZone: 'us-east-1d',
                // state: 'Healthy',
                // lastModified: new Date(2020, 2, 8, 9, 24),
              },
              {
                name: 'Oct 20, 18:43:33Z - 18:45:33Z',
                date: (new Date(Date.UTC(2023, 9, 20, 18, 43, 33))).toLocaleString("en-US", {
                  timeZone: detail.value,
                  timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }),
                background: 9.403,
                mass: 125.943,
                rate: 55.754,
                u10: 186.388,
                layerName: 'balloon12',
                u10speed: 1.691,
                visible: <Checkbox
                  onChange={({ detail }) =>
                    setBallonVis12(detail.checked)
                  }
                  checked={checked12}
                ></Checkbox>,
                lon: -103.79086,
                lat: 32.31488,
                link: <Link onFollow={() => map.current.flyTo({
                  center: [
                    -103.79086,
                    32.31488
                  ],
                  zoom: 15
                  // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })}>{
                    (new Date(Date.UTC(2023, 9, 20, 18, 43, 33))).toLocaleString("en-US", {
                      timeZone: detail.value,
                      timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                      minute: '2-digit'
                    })}</Link>,
                // availabilityZone: 'us-east-1d',
                // state: 'Healthy',
                // lastModified: new Date(2020, 2, 8, 9, 24),
              },
              {
                name: 'Oct 20, 18:49:33Z - 18:50:56Z',
                date: (new Date(Date.UTC(2023, 9, 20, 18, 49, 33))).toLocaleString("en-US", {
                  timeZone: detail.value,
                  timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }),
                background: 9.489,
                mass: 1251.514,
                rate: 116.561,
                u10: 194.411,
                layerName: 'balloon13',
                u10speed: 1.979,
                visible: <Checkbox
                  onChange={({ detail }) =>
                    setBallonVis13(detail.checked)
                  }
                  checked={checked13}
                ></Checkbox>,
                lon: -104.00296,
                lat: 32.19602,
                link: <Link onFollow={() => map.current.flyTo({
                  center: [
                    -104.00296,
                    32.19602
                  ],
                  zoom: 15
                  // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })}>{
                    (new Date(Date.UTC(2023, 9, 20, 18, 49, 33))).toLocaleString("en-US", {
                      timeZone: detail.value,
                      timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                      minute: '2-digit'
                    })}</Link>,
                // availabilityZone: 'us-east-1d',
                // state: 'Healthy',
                // lastModified: new Date(2020, 2, 8, 9, 24),
              },
              {
                name: 'Oct 20, 19:13:01Z - 19:15:01Z',
                date: (new Date(Date.UTC(2023, 9, 20, 19, 13, 1))).toLocaleString("en-US", {
                  timeZone: detail.value,
                  timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }),
                background: 9.551,
                mass: 17628.397,
                rate: 411.782,
                u10: 230.203,
                u10speed: 1.391,
                layerName: 'balloon14',
                visible: <Checkbox
                  onChange={({ detail }) =>
                    setBallonVis14(detail.checked)
                  }
                  checked={checked14}
                ></Checkbox>,
                lon: -103.92634,
                lat: 32.30699,
                link: <Link onFollow={() => map.current.flyTo({
                  center: [
                    -103.92634,
                    32.30699
                  ],
                  zoom: 15
                  // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })}>{
                    (new Date(Date.UTC(2023, 9, 20, 19, 13, 1))).toLocaleString("en-US", {
                      timeZone: detail.value,
                      timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                      minute: '2-digit'
                    })}</Link>,
                // availabilityZone: 'us-east-1d',
                // state: 'Healthy',
                // lastModified: new Date(2020, 2, 8, 9, 24),
              },
              {
                name: 'Oct 20, 19:15:01Z - 19:17:01Z',
                date: (new Date(Date.UTC(2023, 9, 20, 19, 15, 1))).toLocaleString("en-US", {
                  timeZone: detail.value,
                  timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }),
                background: 9.451,
                mass: 34695.989,
                rate: 749.537,
                u10: 193.814,
                layerName: 'balloon15',
                u10speed: 1.986,
                visible: <Checkbox
                  onChange={({ detail }) =>
                    setBallonVis15(detail.checked)
                  }
                  checked={checked15}
                ></Checkbox>,
                lon: -103.79310,
                lat: 32.31565,
                link: <Link onFollow={() => map.current.flyTo({
                  center: [
                    -103.79310,
                    32.31565
                  ],
                  zoom: 15
                  // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })}>{
                    (new Date(Date.UTC(2023, 9, 20, 19, 15, 1))).toLocaleString("en-US", {
                      timeZone: detail.value,
                      timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                      minute: '2-digit'
                    })}</Link>,
                // availabilityZone: 'us-east-1d',
                // state: 'Healthy',
                // lastModified: new Date(2020, 2, 8, 9, 24),
              },
              {
                name: 'Oct 20, 19:17:01Z - 19:19:01Z',
                date: (new Date(Date.UTC(2023, 9, 20, 19, 17, 1))).toLocaleString("en-US", {
                  timeZone: detail.value,
                  timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }),
                background: 9.432,
                mass: 27100.938,
                rate: 302.003,
                layerName: 'balloon16',
                u10: 203.100,
                u10speed: 1.912,
                visible: <Checkbox
                  onChange={({ detail }) =>
                    setBallonVis16(detail.checked)
                  }
                  checked={checked16}
                ></Checkbox>,
                lon: -103.92850,
                lat: 32.30745,
                link: <Link onFollow={() => map.current.flyTo({
                  center: [
                    -103.92850,
                    32.30745
                  ],
                  zoom: 15
                  // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })}>{
                    (new Date(Date.UTC(2023, 9, 20, 19, 17, 1))).toLocaleString("en-US", {
                      timeZone: detail.value,
                      timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                      minute: '2-digit'
                    })}</Link>,
                // availabilityZone: 'us-east-1d',
                // state: 'Healthy',
                // lastModified: new Date(2020, 2, 8, 9, 24),
              },
              {
                name: 'Oct 20, 19:21:01Z - 19:22:25Z',
                date: (new Date(Date.UTC(2023, 9, 20, 19, 21, 1))).toLocaleString("en-US", {
                  timeZone: detail.value,
                  timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }),
                background: 9.501,
                mass: 14253.204,
                rate: 456.757,
                u10: 208.176,
                u10speed: 2.150,
                layerName: 'balloon17',
                visible: <Checkbox
                  onChange={({ detail }) =>
                    setBallonVis17(detail.checked)
                  }
                  checked={checked17}
                ></Checkbox>,
                lon: -104.03101,
                lat: 32.24513,
                link: <Link onFollow={() => map.current.flyTo({
                  center: [
                    -104.03101,
                    32.24513
                  ],
                  zoom: 15
                  // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })}>{
                    (new Date(Date.UTC(2023, 9, 20, 19, 21, 1))).toLocaleString("en-US", {
                      timeZone: detail.value,
                      timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                      minute: '2-digit'
                    })}</Link>,
                // availabilityZone: 'us-east-1d',
                // state: 'Healthy',
                // lastModified: new Date(2020, 2, 8, 9, 24),
              },
              {
                name: 'Oct 20, 19:42:31Z - 19:44:31Z',
                date: (new Date(Date.UTC(2023, 9, 20, 19, 42, 31))).toLocaleString("en-US", {
                  timeZone: detail.value,
                  timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }),
                background: 9.535,
                mass: 5010.326,
                rate: 330.643,
                u10: 179.700,
                layerName: 'balloon18',
                u10speed: 2.381,
                visible: <Checkbox
                  onChange={({ detail }) =>
                    setBallonVis18(detail.checked)
                  }
                  checked={checked18}
                ></Checkbox>,
                lon: -104.03457,
                lat: 32.24452,
                link: <Link onFollow={() => map.current.flyTo({
                  center: [
                    -104.03457,
                    32.24452
                  ],
                  zoom: 15
                  // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })}>{
                    (new Date(Date.UTC(2023, 9, 20, 19, 42, 31))).toLocaleString("en-US", {
                      timeZone: detail.value,
                      timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                      minute: '2-digit'
                    })}</Link>,
                // availabilityZone: 'us-east-1d',
                // state: 'Healthy',
                // lastModified: new Date(2020, 2, 8, 9, 24),
              },
              {
                name: 'Oct 20, 19:48:31Z - 19:50:31Z',
                date: (new Date(Date.UTC(2023, 9, 20, 19, 48, 31))).toLocaleString("en-US", {
                  timeZone: detail.value,
                  timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }),
                background: 9.505,
                mass: 941.499,
                rate: 76.078,
                u10: 229.789,
                layerName: 'balloon19',
                u10speed: 1.803,
                visible: <Checkbox
                  onChange={({ detail }) =>
                    setBallonVis19(detail.checked)
                  }
                  checked={checked19}
                ></Checkbox>,
                lon: -103.92976,
                lat: 32.30952,
                link: <Link onFollow={() => map.current.flyTo({
                  center: [
                    -103.92976,
                    32.30952
                  ],
                  zoom: 15
                  // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })}>{
                    (new Date(Date.UTC(2023, 9, 20, 19, 48, 31))).toLocaleString("en-US", {
                      timeZone: detail.value,
                      timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                      minute: '2-digit'
                    })}</Link>,
                // availabilityZone: 'us-east-1d',
                // state: 'Healthy',
                // lastModified: new Date(2020, 2, 8, 9, 24),
              },
              {
                name: 'Oct 20, 20:12:03Z - 20:14:03Z',
                date: (new Date(Date.UTC(2023, 9, 20, 20, 12, 3))).toLocaleString("en-US", {
                  timeZone: detail.value,
                  timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }),
                background: 9.512,
                mass: 67701.235,
                rate: 920.516,
                layerName: 'balloon20',
                u10: 176.06,
                u10speed: 1.46,
                visible: <Checkbox
                  onChange={({ detail }) =>
                    setBallonVis20(detail.checked)
                  }
                  checked={checked20}
                ></Checkbox>,
                lon: -104.03262,
                lat: 32.24322,
                link: <Link onFollow={() => map.current.flyTo({
                  center: [
                    -104.03262,
                    32.24322
                  ],
                  zoom: 15
                  // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })}>{
                    (new Date(Date.UTC(2023, 9, 20, 20, 12, 3))).toLocaleString("en-US", {
                      timeZone: detail.value,
                      timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                      minute: '2-digit'
                    })}</Link>,
                // availabilityZone: 'us-east-1d',
                // state: 'Healthy',
                // lastModified: new Date(2020, 2, 8, 9, 24),
              },


              // {
              //   name: 'Oct 20, 16:24:59Z - 16:24:59Z',
              //   date: 'Oct 20, 16:24:59Z - 16:24:59Z',
              //   background: 9.473,
              //   mass: 255.689,
              //   rate: 27.495,
              //   u10: 268.775,
              //   u10speed: 0.764,
              //   visible: <Checkbox
              //     onChange={({ detail }) =>
              //       setBallonVis21(detail.checked)
              //     }
              //     checked={checked21}
              //   ></Checkbox>,
              //   link: <Link onFollow={() => map.current.fitBounds([
              //     [-102.33970429080722,
              //       31.870716846771828],
              //     [-102.2402421517698,
              //       32.42405392990251]
              //   ])}>SAMPLE | Oct 20, 16:24:59Z - 16:24:59Z </Link>,
              //   // availabilityZone: 'us-east-1d',
              //   // state: 'Healthy',
              //   // lastModified: new Date(2020, 2, 8, 9, 24),
              // },
            ]
            setFilterPlumeTable(newTableData);
            setAllTableData(newTableData);
            // console.log(map.current._listeners.click)
            // console.log(map.current.off('click', 'unclusted-plume', onPlumeClick))
            // map.current.off('click', onPlumeClick);
            for (let index = 0; index < newTableData.length; index++) {
              const e = newTableData[index];
              // console.log(e)
              const popup = popups[e.name];
              var text = `     
                      <b>Time:</b> ${e.date} <br />
                      <b>Rate:</b> ${e.rate.toFixed(2)} kg/hr <br />
                      <b>Lat:</b> ${e.lat.toFixed(5)} deg<br />
                      <b>Lon:</b> ${e.lon.toFixed(5)} deg<br />
                      `
              popup.setHTML(text)

            }




          }

          }
          value={timeZoneValue.value}
          items={[
            { value: "UTC", label: "UTC" },
            { value: "America/Los_Angeles", label: "Pacific (UTC - 7:00)" },
            { value: "America/Denver", label: "Mountain (UTC - 6:00)" },
            { value: "America/Chicago", label: "Central (UTC - 5:00)" },
            { value: "America/New_York", label: "Eastern (UTC - 4:00)" },
          ]}
        />
      </Modal>

      <TopNavigation
        i18nStrings={i18nStrings}
        search={
          <>
            <SpaceBetween direction="horizontal" size="s">
              <TextContent>
                <p><b>Last refreshed:</b> {new Date().toLocaleString('en-US', {
                  timeZone: timeZoneValue.value, timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                })} (GMT{new Date().toLocaleString('en-US', {
                  timeZone: timeZoneValue.value, timeZoneName: 'shortOffset', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                  minute: '2-digit'
                }).split('GMT')[1]})
                  {/* {filterState['lastUpload']} */}

                </p>

              </TextContent>
              {/* <CsButton
                variant='link'
                onClick={() => fetchDevices()}
              ><CsIcon name="refresh" size='small' /></CsButton> */}
            </SpaceBetween>

          </>

        }
        identity={{
          href: '#',
          title: <>
            <SpaceBetween direction='horizontal' size='m'>

              <Link
                onFollow={() =>
                  setScepterInfoVis(true)
                }
              >
                <CsIcon name='status-info' />
              </Link>

              <Badge color="red"><strong>DEMO</strong></Badge>
              <TextContent><small>v0.2.4</small></TextContent>

              <Modal
                onDismiss={() => setScepterInfoVis(false)}
                visible={scepterInfoVis}
                footer={
                  <Box float="left">
                    <TextContent>
                      <b>Last data update:</b> {new Date().toJSON().slice(0, 13) + ":00:00Z"}
                    </TextContent>
                    {/* <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link">Cancel</Button>
                        <Button variant="primary">Ok</Button>
                      </SpaceBetween> */}
                  </Box>
                }
                header="Multi-sensor Monitoring within the Dynamic Vertical Air Column"
              >
                <div>
                  <p>
                    Scepter monitors global atmospheric components and ingests data from a wide range
                    of reliable, verifiable, independent and third-party atmospheric monitoring providers.
                    The Scepter multi-sensor system captures data in the dynamic, multidimensional airspace.
                    This is monitoring in motion; components in the atmosphere are constantly in flux,
                    and many sensors are also in motion.
                  </p>
                  <p>
                    Scepter simultaneously captures atmospheric data
                    and climate variables throughout the entire vertical air column, from hyperspectral
                    satellite sensors, aircraft, UAV, drone, and both fixed and mobile terrestrial sensors.
                    Multi-sensor, multi-layer capture produces hard, real data, with higher fidelity and
                    greater precision than data captured from a single source or system.
                  </p>

                  <h3>Data types</h3>
                  <dl>
                    <dt>Criteria Air Pollutants</dt>
                    <dd>Carbon monoxide, ground-level ozone, nitrogen dioxide, particulate matter (both 2.5 microns and 10 microns), and sulfur dioxide.</dd>
                    <dt>Greenhouse Gases</dt>
                    <dd>Methane and carbon dioxide</dd>
                    <dt>Atmospheric Climate Variables</dt>
                    <dd>Air temperature, precipitation, solar radiation, wind, water vapor, and cloud properties</dd>
                  </dl>
                </div>
                <a href="https://aerostar.com/" target="_blank">Learn more  <CsIcon name="external" /></a>
              </Modal>
            </SpaceBetween>
          </>,

          logo: { src: logo, alt: 'Service name logo', width: '10px' },
        }}
        // search={
        //   <>
        //     <SpaceBetween direction="horizontal" size="s">
        //       <TextContent>
        //         <p><b>Last refresh:</b> {filterState['lastUpload']}</p>
        //       </TextContent>
        //       {/* <CsButton
        //         variant='link'
        //         onClick={() => fetchDevices()}
        //       ><CsIcon name="refresh" size='small' /></CsButton> */}
        //     </SpaceBetween>

        //   </>

        // }

        utilities={[
          {
            type: 'button',
            iconName: 'file',
            ariaLabel: 'Notifications',
            // badge: true,
            disableUtilityCollapse: true,
            onClick: download
          },
          {
            type: "menu-dropdown",
            iconName: "settings",
            ariaLabel: "Settings",
            title: "Settings",
            items: [
              {
                id: "time-zone",
                text: "Time zone"
              }
            ],
            onItemClick: timezoneFunc
          },
          {
            type: 'menu-dropdown',
            text: user.attributes.email,
            description: user.attributes.email,
            // CHANGE TO THIS FOR PROD
            // text: user.username,
            // description: user.username,
            iconName: 'user-profile',
            items: profileActions,
            onItemClick: escapeFunc
          },
        ]}
      />
      <AppLayout
        stickyNotifications
        toolsHide
        navigationHide
        disableContentPaddings

        ariaLabels={{ navigationClose: 'close' }}
        // navigation={<SideNavigation activeHref="#/pages" items={navItems} />}
        maxContentWidth={Number.MAX_VALUE}
        // breadcrumbs={<BreadcrumbGroup items={breadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />}
        // contentType="table"
        onSplitPanelResize={() => {
          // if (splitPanelPosition=='bottom') {
          setTimeout(function () { map.current.resize() }, 1)
          // }
        }}
        onSplitPanelPreferencesChange={({ detail }) => {
          // console.log(detail)
          setTimeout(function () { map.current.resize() }, 1)
          setSplitPanelPosition(detail.position)
        }
        }
        splitPanelOpen={splitPanelOpen}
        onSplitPanelToggle={({ detail }) => {
          // console.log(detail)
          setSplitPanelOpen(detail.open)
          if (detail.open == false) {
            setTimeout(function () { map.current.resize() }, 1)
          }

        }
        }
        splitPanelPreferences={{ position: splitPanelPosition }}
        splitPanel={<SplitPanel header='Collected Data'
          i18nStrings={{
            preferencesConfirm: 'Confirm',
          }}
        >
          <Tabs
            // disableContentPaddings
            tabs={[
              {
                label: "Ground",
                id: "third",
                content: <>
                  <ExpandableSection headerText="Chart" defaultExpanded>
                    <LineChart
                      series={filterState['measurementSeries']}
                      visibleSeries={filterState['measurementFilterState']}
                      statusType={filterState['measurementLoading']}
                      loadingText="Loading sensor data"
                      onFilterChange={(e) => {
                        // console.log(e.detail.visibleSeries);
                        setFilterState({
                          measurementFilterState: e.detail.visibleSeries,
                          measurementTableData: filterState['measurementTableData'],
                          measurementSeries: filterState['measurementSeries'],
                          measurementLoading: filterState['measurementLoading']
                        })
                      }

                      }
                      xDomain={[
                        new Date(dateFilter + 'T00:00:00Z'),
                        (new Date(new Date(dateFilter).setDate(new Date(dateFilter).getDate() + 1))),
                      ]}
                      xTickFormatter={(e) =>
                        e
                          .toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: !1,
                            timeZone: timeZoneValue.value,
                          })
                          .split(",")
                          .join("\n")
                      }
                      // yDomain={[0, 500000]}
                      i18nStrings={{
                        filterPlaceholder: 'Filter data',
                        // xTickFormatter: e =>
                        //   e
                        //     .toLocaleDateString("en-US", {
                        //       month: "short",
                        //       day: "numeric",
                        //       hour: "numeric",
                        //       minute: "numeric",
                        //       hour12: !1,
                        //       timeZone: timeZoneValue.value,
                        //     })
                        //     .split(",")
                        //     .join("\n"),
                        // yTickFormatter: function o(e) {
                        //   return Math.abs(e) >= 1e9
                        //     ? (e / 1e9).toFixed(1).replace(/\.0$/, "") +
                        //     "G"
                        //     : Math.abs(e) >= 1e6
                        //       ? (e / 1e6).toFixed(1).replace(/\.0$/, "") +
                        //       "M"
                        //       : Math.abs(e) >= 1e3
                        //         ? (e / 1e3).toFixed(1).replace(/\.0$/, "") +
                        //         "K"
                        //         : e.toFixed(2);
                        // }
                      }}
                      ariaLabel="Multiple data series line chart"
                      height={300}
                      xScaleType="time"
                      xTitle={`Time (${timeZoneOptions[timeZoneValue.value]})`}
                      yTitle="Methane emissions (ppm)"
                      additionalFilters={
                        <>
                          <Button onClick={(e) => {
                            setFilterState({
                              measurementFilterState: filterState['measurementSeries'],
                              measurementTableData: filterState['measurementTableData'],
                              measurementSeries: filterState['measurementSeries'],
                              measurementLoading: filterState['measurementLoading']
                            })
                            // setMeasurementsFilterState([])


                          }} variant='primary' >Select all</Button>
                          <Button onClick={(e) => {
                            setFilterState({
                              measurementFilterState: [],
                              measurementTableData: filterState['measurementTableData'],
                              measurementSeries: filterState['measurementSeries'],
                              measurementLoading: filterState['measurementLoading']
                            })


                          }} >Remove all</Button>
                        </>
                      }
                      empty={
                        <Box textAlign="center" color="inherit">
                          <b>No data available</b>
                          <Box variant="p" color="inherit">
                            There is no data available
                          </Box>
                        </Box>
                      }
                      noMatch={
                        <Box textAlign="center" color="inherit">
                          <b>No matching data</b>
                          <Box variant="p" color="inherit">
                            There is no matching data to display
                          </Box>
                          <Button>Clear filter</Button>
                        </Box>
                      }
                    ></LineChart>
                  </ExpandableSection>
                  <ExpandableSection headerText="Table" defaultExpanded>
                    {SoofieMeasurementsTable()}
                  </ExpandableSection>
                </>
              },
              {
                label: "Air",
                id: "first",
                content: <>
                  <ExpandableSection headerText="Visualization controls">
                    <SpaceBetween direction='horizontal' size='m'>
                      <Button onClick={() => {
                        setFilterPlumeTable(allTableData);
                      }}>Include all plumes</Button>
                      <Button variant='primary' iconName='refresh' onClick={() => {
                        // console.log(map.current.getBounds().toArray())
                        // console.log(filterPlumeTable)
                        let boundaryCoords = map.current.getBounds().toArray();

                        let filterList = allTableData.filter(function (i, n) {
                          // console.log(i)
                          // console.log(n.lon >= boundaryCoords[0][0])
                          return (i.lon + soofieOffsetLon >= boundaryCoords[0][0] && i.lon + soofieOffsetLon <= boundaryCoords[1][0] && i.lat + soofieOffsetLat >= boundaryCoords[0][1] && i.lat + soofieOffsetLat <= boundaryCoords[1][1]);
                        });

                        setFilterPlumeTable(filterList)

                      }}>Include plumes visible in current map view</Button>
                      <SpaceBetween direction='horizontal' size='s'>
                        <TextContent><p>Plume opacity</p></TextContent>
                        <SliderField
                          label="Opacity"
                          size='small'
                          max={100}
                          defaultValue={100}
                          onChange={handlePlumeSliderChange}
                          isValueHidden
                          labelHidden
                        />
                      </SpaceBetween>
                    </SpaceBetween>
                  </ExpandableSection>
                  {/* <Header variant="h3">Table controls</Header> */}

                  {/* <Header variant="h3">Plume layer controls</Header> */}
                  <ExpandableSection headerText="Full captures" defaultExpanded={true}>
                    {CatalogCollectionHooksTable()}
                  </ExpandableSection>
                  <ExpandableSection headerText="Detected plumes">
                    {CollectionHooksTable()}
                  </ExpandableSection>

                </>
              },


              {
                label: "Space",
                id: "fourth",
                content: <>
                <ExpandableSection headerText="TROPOMI" defaultExpanded>
                    <TropomiCatalogCollectionHooksTable/>
                  </ExpandableSection>
                  <ExpandableSection headerText="MSI" defaultExpanded>
                    <MsiCatalogCollectionHooksTable defaultExpanded/>
                  </ExpandableSection>
                  <ExpandableSection headerText="Background methane">
                    <Cards
                      // onSelectionChange={({ detail }) =>
                      //   setSelectedItems(detail?.selectedItems ?? [])
                      // }
                      // selectedItems={[{ name: "TROPOMI 0" }, { name: "TROPOMI 1" }]}
                      ariaLabels={{
                        itemSelectionLabel: (e, n) => `select ${n.name}`,
                        selectionGroupLabel: "Item selection"
                      }}
                      cardDefinition={{
                        header: item => (
                          // <Link href="#" fontSize="heading-m">
                          item.name
                          // </Link>
                        ),
                        sections: [
                          {
                            id: "minimum",
                            header: "Minimum",
                            content: item => item.minimum,
                            width: 50
                          },
                          {
                            id: "maximum",
                            header: "Maximum",
                            content: item => item.maximum,
                            width: 50
                          },
                          {
                            id: "mean",
                            header: "Mean",
                            content: item => item.mean,
                            width: 50
                          },
                          {
                            id: "median",
                            header: "Median",
                            content: item => item.median,
                            width: 50
                          },
                          {
                            id: "std",
                            header: "Standard deviation",
                            content: item => item.std,
                            width: 50
                          },
                          {
                            id: "preview",
                            header: "Preview",
                            content: item => item.preview
                          },
                        ]
                      }}
                      cardsPerRow={[
                        { cards: 1 },
                        { minWidth: 500, cards: 2 }
                      ]}
                      items={tropomiCards}
                      // items={[
                      //   {
                      //     name: "TROPOMI 0",
                      //     alt: "First",
                      //     maximum: "2.001 ppm",
                      //     minimum: "1.563 ppm",
                      //     median: "1.857 ppm",
                      //     mean: "1.852 ppm",
                      //     std: "0.047 ppm",
                      //     preview: <img width="400" src='https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/preview?url=https://meeo-s5p.s3.eu-central-1.amazonaws.com/COGT/OFFL/L2__CH4___/2023/09/05/S5P_OFFL_L2__CH4____20230905T145319_20230905T163448_30549_03_020500_20230907T063502_PRODUCT_methane_mixing_ratio_4326.tif&rescale=1600%2C2200&colormap_name=plasma&return_mask=true'></img>
                      //   },
                      //   {
                      //     name: "TROPOMI 1",
                      //     alt: "Second",
                      //     maximum: "1.951 ppm",
                      //     minimum: "1.418 ppm",
                      //     median: "1.851 ppm",
                      //     mean: "1.852 ppm",
                      //     std: "0.050 ppm",
                      //     preview: <img width="400" src='https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/preview?url=https://meeo-s5p.s3.eu-central-1.amazonaws.com/COGT/OFFL/L2__CH4___/2023/09/05/S5P_OFFL_L2__CH4____20230905T163448_20230905T181618_30550_03_020500_20230907T084933_PRODUCT_methane_mixing_ratio_4326.tif&rescale=1600%2C2200&colormap_name=plasma&return_mask=true'></img>
                      //   },
                      // ]}
                      loadingText="Loading resources"
                      // selectionType="multi"
                      trackBy="name"
                      visibleSections={["maximum", "minimum", "median", "mean", "preview"]}
                      empty={
                        <Box
                          margin={{ vertical: "xs" }}
                          textAlign="center"
                          color="inherit"
                        >
                          <SpaceBetween size="m">
                            Sample satellite imagery is available for October 2023. Use the date filter in the left menu to adjust the date selection.
                            {/* <Button>Create resource</Button> */}
                          </SpaceBetween>
                        </Box>
                      }

                    />
                  </ExpandableSection>
                  <ExpandableSection headerText="MSI plumes">
                    <Cards
                      onSelectionChange={({ detail }) => {
                        console.log(detail);
                        setSelectedMsiItems(detail?.selectedItems ?? [])
                        map.current.setLayoutProperty(
                          '2023-10-17',
                          'visibility',
                          'none'
                        );
                        map.current.setLayoutProperty(
                          '2023-11-21',
                          'visibility',
                          'none'
                        );
                        map.current.setLayoutProperty(
                          '2023-11-23',
                          'visibility',
                          'none'
                        );
                        map.current.setLayoutProperty(
                          '2023-12-11',
                          'visibility',
                          'none'
                        );

                        for (let index = 0; index < detail.selectedItems.length; index++) {
                          const element = detail.selectedItems[index];
                          map.current.setLayoutProperty(
                            element.alt,
                            'visibility',
                            'visible'
                          );
                        }
                      }
                      }
                      selectedItems={selectedMsiItems}
                      ariaLabels={{
                        itemSelectionLabel: (e, n) => `select ${n.name}`,
                        selectionGroupLabel: "Item selection"
                      }}
                      cardDefinition={{
                        header: item => (
                          // <Link href="#" fontSize="heading-m">
                          item.name
                          // </Link>
                        ),
                        sections: [
                          // {
                          //   id: "minimum",
                          //   header: "Minimum",
                          //   content: item => item.minimum,
                          //   width: 50
                          // },
                          {
                            id: "maximum",
                            header: "Peak absorbance loss",
                            content: item => item.maximum,
                            width: 50
                          },
                          // {
                          //   id: "mean",
                          //   header: "Mean",
                          //   content: item => item.mean,
                          //   width: 50
                          // },
                          // {
                          //   id: "median",
                          //   header: "Median",
                          //   content: item => item.median,
                          //   width: 50
                          // },
                          // {
                          //   id: "std",
                          //   header: "Standard deviation",
                          //   content: item => item.std,
                          //   width: 50
                          // },
                          // {
                          //   id: "preview",
                          //   header: "Preview",
                          //   content: item => item.preview
                          // },
                        ]
                      }}
                      cardsPerRow={[
                        { cards: 1 },
                        { minWidth: 500, cards: 2 }
                      ]}
                      items={[
                        {
                          name: <Link onFollow={() => map.current.flyTo({
                            center: [
                              -101.83228,
                              32.42963
                            ],
                            zoom: 14
                          })}>2023-10-17</Link>,
                          alt: "2023-10-17",
                          maximum: "4.04%",
                          minimum: "3.157 ppm",
                          median: "3.550 ppm",
                          mean: "3.536 ppm",
                          std: "0.047 ppm",
                          // preview: <img width="200" src={"https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/preview?rescale=3%2C255&colormap_name=plasma&return_mask=false&url=" + encodeURIComponent(signedSentinelUrl)}></img>


                        },
                        {
                          name: <Link onFollow={() => map.current.flyTo({
                            center: [
                              -101.83228,
                              32.42963
                            ], zoom: 14
                          })}>2023-11-21</Link>,
                          alt: "2023-11-21",
                          maximum: "7.66%",
                          minimum: "3.157 ppm",
                          median: "3.550 ppm",
                          mean: "3.536 ppm",
                          std: "0.047 ppm",
                          // preview: <img width="200" src={"https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/preview?rescale=3%2C255&colormap_name=plasma&return_mask=false&url=" + encodeURIComponent(signedSentinelUrl)}></img>
                        },
                        {
                          name: <Link onFollow={() => map.current.flyTo({
                            center: [
                              -101.83228,
                              32.42963
                            ], zoom: 14
                          })}>2023-11-23</Link>,
                          alt: "2023-11-23",
                          maximum: "3.90%",
                          minimum: "3.157 ppm",
                          median: "3.550 ppm",
                          mean: "3.536 ppm",
                          std: "0.047 ppm",
                          // preview: <img width="200" src={"https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/preview?rescale=3%2C255&colormap_name=plasma&return_mask=false&url=" + encodeURIComponent(signedSentinelUrl)}></img>


                        },
                        {
                          name: <Link onFollow={() => map.current.flyTo({
                            center: [
                              -101.83228,
                              32.42963
                            ], zoom: 14
                          })}>2023-12-11</Link>,
                          alt: "2023-12-11",
                          maximum: "4.46%",
                          minimum: "3.157 ppm",
                          median: "3.550 ppm",
                          mean: "3.536 ppm",
                          std: "0.047 ppm",
                          // preview: <img width="200" src={"https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/preview?rescale=3%2C255&colormap_name=plasma&return_mask=false&url=" + encodeURIComponent(signedSentinelUrl)}></img>


                        }
                      ]}
                      loadingText="Loading resources"
                      selectionType="multi"
                      trackBy="alt"
                      visibleSections={["maximum", "minimum", "median", "mean", "preview"]}
                      empty={
                        <Box
                          margin={{ vertical: "xs" }}
                          textAlign="center"
                          color="inherit"
                        >
                          <SpaceBetween size="m">
                            <b>No resources</b>
                            <Button>Create resource</Button>
                          </SpaceBetween>
                        </Box>
                      }
                    />
                  </ExpandableSection>
                  
                  
                  {/* {CollectionHooksTable()} */}
                </>
              },
              // {
              //   label: "Satellite methane plumes",
              //   id: "fifth",
              //   content: <>
              //     {/* {CollectionHooksTable()} */}
              //   </>
              // },
            ]}
          />



        </SplitPanel>}
        content={

          // <div>


          <div ref={mapContainer} className="map-container" >

            <div className="child">

              <SpaceBetween direction='horizontal' size='m'>

                <Container
                // header={
                //   <Header
                //     variant="h2"
                //   // description="Container description"
                //   >
                //     Layers
                //   </Header>
                // }
                >
                  <ScrollView maxHeight='70vh'>


                    <ExpandableSection headerText="Filters">
                      <SpaceBetween direction="vertical">

                        <TextContent>
                          <h5 style={{ color: "orange" }}>Date</h5>
                        </TextContent>
                        <DatePicker className='content-container'
                          onChange={async ({ detail }) => {
                            setDateFilter(detail.value)




                            let soofieDateYear = detail.value.slice(0, 4)
                            let soofieDateMonth = parseInt(detail.value.slice(5, 7)).toString()
                            let soofieDateDay = parseInt(detail.value.slice(8)).toString()
                            const soofieKey = 'soofie/' + soofieDateYear + '/' + soofieDateMonth + '/' + soofieDateDay + '/methane-summary.json'
                            // const soofieKey = 'methane-summary.json'
                            const soofieUrl = await Storage.get(soofieKey, { useAccelerateEndpoint: true, expiration: 28800 });

                            // console.log(soofieUrl)
                            var soofieJson = ''
                            fetch(soofieUrl)
                              .then(res => res.json())
                              .then(out => {

                                map.current.off('click', 'unclustered-soofie', (e) => { console.log(e) })

                                map.current.removeLayer('unclustered-soofie');
                                map.current.removeLayer('soofieClustersLayer');
                                map.current.removeLayer('cluster-count');
                                map.current.removeSource('soofieSource');



                                const popups = document.getElementsByClassName("maplibregl-popup");

                                if (popups.length) {

                                  popups[0].remove();

                                }

                                // console.log(out)
                                soofieJson = out
                                const soofielayer = {
                                  type: 'FeatureCollection',
                                  features:

                                    soofieJson.map((sensor, index) => (
                                      {
                                        type: "Feature",
                                        properties:
                                        {
                                          id: sensor['name'],
                                          stats: sensor['stats'],
                                          index: index,
                                          // site: sensor['site']
                                        },
                                        geometry:
                                        {
                                          type: "Point",
                                          coordinates:
                                            [
                                              sensor['lon'] + soofieOffsetLon,
                                              sensor['lat'] + soofieOffsetLat
                                            ]
                                        }
                                      }
                                    ))
                                  // }
                                };

                                map.current.addSource('soofieSource', {
                                  type: 'geojson',
                                  // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
                                  data: soofielayer,
                                  cluster: true,
                                  clusterRadius: 50,
                                  clusterMaxZoom: 17, // Max zoom to cluster points on
                                });



                                const devLayer = map.current.addLayer({
                                  id: 'soofieClustersLayer',
                                  type: 'circle',
                                  source: 'soofieSource',
                                  // minzoom: 6,
                                  filter: ['has', 'point_count'],
                                  paint: {
                                    // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
                                    // with three steps to implement three types of circles:
                                    //   * Blue, 20px circles when point count is less than 100
                                    //   * Yellow, 30px circles when point count is between 100 and 750
                                    //   * Pink, 40px circles when point count is greater than or equal to 750
                                    'circle-color': [
                                      'step',
                                      ['get', 'point_count'],
                                      '#abf5f2',
                                      5,
                                      '#abf5f2',
                                      20,
                                      '#abf5f2'
                                    ],
                                    'circle-stroke-width': [
                                      'step',
                                      ['get', 'point_count'],
                                      1,
                                      5,
                                      1,
                                      20,
                                      1
                                    ],
                                    'circle-stroke-color': [
                                      'step',
                                      ['get', 'point_count'],
                                      '#000',
                                      5,
                                      '#000',
                                      20,
                                      '#000'
                                    ],
                                    'circle-radius': [
                                      'step',
                                      ['get', 'point_count'],
                                      10,
                                      5,
                                      15,
                                      20,
                                      20
                                    ]
                                  }
                                });

                                // console.log(map)

                                map.current.addLayer({
                                  id: 'cluster-count',
                                  type: 'symbol',
                                  source: 'soofieSource',
                                  filter: ['has', 'point_count'],
                                  layout: {
                                    'text-field': '{point_count_abbreviated}',
                                    'text-font': ['literal', ['Fira GO Regular']],
                                    'text-size': 12
                                  }
                                });


                                map.current.addLayer({
                                  id: 'unclustered-soofie',
                                  type: 'circle',
                                  source: 'soofieSource',
                                  filter: ['!', ['has', 'point_count']],
                                  paint: {
                                    'circle-color': '#abf5f2',
                                    'circle-radius': 7,
                                    'circle-stroke-width': 1,
                                    'circle-stroke-color': '#000'
                                  }
                                });

                                //                 map.current.on('click', 'unclustered-soofie', (e) => {
                                //                   const coordinates = e.features[0].geometry.coordinates.slice();
                                //                   const id = e.features[0].properties.id;
                                //                   const stats = JSON.parse(e.features[0].properties.stats);
                                //                   console.log(stats)
                                //                   // const site = e.features[0].properties.site;
                                //                   // <b>Site:</b> ${site}<br />
                                //                   var text = `

                                //   <b>Name:</b> ${id} <br />
                                //   <b>Max methane:</b> ${stats['max'].toFixed(2)} ppm <br />
                                //   <b>Avg methane:</b> ${stats['avg'].toFixed(2)} ppm <br />
                                //   <b>Samples:</b> ${stats['count']} <br />

                                //   <b>LAT:</b> ${coordinates[1].toFixed(5)}<br />
                                // <b>LON:</b> ${coordinates[0].toFixed(5)} <br />
                                //   `

                                //                   // const mag = e.features[0].properties.mag;
                                //                   // const tsunami =
                                //                   // e.features[0].properties.tsunami === 1 ? 'yes' : 'no';

                                //                   // Ensure that if the map is zoomed out such that
                                //                   // multiple copies of the feature are visible, the
                                //                   // popup appears over the copy being pointed to.
                                //                   while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                                //                     coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                                //                   }

                                //                   new maplibregl.Popup({ offset: 10, closeOnClick: true })
                                //                     .setLngLat(coordinates)
                                //                     // .trackPointer()
                                //                     .setHTML(
                                //                       text
                                //                     )
                                //                     .addTo(map.current);
                                //                 });

                                // map.current.on('click', 'soofieClustersLayer', (e) => {
                                //   console.log(e)
                                //   const features = map.current.queryRenderedFeatures(e.point, {
                                //     layers: ['soofieClustersLayer']
                                //   });
                                //   const clusterId = features[0].properties.cluster_id;
                                //   map.current.getSource('soofieSource').getClusterExpansionZoom(
                                //     clusterId,
                                //     (err, zoom) => {
                                //       if (err) return;

                                //       map.current.easeTo({
                                //         center: features[0].geometry.coordinates,
                                //         zoom: zoom
                                //       });
                                //     }
                                //   );
                                // });
                              })
                              .catch(err => { throw err });
                            function Get(yourUrl) {
                              var Httpreq = new XMLHttpRequest(); // a new request
                              Httpreq.open("GET", yourUrl, false);
                              Httpreq.send(null);
                              return Httpreq.responseText;
                            }

                            for (let index = 0; index < 100; index++) {
                              try {
                                map.current.removeLayer('tropomi' + index.toString())
                                map.current.removeSource('tropomi' + index.toString())
                              } catch (error) {

                              }

                            }
                            let tropomiDateYear = detail.value.slice(0, 4)
                            let tropomiDateMonth = detail.value.slice(5, 7)
                            let tropomiDateDay = detail.value.slice(8)
                            let tropomiPrefix = 'tropomi/' + tropomiDateYear + '/' + tropomiDateMonth + '/' + tropomiDateDay + '/'

                            const tropomiUrls = Storage.list(tropomiPrefix) // for listing ALL files without prefix, pass '' instead
                              .then(({ results }) => {
                                // console.log(results)
                                var tropomiIndex = 0
                                let tropomiCardsTemp = []
                                for (let index = 0; index < results.length; index++) {
                                  if (results[index]['key'].endsWith('ratio_4326.tif')) {
                                    // console.log(results[index]['key'])
                                    const url = Storage.get(results[index]['key'], { useAccelerateEndpoint: true, expiration: 28800 }).then(function (result) {
                                      // console.log(tropomiIndex)
                                      map.current.addSource('tropomi' + index.toString(), {
                                        'type': 'raster',
                                        'tiles': [
                                          "https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?&rescale=1800%2C2400&colormap_name=ylorrd&return_mask=true&url=" + encodeURIComponent(result)
                                        ],
                                      });
                                      map.current.addLayer(
                                        {
                                          'id': 'tropomi' + index.toString(), // Layer ID
                                          'type': 'raster',
                                          'source': 'tropomi' + index.toString(), // ID of the tile source created above
                                          'paint': {
                                            'raster-fade-duration': 0,
                                            'raster-opacity': 0.6
                                            // 'raster-opacity': 0.2
                                          }
                                        },
                                      );

                                      let stats = JSON.parse(Get('https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/statistics?url=' + encodeURIComponent(result)))
                                      let card = {
                                        name: "TROPOMI " + index.toString(),
                                        alt: "TROPOMI " + index.toString(),
                                        maximum: stats['b1']['max'].toFixed(2).toString() + " ppb",
                                        minimum: stats['b1']['min'].toFixed(2).toString() + " ppb",
                                        median: stats['b1']['median'].toFixed(2).toString() + " ppb",
                                        mean: stats['b1']['mean'].toFixed(2).toString() + " ppb",
                                        std: stats['b1']['std'].toFixed(2).toString() + " ppb",
                                        preview: <img width="100%" src={'https://47btg4v5ij.execute-api.us-east-2.amazonaws.com/cog/preview?rescale=1800%2C2400&colormap_name=ylorrd&return_mask=true&url=' + encodeURIComponent(result)}></img>
                                      }
                                      tropomiCardsTemp.push(card)
                                    })

                                  }
                                }
                                setTropomiCards(tropomiCardsTemp)
                              })



                              .catch((err) => console.log(err));
                            setFilterState(
                              {
                                measurementFilterState: [],
                                measurementSeries: [],
                                measurementTableData: [],
                                measurementLoading: 'loading'
                              }
                            )
                            let dateYear = detail.value.slice(0, 4)
                            let dateMonth = parseInt(detail.value.slice(5, 7)).toString()
                            let dateDay = parseInt(detail.value.slice(8)).toString()
                            const measurementsKey = 'soofie/' + dateYear + '/' + dateMonth + '/' + dateDay + '/Methane.json'
                            const measurementsUrl = await Storage.get(measurementsKey, { useAccelerateEndpoint: true, expiration: 28800 });

                            // const measurementsKey = 'measurements.json'
                            // const measurementsUrl = await Storage.get(measurementsKey);

                            // console.log(measurementsUrl)

                            var measurementJson = ''
                            fetch(measurementsUrl)
                              .then(res => res.json())
                              .then(out => {
                                measurementJson = out
                                // console.log(measurementJson)

                                let visTpList = []
                                // const devs = DataStore.query(RecentSource);
                                const visDevs = Object.keys(measurementJson.reduce((r, { name }) => (r[name] = '', r), {}))
                                // console.log(visDevs)
                                // console.log(devs)
                                // console.log(filterState['devices'])

                                for (let i = 0; i < visDevs.length; i++) {


                                  var subset = measurementJson.filter(function (tp) {
                                    return tp['name'] == visDevs[i];
                                  });

                                  if (subset.length > 0) {
                                    // console.log(subset)
                                    let deviceData = {
                                      title: 'sensor-' + i.toString().padStart(2, '0'),
                                      // title: visDevs[i],
                                      type: "line",
                                      data: subset.filter(function (tp) {
                                        if (tp['value'].toString().length > 6) {
                                          // console.log('skipped')
                                          return false; // skip
                                        } else
                                          if (tp['time'].includes(':00:00Z')) {
                                            return false;
                                          }
                                        return true;
                                      }).map((tp, index) => (
                                        {
                                          x: new Date(tp['time']),
                                          y: parseFloat(tp['value'])
                                        }
                                      )
                                      ).sort(function (a, b) {
                                        // Turn your strings into dates, and then subtract them
                                        // to get a value that is either negative, positive, or zero.
                                        return b.x - a.x;
                                      })
                                    }

                                    // console.log(deviceData)

                                    visTpList.push(deviceData)
                                  }
                                }

                                // console.log(visTpList)

                                visTpList.sort(function (a, b) {
                                  if (a.title < b.title) {
                                    return -1;
                                  }
                                  if (a.title > b.title) {
                                    return 1;
                                  }
                                  return 0;
                                });

                                // await new Promise(r => setTimeout(r, 10000));
                                // console.log(flightLineJson)
                                // console.log(flightJson)

                                // await new Promise(r => setTimeout(r, 10000));

                                const measurementTableData = measurementJson.map((tp, index) => (
                                  {
                                    // name: tp['name'],
                                    name: 'sensor-' + index.toString().padStart(2, '0'),
                                    value: tp['value'].toFixed(2),
                                    time: tp['time'],
                                    lat: tp['lat'] + soofieOffsetLat,
                                    lon: tp['lon'] + soofieOffsetLon,
                                    //   link: <Link onFollow={() => map.current.flyTo({
                                    //     center: [
                                    //       tp['lon'] + soofieOffsetLon,
                                    //       tp['lat'] + soofieOffsetLat
                                    //     ],
                                    //     zoom: 17
                                    //     // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                                    //   })}>sensor-{visDevs.indexOf(tp['name']).toString().padStart(2, '0')}</Link>
                                    // }
                                    link: <Link onFollow={() => {
                                      console.log(selectedMeasurement)
                                      // setSelectedMeasurement([
                                      //   {
                                      //     name: tp['name'],
                                      //     value: tp['value'].toFixed(2),
                                      //     time: (new Date(tp['time'])).toLocaleString("en-US", {
                                      //       timeZone: timeZoneValue.value,
                                      //       timeZoneName: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit',
                                      //       minute: '2-digit'
                                      //     }),
                                      //     // sensor-{visDevs.indexOf(tp['name']).toString().padStart(2, '0')}
                                      //     link: <Link onFollow={() => {
                                      //       map.current.flyTo({
                                      //         center: [
                                      //           tp['lon'] + soofieOffsetLon,
                                      //           tp['lat'] + soofieOffsetLat
                                      //         ],
                                      //         zoom: 17
                                      //         // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                                      //       })
                                      //     }}>
                                      //       {tp['name']}
                                      //     </Link>
                                      //   }
                                      // ])
                                      map.current.flyTo({
                                        center: [
                                          tp['lon'] + soofieOffsetLon,
                                          tp['lat'] + soofieOffsetLat
                                        ],
                                        zoom: 17
                                        // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                                      })


                                    }}>{tp['name']}</Link>
                                  }
                                )
                                ).sort(function (a, b) {
                                  // Turn your strings into dates, and then subtract them
                                  // to get a value that is either negative, positive, or zero.
                                  return b.value - a.value;
                                })



                                setFilterState(
                                  {
                                    measurementFilterState: visTpList,
                                    measurementSeries: visTpList,
                                    measurementTableData: measurementTableData,
                                    measurementLoading: 'finished'
                                  }
                                )

                                // console.log(filterState)


                              })
                              .catch(err => { throw err });

                          }}
                          value={dateFilter
                          }
                          // expandToViewport

                          openCalendarAriaLabel={selectedDate =>
                            "Choose certificate expiry date" +
                            (selectedDate
                              ? `, selected date is ${selectedDate}`
                              : "")
                          }
                          placeholder="YYYY/MM/DD"
                        />

                      </SpaceBetween>
                      <SpaceBetween size="xs">
                        <TextContent>
                          <h5 style={{ color: "orange" }}>Region</h5>
                        </TextContent>
                        <Select
                          // disabled
                          selectedOption={selectedRegion}
                          onChange={({ detail }) => {
                            map.current.flyTo({
                              center: [
                                detail.selectedOption.position.lng,
                                detail.selectedOption.position.lat
                              ],
                              zoom: detail.selectedOption.position.zoom
                              // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                            })
                            setSelectedRegion(detail.selectedOption)
                            setSelectedField({ label: "All", value: "all" })
                            setSelectedSite({ label: "All", value: "all" })

                          }
                          }
                          options={[

                            { label: "All", value: "all", position: { lng: -103.80685014, lat: 32.10275163, zoom: 4 } },
                            { label: "Permian", value: "permian", position: { lng: -102.08570, lat: 31.87546, zoom: 6 } },
                            { label: "Bakken", value: "bakken", position: { lng: -103.12897, lat: 47.84681, zoom: 6 } }

                          ]}
                        />
                        <TextContent>
                          <h5 style={{ color: "orange" }}>Field</h5>
                        </TextContent>
                        <Select
                          // disabled
                          selectedOption={selectedField}
                          onChange={({ detail }) => {
                            map.current.flyTo({
                              center: [
                                detail.selectedOption.position.lng,
                                detail.selectedOption.position.lat
                              ],
                              zoom: detail.selectedOption.position.zoom
                              // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                            })
                            setSelectedField(detail.selectedOption)
                            setSelectedRegion({ label: "Permian", value: "permian" })
                            setSelectedSite({ label: "All", value: "all" })
                          }
                          }
                          options={[
                            { label: "All", value: "all", position: { lng: -103.80685014, lat: 32.10275163, zoom: 4 } },
                            { label: "Field 1", value: "field1", position: { lng: -103.87908, lat: 32.16824, zoom: 9 } },
                            { label: "Field 2", value: "field2", position: { lng: -102.08570, lat: 31.87546, zoom: 9 } },
                            { label: "Field 3", value: "field3", position: { lng: -96.27519, lat: 31.50092, zoom: 9 } },
                          ]}
                        />
                        <TextContent>
                          <h5 style={{ color: "orange" }}>Site</h5>
                        </TextContent>
                        <Select
                          // disabled
                          selectedOption={selectedSite}
                          onChange={({ detail }) => {
                            // this.setState({
                            //   selectedOption: detail.selectedOption,
                            // });
                            // console.log(filterState);
                            // var filterUpdate = filterState;
                            // console.log(detail.selectedOption);
                            // console.log(filterUpdate)
                            // filterState['site'] = detail.selectedOption;
                            // console.log(filterUpda);
                            // setFilterState({site: detail.selectedOption});
                            // setSelectedSite(detail.selectedOption)
                            map.current.flyTo({
                              center: [
                                // parseFloat(detail.selectedOption.position.lng.slice(1)),
                                parseFloat(detail.selectedOption.position.lng),
                                detail.selectedOption.position.lat
                              ],
                              zoom: detail.selectedOption.position.zoom
                              // essential: true // this animation is considered essential with respect to prefers-reduced-motion
                            })
                            setSelectedSite({ label: detail.selectedOption.label, value: detail.selectedOption.value });
                            setSelectedRegion({ label: "Permian", value: "permian" })
                          }
                          }
                          options={siteOptions}
                        // selectedOption={filterState['site']}
                        // onChange={({ detail }) => {
                        //   // this.setState({
                        //   //   selectedOption: detail.selectedOption,
                        //   // });
                        //   // console.log(filterState);
                        //   // var filterUpdate = filterState;
                        //   // console.log(detail.selectedOption);
                        //   // console.log(filterUpdate)
                        //   // filterState['site'] = detail.selectedOption;
                        //   // console.log(filterUpda);
                        //   // setFilterState({site: detail.selectedOption});
                        //   // setSelectedSite(detail.selectedOption)

                        //   updateSiteSelect(detail.selectedOption);

                        // }
                        // }
                        // options={
                        //   filterState['sites'].map((site, index) => (
                        //     { label: site['name'], value: site }
                        //     // fontSize='1.5vh'
                        //   ))
                        // }
                        />



                        {/* 
                <Checkbox
                  onChange={({ detail }) =>
                  {
                    showComponents(detail.checked)
                    setFilterState({componentsChecked: detail.checked});
                  }
                }
                checked={filterState['componentsChecked']}
                >
                  Components
                </Checkbox>
                */}

                      </SpaceBetween>
                    </ExpandableSection>
                    <ExpandableSection headerText='Layers'>
                      <Flex justifyContent='space-between'>
                        {/* <SpaceBetween direction='horizontal' size='s'> */}
                        <TextContent>
                          <h4 >Oil and Gas Infrastructure</h4>
                        </TextContent>
                        {/* <Link
                        onFollow={() =>
                          setGroundInfoVis(true)
                        }
                      >
                        <CsIcon name='status-info' />
                      </Link> */}


                        {/* </SpaceBetween> */}
                      </Flex>
                      <Flex justifyContent='space-between'>


                        <SpaceBetween direction='horizontal' size='s'>
                          <Checkbox onChange={({ detail }) =>
                            setWellsVis(detail.checked)
                          }
                            checked={checkedWells}>Natural Gas Wells</Checkbox>
                          {/* <Checkbox
                          disabled
                        // checked={filterState['sensorsChecked']}
                        // onChange={({ detail }) => {
                        //   showDevices(detail.checked)
                        //   // setFilterState({sensorsChecked: detail.checked});

                        //   // {
                        //   //   viewProps: {
                        //   //     lat: 32.10275163,
                        //   //     lon: -103.80685014,
                        //   //     zoom: 7
                        //   //   },
                        //   //   region: {
                        //   //     label: "Permian",
                        //   //     value: "permian"
                        //   //   },
                        //   //   field: {
                        //   //     label: "Field 1",
                        //   //     value: "field 1"
                        //   //   },
                        //   //   site: {
                        //   //     label: "Darlene",
                        //   //     value: "d36689f0-23dd-4064-918c-3f2c9c9ae779"
                        //   //   },
                        //   //   sensorsChecked: true,
                        //   //   alertsChecked: true,
                        //   //   windChecked: false,
                        //   //   componentsChecked: false,
                        //   //   noaaWindChecked: false 
                        //   // }

                        //   // this.checked = detail.checked
                        // }
                        // }
                        // checked={checked}
                        >
                          Sensors
                        </Checkbox> */}
                        </SpaceBetween>
                        <Button iconName="angle-up" variant="inline-icon"
                          onClick={() => {
                            map.current.moveLayer('oilGasWellClustersLayer');
                            map.current.moveLayer('unclustered-oil-gas-well');
                            map.current.moveLayer('oil-gas-well-cluster-count');

                          }}
                        ></Button>

                      </Flex>
                      <Flex justifyContent='space-between'>


                        <SpaceBetween direction='horizontal' size='s'>
                          <Checkbox onChange={({ detail }) =>
                            setTexasPipesVis(detail.checked)
                          }
                            checked={checkedPipes}>Natural Gas Pipelines</Checkbox>
                        </SpaceBetween>
                        <Button iconName="angle-up" variant="inline-icon"
                          onClick={() => {
                            map.current.moveLayer('texasPipesLayer');
                          }}
                        ></Button>

                      </Flex>
                      <Flex justifyContent='space-between'>


                        <SpaceBetween direction='horizontal' size='s'>
                          <Checkbox onChange={({ detail }) =>
                            setLandfillsVis(detail.checked)
                          }
                            checked={checkedLandfills}>Landfills</Checkbox>
                        </SpaceBetween>
                        <Button iconName="angle-up" variant="inline-icon"
                          onClick={() => {
                            map.current.moveLayer('landfillsClustersLayer');
                            map.current.moveLayer('unclustered-landfills');
                            map.current.moveLayer('landfills-cluster-count');
                          }}
                        ></Button>

                      </Flex>
                      <Flex justifyContent='space-between'>
                        {/* <SpaceBetween direction='horizontal' size='s'> */}
                        <TextContent>
                          <h4 >Ground</h4>
                        </TextContent>
                        <Link
                          onFollow={() =>
                            setGroundInfoVis(true)
                          }
                        >
                          <CsIcon name='status-info' />
                        </Link>

                        <Modal
                          onDismiss={() => setGroundInfoVis(false)}
                          visible={groundInfoVis}
                          footer={
                            <Box float="left">
                              <TextContent>
                                <b>Last data update:</b> 2023-09-05T23:14:59Z
                              </TextContent>
                              {/* <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link">Cancel</Button>
                        <Button variant="primary">Ok</Button>
                      </SpaceBetween> */}
                            </Box>
                          }
                          header="Systematic Observations of Facility Intermittent Emissions (SOOFIE)"
                        >
                          <p>
                            The SOOFIE system is a ground-based technology that provides continuous emissions monitoring and immediately alerts operators about potential concerns. This rapid, reliable, and affordable system is an all-in-one solution taht can monitor oil and gas facilities, 24 hours per day, 365 days per year through a solar-powered on-site detection unit that leverates cloud-based servers and AI for analysis and reporting.
                          </p>
                          <a href="https://www.championx.com/contents/Scientific%20Aviation%20brochure_compressed.pdf" target="_blank">Learn more  <CsIcon name="external" /></a>
                        </Modal>
                        {/* </SpaceBetween> */}
                      </Flex>

                      <Flex justifyContent='space-between'>


                        <SpaceBetween direction='horizontal' size='s'>
                          <Checkbox onChange={({ detail }) =>
                            setGroundSensorsVis(detail.checked)
                          }
                            checked={checkedGroundSensors}>Ground Sensors</Checkbox>
                          {/* <Checkbox
                          disabled
                        // checked={filterState['sensorsChecked']}
                        // onChange={({ detail }) => {
                        //   showDevices(detail.checked)
                        //   // setFilterState({sensorsChecked: detail.checked});

                        //   // {
                        //   //   viewProps: {
                        //   //     lat: 32.10275163,
                        //   //     lon: -103.80685014,
                        //   //     zoom: 7
                        //   //   },
                        //   //   region: {
                        //   //     label: "Permian",
                        //   //     value: "permian"
                        //   //   },
                        //   //   field: {
                        //   //     label: "Field 1",
                        //   //     value: "field 1"
                        //   //   },
                        //   //   site: {
                        //   //     label: "Darlene",
                        //   //     value: "d36689f0-23dd-4064-918c-3f2c9c9ae779"
                        //   //   },
                        //   //   sensorsChecked: true,
                        //   //   alertsChecked: true,
                        //   //   windChecked: false,
                        //   //   componentsChecked: false,
                        //   //   noaaWindChecked: false 
                        //   // }

                        //   // this.checked = detail.checked
                        // }
                        // }
                        // checked={checked}
                        >
                          Sensors
                        </Checkbox> */}
                        </SpaceBetween>
                        <Button iconName="angle-up" variant="inline-icon"
                          onClick={() => {
                            map.current.moveLayer('soofieClustersLayer');
                            map.current.moveLayer('unclustered-soofie');
                            map.current.moveLayer('cluster-count');

                          }}
                        ></Button>

                      </Flex>
                      {/* <Flex justifyContent='space-between'>
                        <SpaceBetween direction='horizontal' size='s'>
                          <Checkbox
                            disabled
                          // onChange={({ detail }) => {
                          //   showAlerts(detail.checked)
                          //   // setFilterState({
                          //   //   alertsChecked: detail.checked,
                          //   //   alertMarkers: filterState['alertMarkers']
                          //   // });
                          // }
                          // }
                          // checked={filterState['alertsChecked']}
                          >
                            <img src='red-16.png' width='15vw' />  Methane Alerts

                          </Checkbox>
                        </SpaceBetween>

                        <Button iconName="angle-up" variant="inline-icon"
                        // onClick={() => {
                        //   map.current.moveLayer('alerts');
                        //   map.current.moveLayer('unclustered-alert');
                        // }}
                        ></Button>
                      </Flex> */}
                      {/* <SpaceBetween direction='horizontal' size='s'>
                        <Checkbox
                          disabled
                        // onChange={({ detail }) => {
                        //   showSoofieWind(detail.checked)
                        //   setFilterState({ windChecked: detail.checked });
                        // }
                        // }
                        // checked={filterState['windChecked']}
                        >
                          <img src='https://upload.wikimedia.org/wikipedia/commons/f/f6/Kars_Arrow.png' width='15vw' height='10px' /> Average Wind
                        </Checkbox>
                      </SpaceBetween> */}
                      <Checkbox onChange={({ detail }) =>
                        setTerrainVis(detail.checked)
                      }
                        checked={checkedTerrain}>3D Terrain</Checkbox>
                      {/* <SpaceBetween direction='horizontal' size='s'> */}
                      <Flex justifyContent='space-between'>
                        {/* <SpaceBetween direction='horizontal' size='s'> */}
                        <TextContent>
                          <h4 >Air</h4>
                        </TextContent>
                        <Link
                          onFollow={() =>
                            setBalloonInfoVis(true)
                          }
                        >
                          <CsIcon name='status-info' />
                        </Link>

                        <Modal
                          onDismiss={() => setBalloonInfoVis(false)}
                          visible={balloonInfoVis}
                          footer={
                            <Box float="left">
                              <TextContent>
                                <b>Last data update:</b> 2023-09-05T23:14:59Z
                              </TextContent>
                              {/* <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link">Cancel</Button>
                        <Button variant="primary">Ok</Button>
                      </SpaceBetween> */}
                            </Box>
                          }
                          header="Aerostar Balloon"
                        >
                          <p>
                            Info about Aerostar balloon.
                          </p>
                          <a href="https://aerostar.com/" target="_blank">Learn more  <CsIcon name="external" /></a>
                        </Modal>
                        {/* </SpaceBetween> */}
                      </Flex>



                      {/* </SpaceBetween> */}
                      <Flex justifyContent='space-between'>
                        <Checkbox onChange={({ detail }) =>
                          set2DBalloonVis(detail.checked)
                        }
                          checked={checked2DBalloon}>Flight 3 | 2D Balloon Track</Checkbox>
                        <Button iconName="angle-up" variant="inline-icon"
                          onClick={() => {
                            map.current.moveLayer('flightLineLayer');
                            // map.current.moveLayer('unclustered-alert');
                          }}
                        ></Button>
                      </Flex>
                      <Flex justifyContent='space-between'>
                        <Checkbox onChange={({ detail }) =>
                          set3DBalloonVis(detail.checked)
                        }
                          checked={checked3DBalloon}>Flight 3 | 3D Balloon Track</Checkbox>
                        <Button iconName="angle-up" variant="inline-icon"
                          onClick={() => {
                            map.current.moveLayer('flightLayer');
                            // map.current.moveLayer('unclustered-alert');
                          }}
                        ></Button>
                      </Flex>
                      <Flex justifyContent='space-between'>
                        <Checkbox onChange={({ detail }) =>
                          set2DBalloonVis_flight2(detail.checked)
                        }
                          checked={checked2DBalloon_flight2}>Flight 2 | 2D Balloon Track</Checkbox>
                        <Button iconName="angle-up" variant="inline-icon"
                          onClick={() => {
                            map.current.moveLayer('flightLine2Layer');
                            // map.current.moveLayer('unclustered-alert');
                          }}
                        ></Button>
                      </Flex>
                      <Flex justifyContent='space-between'>
                        <Checkbox onChange={({ detail }) =>
                          set3DBalloonVis_flight2(detail.checked)
                        }
                          checked={checked3DBalloon_flight2}>Flight 2 | 3D Balloon Track</Checkbox>
                        <Button iconName="angle-up" variant="inline-icon"
                          onClick={() => {
                            map.current.moveLayer('flight2Layer');
                            // map.current.moveLayer('unclustered-alert');
                          }}
                        ></Button>
                      </Flex>
                      {/* <Flex justifyContent='space-between'>
                          <Checkbox onChange={({ detail }) =>
                            setBalloonPlumeVis(detail.checked)
                          }
                            checked={checkedBalloonPlume}>Methane Plumes</Checkbox>
                          <Button iconName="angle-up" variant="inline-icon"
                            onClick={() => {
                              map.current.moveLayer('balloon1');
                              map.current.moveLayer('balloon2');
                              map.current.moveLayer('balloon3');
                              map.current.moveLayer('balloon4');
                              map.current.moveLayer('balloon5');
                              map.current.moveLayer('balloon6');
                              map.current.moveLayer('balloon8');
                              map.current.moveLayer('balloon9');
                              map.current.moveLayer('balloon10');
                              map.current.moveLayer('balloon11');
                              map.current.moveLayer('balloon12');
                              map.current.moveLayer('balloon13');
                              map.current.moveLayer('balloon14');
                              map.current.moveLayer('balloon15');
                              map.current.moveLayer('balloon16');
                              map.current.moveLayer('balloon17');
                              map.current.moveLayer('balloon18');
                              map.current.moveLayer('balloon19');
                              map.current.moveLayer('balloon20');

                              // map.current.moveLayer('unclustered-alert');
                            }}
                          ></Button>
                        </Flex> */}
                      {/* <SpaceBetween direction='horizontal' size='s'>
                          <TextContent><p><small>Opacity</small></p></TextContent>
                          <SliderField
                            label="Opacity"
                            size='small'
                            max={100}
                            defaultValue={60}
                            onChange={handlePlumeSliderChange}
                            isValueHidden
                            labelHidden
                          />
                        </SpaceBetween> */}
                      <Flex justifyContent='space-between'>


                        <SpaceBetween direction='horizontal' size='s'>
                          <Checkbox onChange={({ detail }) =>
                            setPlumeMarkerVis(detail.checked)
                          }
                            checked={checkedPlumeMarkers}>Flight 2 | Plume Markers</Checkbox>

                        </SpaceBetween>
                        <Button iconName="angle-up" variant="inline-icon"
                          onClick={() => {
                            map.current.moveLayer('plumeClustersLayer');
                            map.current.moveLayer('unclustered-plume');
                            map.current.moveLayer('plume-cluster-count');
                          }}
                        ></Button>

                      </Flex>

                      {/* <SpaceBetween direction='horizontal' size='s'> */}
                      <Flex justifyContent='space-between'>
                        <TextContent>
                          <h4 >Space</h4>
                        </TextContent>
                        <Link
                          onFollow={() =>
                            setSatelliteInfoVis(true)
                          }
                        >
                          <CsIcon name='status-info' />
                        </Link>

                        <Modal
                          onDismiss={() => setSatelliteInfoVis(false)}
                          visible={satelliteInfoVis}
                          footer={
                            <Box float="left">
                              <TextContent>
                                <b>Last data update:</b> 2023-09-05T23:14:59Z
                              </TextContent>
                              {/* <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link">Cancel</Button>
                        <Button variant="primary">Ok</Button>
                      </SpaceBetween> */}
                            </Box>
                          }
                          header="Sentinel-5 Precursor Level 2 Methane"
                        >
                          <p>
                            The output product consists of the retrieved methane column and a row vector referred to as the column averaging kernel A. The column averaging kernel describes how the retrieved column relates to the true profile and should be used in validation exercises (when possible) or use of the product in source/sink inverse modelling. The output product also contains altitude levels of the layer interfaces to which the column averaging kernel corresponds. Additional output for Level-2 data products: viewing geometry, precision of retrieved methane, residuals of the fit, quality flags (cloudiness, terrain roughness etc.) and retrieved albedo and aerosol properties. The latter properties are required for a posteriori filtering and for estimation of total retrieval error.
                          </p>
                          <a href="https://sentinels.copernicus.eu/web/sentinel/data-products/-/asset_publisher/fp37fc19FN8F/content/tropomi-level-2-methane" target="_blank">Learn more  <CsIcon name="external" /></a>
                        </Modal>
                      </Flex>
                      <Flex justifyContent='space-between'>
                        <Checkbox onChange={({ detail }) =>
                          setSentinel5pVis(detail.checked)
                        }
                          checked={checkedSentinel5p}>Sentinel 5P Ground Track</Checkbox>
                        <Button iconName="angle-up" variant="inline-icon"
                          onClick={() => {
                            map.current.moveLayer('sentinel5pLayer');
                            // map.current.moveLayer('unclustered-alert');
                          }}
                        ></Button>
                      </Flex>
                      <Flex justifyContent='space-between'>
                        <Checkbox onChange={({ detail }) =>
                          setSentinel2aVis(detail.checked)
                        }
                          checked={checkedSentinel2a}>Sentinel 2A Ground Track</Checkbox>
                        <Button iconName="angle-up" variant="inline-icon"
                          onClick={() => {
                            map.current.moveLayer('sentinel2aLayer');
                            // map.current.moveLayer('unclustered-alert');
                          }}
                        ></Button>
                      </Flex>
                      <Flex justifyContent='space-between'>
                        <Checkbox onChange={({ detail }) =>
                          setSentinel2bVis(detail.checked)
                        }
                          checked={checkedSentinel2b}>Sentinel 2B Ground Track</Checkbox>
                        <Button iconName="angle-up" variant="inline-icon"
                          onClick={() => {
                            map.current.moveLayer('sentinel2bLayer');
                            // map.current.moveLayer('unclustered-alert');
                          }}
                        ></Button>
                      </Flex>
                      {/* </SpaceBetween> */}
                      <Flex justifyContent='space-between'>
                        <SpaceBetween direction='horizontal' size='s'>
                          <Checkbox
                            onChange={({ detail }) => {
                              setTropomiChecked(detail.checked)
                              const tropomiLayers = map.current.getStyle().layers
                              // console.log(tropomiLayers)
                              tropomiLayers.forEach(element => {
                                const layerId = element['id']
                                if (layerId.includes('tropomi')) {
                                  if (detail.checked == true) {
                                    map.current.setLayoutProperty(
                                      element['id'],
                                      'visibility',
                                      'visible'
                                    );
                                  } else {
                                    map.current.setLayoutProperty(
                                      element['id'],
                                      'visibility',
                                      'none'
                                    );
                                  }

                                }
                              });

                            }
                            }
                            // disabled
                            checked={tropomiChecked}
                          >
                            Background Methane
                          </Checkbox>
                        </SpaceBetween>
                        <Button iconName="angle-up" variant="inline-icon"
                          onClick={() => {
                            const tropomiLayers = map.current.getStyle().layers
                            // console.log(tropomiLayers)
                            tropomiLayers.forEach(element => {
                              const layerId = element['id']
                              if (layerId.includes('tropomi')) {
                                map.current.moveLayer(element['id'])
                              }
                            });
                            // map.current.moveLayer('tropomi0');
                            // map.current.moveLayer('tropomi1');
                            // map.current.moveLayer('tropomi2');
                            // map.current.moveLayer('tropomi3');
                            // map.current.moveLayer('tropomi4');
                            // map.current.moveLayer('tropomi5');
                            // map.current.moveLayer('tropomi6');
                            // map.current.moveLayer('tropomi7');
                            // map.current.moveLayer('tropomi8');
                            // map.current.moveLayer('tropomi9');
                            // map.current.moveLayer('tropomi10');
                            // map.current.moveLayer('tropomi11');
                            // map.current.moveLayer('tropomi12');
                            // map.current.moveLayer('tropomi13');
                          }}
                        ></Button>
                      </Flex>

                      <SpaceBetween direction='horizontal' size='s'>
                        <TextContent><p><small>Opacity</small></p></TextContent>
                        <SliderField
                          label="Opacity"
                          size='small'
                          max={100}
                          defaultValue={60}
                          onChange={handleSliderChange}
                          isValueHidden
                          labelHidden
                        />
                      </SpaceBetween>
                      <Flex justifyContent='space-between'>
                        {/* <SpaceBetween direction='horizontal' size='s'>
                        <Checkbox
                          onChange={({ detail }) => {
                            setPlumeChecked(detail.checked)

                            if (detail.checked == true) {
                              map.current.setLayoutProperty(
                                'sentinel',
                                'visibility',
                                'visible'
                              );
                            } else {
                              map.current.setLayoutProperty(
                                'sentinel',
                                'visibility',
                                'none'
                              );
                            }

                            // setFilterState({
                            //   alertsChecked: detail.checked,
                            //   alertMarkers: filterState['alertMarkers']
                            // });
                          }
                          }
                          // disabled
                          checked={plumeChecked}
                        >
                          Methane Plumes
                        </Checkbox>
                      </SpaceBetween>
                      <Button iconName="angle-up" variant="inline-icon"
                        onClick={() => {
                          map.current.moveLayer('sentinel');
                        }}
                      ></Button> */}

                      </Flex>



                    </ExpandableSection>
                    <ExpandableSection headerText="Color key">
                      <SpaceBetween direction="vertical">
                        <TextContent>
                          <h5 style={{ color: "orange" }}>Methane plumes (g/m^2)</h5>
                        </TextContent>
                        <SpaceBetween direction="horizontal" size="s">
                          <TextContent>
                            {/* <p ><strong>1.6</strong></p> */}
                            <p ><strong>9.0</strong></p>
                          </TextContent>
                          <Image
                            alt="Color bar"
                            src="/colorbar.png"
                            // objectFit="initial"
                            // objectPosition="50% 50%"
                            // backgroundColor="initial"
                            height="10px"
                            minHeight="10px"
                            width="5vw"
                            minWidth="100px"
                          // opacity="100%"
                          // onClick={() => alert('📸 Say cheese!')}
                          />
                          <TextContent>
                            {/* <p ><strong>3.9</strong></p> */}
                            <p ><strong>10.0</strong></p>
                          </TextContent>
                        </SpaceBetween>
                        <TextContent>
                          <h5 style={{ color: "orange" }}>Background methane (ppm)</h5>
                        </TextContent>
                        <SpaceBetween direction="horizontal" size="s">
                          <TextContent>
                            {/* <p ><strong>1.6</strong></p> */}
                            <p ><strong>1.8</strong></p>
                          </TextContent>
                          <Image
                            alt="Color bar"
                            src="/reds.png"
                            // objectFit="initial"
                            // objectPosition="50% 50%"
                            // backgroundColor="initial"
                            height="10px"
                            minHeight="10px"
                            width="5vw"
                            minWidth="100px"
                          // opacity="100%"
                          // onClick={() => alert('📸 Say cheese!')}
                          />
                          <TextContent>
                            {/* <p ><strong>3.9</strong></p> */}
                            <p ><strong>2.4</strong></p>
                          </TextContent>
                        </SpaceBetween>
                        <TextContent>
                          <h5 style={{ color: "orange" }}>Ground sensors</h5>
                        </TextContent>


                        <SpaceBetween direction="horizontal" size="s">
                          <Image
                            alt="Cluster sizes"
                            src="/cluster_size.png"
                            // objectFit="initial"
                            // objectPosition="50% 50%"
                            // backgroundColor="initial"
                            // height="55px"
                            // minHeight="10px"
                            width="6vw"
                            minWidth="200px"
                          // opacity="100%"
                          // onClick={() => alert('📸 Say cheese!')}
                          />
                        </SpaceBetween>
                        <TextContent>
                          <h5 style={{ color: "orange" }}>Plume markers</h5>
                        </TextContent>
                        <SpaceBetween direction="horizontal" size="s">
                          <Image
                            alt="Cluster sizes"
                            src="/redmarkers.png"
                            // objectFit="initial"
                            // objectPosition="50% 50%"
                            // backgroundColor="initial"
                            // height="55px"
                            // minHeight="10px"
                            width="6vw"
                            minWidth="200px"
                          // opacity="100%"
                          // onClick={() => alert('📸 Say cheese!')}
                          />
                        </SpaceBetween>
                        <TextContent>
                          <h5 style={{ color: "orange" }}>Natural Gas Wells</h5>
                        </TextContent>
                        <SpaceBetween direction="horizontal" size="s">
                          <Image
                            alt="Cluster sizes"
                            src="/yellow-clusters.png"
                            // objectFit="initial"
                            // objectPosition="50% 50%"
                            // backgroundColor="initial"
                            // height="55px"
                            // minHeight="10px"
                            width="6vw"
                            minWidth="200px"
                          // opacity="100%"
                          // onClick={() => alert('📸 Say cheese!')}
                          />
                        </SpaceBetween>

                        <TextContent>
                          <h5 style={{ color: "orange" }}>Landfills</h5>
                        </TextContent>
                        <SpaceBetween direction="horizontal" size="s">
                          <Image
                            alt="Cluster sizes"
                            src="/orange-clusters.png"
                            // objectFit="initial"
                            // objectPosition="50% 50%"
                            // backgroundColor="initial"
                            // height="55px"
                            // minHeight="10px"
                            width="6vw"
                            minWidth="200px"
                          // opacity="100%"
                          // onClick={() => alert('📸 Say cheese!')}
                          />
                        </SpaceBetween>
                        <TextContent>
                          <h5 style={{ color: "orange" }}>Natural Gas Pipelines (over 50 km)</h5>
                        </TextContent>
                        <hr size="20" color="yellow"></hr>
                        <TextContent>
                          <h5 style={{ color: "orange" }}>Balloon Path</h5>
                        </TextContent>
                        <hr size="20" color="cyan"></hr>


                      </SpaceBetween>
                      {/* <SpaceBetween direction="vertical">
                        <TextContent>
                          <h5 style={{ color: "orange" }}>Methane Plume Scale (ppm)</h5>
                        </TextContent>
                        <SpaceBetween direction="horizontal" size="s">
                          <TextContent>
                            <p ><strong>2.2</strong></p>
                          </TextContent>
                          <Image
                            alt="Color bar"
                            src="/winterbar.png"
                            // objectFit="initial"
                            // objectPosition="50% 50%"
                            // backgroundColor="initial"
                            height="30px"
                            width="300px"
                          // opacity="100%"
                          // onClick={() => alert('📸 Say cheese!')}
                          />
                          <TextContent>
                            <p ><strong>3.9</strong></p>
                          </TextContent>
                        </SpaceBetween> */}


                      {/* </SpaceBetween> */}
                    </ExpandableSection>

                    {/* <FormField
                            label="Certificate expiry"
                            constraintText="Use YYYY/MM/DD format."
                          > */}


                    {/* </FormField> */}

                  </ScrollView>
                </Container>

                <Container>

                  <TextContent>
                    <strong>Lon:</strong> {cursorCoords['lng'].toFixed(5)}, <strong>Lat: </strong>{cursorCoords['lat'].toFixed(5)}
                  </TextContent>
                </Container>

              </SpaceBetween>
            </div>
          </div>
          // </div>
          // </Grid>





        }

        tools={
          <HelpPanel
            footer={
              <div>
                <h3>
                  Learn more <CsIcon name="external" />
                </h3>
                <ul>
                  <li>
                    <a href="https://scepterair.com/technology/" target="_blank">Link to documentation</a>
                  </li>
                </ul>
              </div>
            }
            header={<h2>Multi-sensor Monitoring within the Dynamic Vertical Air Column</h2>}
          >
            <div>
              <p>
                Scepter monitors global atmospheric components and ingests data from a wide range
                of reliable, verifiable, independent and third-party atmospheric monitoring providers.
                The Scepter multi-sensor system captures data in the dynamic, multidimensional airspace.
                This is monitoring in motion; components in the atmosphere are constantly in flux,
                and many sensors are also in motion.
              </p>
              <p>
                Scepter simultaneously captures atmospheric data
                and climate variables throughout the entire vertical air column, from hyperspectral
                satellite sensors, aircraft, UAV, drone, and both fixed and mobile terrestrial sensors.
                Multi-sensor, multi-layer capture produces hard, real data, with higher fidelity and
                greater precision than data captured from a single source or system.
              </p>

              <h3>Data types</h3>
              <dl>
                <dt>Criteria Air Pollutants</dt>
                <dd>Carbon monoxide, ground-level ozone, nitrogen dioxide, particulate matter (both 2.5 microns and 10 microns), and sulfur dioxide.</dd>
                <dt>Greenhouse Gases</dt>
                <dd>Methane and carbon dioxide</dd>
                <dt>Atmospheric Climate Variables</dt>
                <dd>Air temperature, precipitation, solar radiation, wind, water vapor, and cloud properties</dd>
              </dl>
            </div>
          </HelpPanel>
        }
      // notifications={<Notifications />}
      />
    </>
    // {/* </ThemeProvider> */}
  )
}

function UiHeader() {
  const { tokens } = useTheme();

  return (
    <Image
      alt="logo"
      src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
    // padding={tokens.space.medium}
    />
  );
}

function Footer() {
  // const { tokens } = useTheme();

  return (
    <></>
    // <Flex justifyContent="center" padding={tokens.space.medium}>
    //   <Text>&copy; All Rights Reserved</Text>
    // </Flex>
  );
}

function SignInFooter() {
  // const { toResetPassword } = useAuthenticator();
  // const { tokens } = useTheme();

  return (
    <></>
    // <Flex justifyContent="center" padding={`0 0 ${tokens.space.medium}`}>
    //   <Link onClick={toResetPassword}>Reset your password</Link>
    // </Flex>
  );
}

function SignInHeader() {
  // const { tokens } = useTheme();

  return (
    <></>
    // <Heading level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
    //   Sign in to your Account
    // </Heading>
  );
}



const components = {
  Header() {
    // const { tokens } = useTheme();

    return (
      <Box>
        {/* <Flashbar items={[
          {
            type: "info",
            dismissible: false,
            // dismissLabel: "Dismiss message",
            // onDismiss: () => setHeaderItems([]),
            content: (
              <>
                For more information, visit {" "}
                <Link color='inverted' external href="https://sceptercorp.com/">
                  sceptercorp.com
                </Link>
              </>
            ),
            id: "message_1"
          }
        ]} />
         <div>
          <br></br>
        </div> */}
        <Image
          alt="ScepterAir"
          src="/ScepterAir.svg"
          // objectFit="initial"
          // objectPosition="50% 50%"
          // backgroundColor="initial"
          // height="10px"
          // minHeight="10px"
          width="100%"
        // minWidth="100px"
        // opacity="100%"
        // onClick={() => alert('📸 Say cheese!')}
        />
        <div>
          <br></br>
        </div>
        
        {/* // USE THIS FOR PROD
        <Button variant='primary' iconName="lock-private" onClick={() => Auth.federatedSignIn()}>Customer Sign-in Portal</Button>
        <div>
        <br></br>
        </div> */}


        {/* <Button variant='primary' iconName="lock-private" onClick={() => Auth.federatedSignIn()}>Customer Sign-in Portal</Button> */}
        {/* <div> */}
        {/* <br></br> */}
        {/* </div> */}
      </Box>


      // <View textAlign="center" padding={tokens.space.large}>
      //   <Image
      //     alt="Amplify logo"
      //     src="https://docs.amplify.aws/assets/logo-dark.svg"
      //   />
      // </View>
    );
  },
  Footer() {
    // const { tokens } = useTheme();

    return (
      <Box>
        <div>
          <br></br>
        </div>
        <Flashbar items={[
          {
            type: "info",
            dismissible: false,
            // dismissLabel: "Dismiss message",
            // onDismiss: () => setHeaderItems([]),
            content: (
              <>
                For more information, visit {" "}
                <Link color='inverted' external href="https://sceptercorp.com/">
                  sceptercorp.com
                </Link>
              </>
            ),
            id: "message_1"
          }
        ]} />

      </Box>


      // <View textAlign="center" padding={tokens.space.large}>
      //   <Image
      //     alt="Amplify logo"
      //     src="https://docs.amplify.aws/assets/logo-dark.svg"
      //   />
      // </View>
    );
  },
}

export default withAuthenticator(App, { components: components });
