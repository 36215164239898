/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://o5gvy4vxonhm7pdkevyy66fz74.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:82253f80-a16f-4751-acb9-3ea10491b852",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_XUTmMwMuM",
    "aws_user_pools_web_client_id": "7vl9lu72hj1q599sbtp3gbkmv2",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "14",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "scepteruimfa-20230518100238-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d11iry0lr5n29u.cloudfront.net",
    "aws_user_files_s3_bucket": "scepteruimfa05d44e66cc0f4c8d9b43f9fa5979a52f134941-dev",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_mobile_analytics_app_id": "9cf3818b2e6f4bebb573246ee50d70de",
    "aws_mobile_analytics_app_region": "us-east-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "9cf3818b2e6f4bebb573246ee50d70de",
            "region": "us-east-2"
        }
    },
    "geo": {
        "amazon_location_service": {
            "region": "us-east-2",
            "maps": {
                "items": {
                    "berlin-dev": {
                        "style": "VectorHereBerlin"
                    },
                    "dark-dev": {
                        "style": "VectorEsriDarkGrayCanvas"
                    },
                    "esrisat-dev": {
                        "style": "RasterEsriImagery"
                    },
                    "heresat-dev": {
                        "style": "RasterHereExploreSatellite"
                    },
                    "hybrid-dev": {
                        "style": "HybridHereExploreSatellite"
                    },
                    "street-dev": {
                        "style": "VectorEsriStreets"
                    },
                    "topographic-dev": {
                        "style": "VectorEsriTopographic"
                    },
                    "truck-dev": {
                        "style": "VectorHereExploreTruck"
                    }
                },
                "default": "dark-dev"
            },
            "search_indices": {
                "items": [
                    "scepteruimfalocationservice-dev"
                ],
                "default": "scepteruimfalocationservice-dev"
            }
        }
    }
};


export default awsmobile;
